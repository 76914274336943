import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AddProductComponent } from './pages/products/add-product/add-product.component';
import { ViewProductComponent } from './pages/products/view-product/view-product.component';
import { ViewSubscriptionComponent } from './pages/subscriptions/view-subscription/view-subscription.component';
import { MsalGuard } from '@azure/msal-angular';
import { ProductsComponent } from './pages/products/products.component';
import { EditProductComponent } from './pages/products/edit-product/edit-product.component';
import { ManageProductComponent } from './pages/products/manage-product/manage-product.component';
import { NotFoundComponent } from './core/not-found/not-found/not-found.component';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { HealthMonitorComponent } from './pages/health-monitor/health-monitor/health-monitor.component';
import { AllProductsComponent } from './pages/products/all-products/all-products.component';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized.component';

const routes: Routes = [
  {
    // Needed for hash routing, msal pases state via this route to prevent cross-site request forgery attacks
    path: 'state',
    component: AllProductsComponent
  },
  {
    // Needed for hash routing, azure ad return route /code with the token
    path: 'code',
    component: AllProductsComponent
  },
  {
    // Needed for hash routing, same if a authentication erroroccurs
    // maybe intresting to do something with it?
    path: 'error',
    component: AllProductsComponent
  },
  {
    path: 'not-found',
    component:NotFoundComponent
  },
  {
    path: 'dashboard',
    data: {"menu" : "Dashboard", "defaultModuleRoute" : "/dashboard"},
    component: DashboardComponent
  },
  {
    path: 'health',
    component: HealthMonitorComponent
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent
  },  
  {
    path: '',
    redirectTo: 'developer/all-products',
    pathMatch: 'full'
  }
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes)  ],
  exports: [RouterModule]  
})

export class AppRoutingModule {

}
