<div class="container ds_c-breadcrumb" [class.breadcrumbButton]="buttonProps.length">
    <div>
    <span>
        <ng-container *ngFor="let link of links">
            <a [routerLink]="[link.href]"  *ngIf="link.isQueryParamsReq == undefined">{{link.title}}</a> 
            <a [routerLink]="[link.href]" [queryParams]="link.queryparams"  *ngIf="link.isQueryParamsReq != undefined && link.isQueryParamsReq == true">{{link.title}}</a> 
        </ng-container>
    </span>
    <div *ngIf="links.length > 0 && links[0].description">
        <p  class="breadcrumb-description">{{ links[0].description }}</p>
         </div>
     </div>    
    <span class="button-wrapper">
        <app-buttons (btnEmitedClickEvent)="emitClickEvent($event)" *ngFor="let btnProp of buttonProps" [props]="{classType: btnProp.classType, content: btnProp.content, icon: btnProp.icon, routePath: btnProp.routePath, isBreadCrumbClickEvent: btnProp.isBreadCrumbClickEvent, state:btnProp.state, isqueryParamsReq: btnProp.isParamsReq, queryParams: btnProp.params }"></app-buttons>
    </span>
</div>
