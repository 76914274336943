<div class="healthCard">
    <div class="flexContainer">
        <app-donut-chart [data]="cardData.Statistics"></app-donut-chart>
        <div class="healthCard__details">
            <div class="title"><h3>{{cardData.apiName}}</h3> <span class="status" [ngClass]="{'healthy': cardData.Status === 'healthy', 'degraded': cardData.Status === 'degraded', 'unhealthy': cardData.Status === 'unhealthy', 'advisory': cardData.Status === 'advisory'}"></span></div>
            <p>{{cardData.Description}}</p>
            <div class="flexContainer">
                <div class="businessTag" [ngClass]="{'cyan': this.cardData.BusinessArea === 'CT', 'atlasBlue': this.cardData.BusinessArea === 'CO', 'cometRed': this.cardData.BusinessArea === 'IT', 'atlasViolet': this.cardData.BusinessArea === 'PT', 'atlasOrange': this.cardData.BusinessArea === 'VT'}"><span class="middle">{{this.cardData.BusinessArea}}</span></div>
                <p><strong>Last Execution</strong>&nbsp;:&nbsp;{{cardData.LastExecuted}}</p>
            </div>
        </div>
    </div>
    <div class="flexContainer border-t">
        <div class="countStatus">
            {{this.cardData.Statistics.Healthy}} - Healthy  |  {{this.cardData.Statistics.Degraded}} - Degraded  |  {{this.cardData.Statistics.Unhealthy}} - Unhealthy  |  {{this.cardData.Statistics.Advisory}} - Advisory
        </div>
        <a class="details" href="/">Details</a>
    </div>
</div>

