import { Injectable } from '@angular/core';

interface CustModal{
  id: string;
  visible: boolean;

}

@Injectable({
  providedIn: 'root'
})
export class ModalService {

  private modals: CustModal[] = []
  constructor() { }

  register(id: string){
    this.modals.push({
      id,
      visible: false
  
    })
  }

  unRegister(id: string){
        for(let i=0;i<this.modals.length;i++){
                   if(this.modals[i].id==id){
                           this.modals.splice(i,1);
                   }
        }
  }
  isModalOpen(id: string): boolean{
    return !!this.modals.find(element => element.id === id)?.visible
  }

  toggleModal(id: string){
    const modal = this.modals.find(element => element.id === id)
    if(modal){
      modal.visible = !modal.visible
    }
  }
}
