import { Component, ElementRef, HostListener, Input, OnInit, Renderer2 } from '@angular/core';
interface Arc {
  startAngle: number;
  endAngle: number;
  color: string;
}
interface Point {
  x: number;
  y: number;
}
@Component({
  selector: 'app-donut-chart',
  templateUrl: './donut-chart.component.html',
  styleUrls: ['./donut-chart.component.scss']
})
export class DonutChartComponent implements OnInit {
  @Input() data: { [key: string]: number } ={};
  value = this.getChartSizeInRem();
  chartSize: number = 0; 
  rootFontSize: number = 0;
  radius = 0;
  centerX =0; 
  centerY = 0;
  totalValue: number = 0;
  arcs: Arc[] = [];
  transform: string = '';
  svgWidth: string = '';

 
   
  colors = [
    '#43B02A',
    '#F26522',
    '#D03B53',
    '#0066B3'
  ];

  constructor(private renderer: Renderer2, private el: ElementRef) {

  }

  ngOnInit(): void {
    this.resizeChart();
  }

  resizeChart(): void {
    const value = this.getChartSizeInRem();
    this.chartSize = value.chartSizeInRem;
    this.rootFontSize = value.rootFontSizeInPixels;
    this.radius = (this.chartSize * this.rootFontSize) / 2;
    this.centerX = this.radius;
    this.centerY = this.radius;
    this.calculateTotalValue();
    this.calculateArcs();
    this.setTransform();
    this.calculateSvgWidth();
  }

  getChartSizeInRem(): { chartSizeInRem: number, rootFontSizeInPixels: number } {
    const rootFontSizeInPixels = parseFloat(getComputedStyle(document.documentElement).fontSize);
    const viewportWidth = window.innerWidth;
    let chartSizeInPixels:number = 76; // Desired chart size in pixels
    if (viewportWidth >= 1921) {
      chartSizeInPixels =7.6* viewportWidth * 0.0052;
    }else{
      chartSizeInPixels = 76;
    }
    const chartSizeInRem = chartSizeInPixels / rootFontSizeInPixels;
    return {chartSizeInRem, rootFontSizeInPixels};
  }
  calculateTotalValue(): void {
    this.totalValue = Object.values(this.data).reduce((sum, value) => sum + value, 0);
    
  }

  calculateArcs(): void {
    this.arcs = [];
    const nonZeroValues = Object.values(this.data).filter(value => value !== 0);
  
    if (nonZeroValues.length === 1) {
      const nonZeroKey = Object.keys(this.data).find(key => this.data[key] !== 0);
      if (nonZeroKey) {
        const nonZeroIndex = Object.keys(this.data).indexOf(nonZeroKey);
        const arc: Arc = {
          startAngle: 0,
          endAngle: 2 * Math.PI,
          color: this.colors[nonZeroIndex % this.colors.length]
        };
        this.arcs.push(arc);
      }
    } else {
      let totalNonZeroValue = 0;
      let colorIndex = 0;
  
      for (const key in this.data) {
        if (this.data.hasOwnProperty(key)) {
          const value = this.data[key];
          totalNonZeroValue += value;
        }
      }
  
      let startAngle = 0;
      for (const key in this.data) {
        if (this.data.hasOwnProperty(key)) {
          const value = this.data[key];
          const endAngle = startAngle + (value / totalNonZeroValue) * 2 * Math.PI;
  
          if (value !== 0) {
            const arc: Arc = {
              startAngle: startAngle,
              endAngle: endAngle,
              color: this.colors[colorIndex % this.colors.length]
            };
  
            this.arcs.push(arc);
            colorIndex++;
          }
  
          startAngle = endAngle;
        }
      }
    }
  }
  
  describeArc(x: number, y: number, radius: number, startAngle: number, endAngle: number): string {
    const start = this.polarToCartesian(x, y, radius, endAngle);
    const end = this.polarToCartesian(x, y, radius, startAngle);
    const largeArcFlag = endAngle - startAngle <= Math.PI ? '0' : '1';

    const pathData = [
      'M', start.x, start.y,
      'A', radius, radius, 0, largeArcFlag, 0, end.x, end.y,
      'L', x, y,
      'L', start.x, start.y
    ].join(' ');

    return pathData;
  }

  polarToCartesian(centerX: number, centerY: number, radius: number, angleInRadians: number): Point {
    const x = centerX + radius * Math.cos(angleInRadians);
    const y = centerY + radius * Math.sin(angleInRadians);
    return { x, y };
  }

  setTransform(): void {
    const chartGroup = this.el.nativeElement.querySelector('.chart-group');  
    const transform = `translate(50%, 50%)`;
    this.renderer.setAttribute(chartGroup, 'transform', transform);
  }

  calculateSvgWidth(): void {
    const svgWidthInPixels = this.chartSize * this.rootFontSize;
    this.svgWidth = svgWidthInPixels.toString();
  }
  @HostListener('window:resize')
ResizeChart(): void {
  // Call the method to recalculate chart size
  this.resizeChart();
}
}