import { NgModule } from '@angular/core';
import {  HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { msalConfig, loginRequest, protectedResources } from './auth-config';
import { IPublicClientApplication, PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalModule, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration, MsalRedirectComponent } from '@azure/msal-angular';

import { AppComponent } from './app.component';
import { Hip2ManagementInterceptor } from './shared/interceptors/hip2-management-interceptor';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPaginationModule } from 'ngx-pagination';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { AppRoutingModule } from './app-routing.module';
import { ProductModule } from './pages/products/products.module';
import { SharedModule } from './shared/shared.module';
import { ViewProductComponent } from './pages/products/view-product/view-product.component';
import { ViewSubscriptionComponent } from './pages/subscriptions/view-subscription/view-subscription.component';
import {ViewApisComponent} from './pages/apis/view-apis/view-apis.component'
import { ApiModule } from './pages/apis/api.module';
import { NotFoundComponent } from './core/not-found/not-found/not-found.component';
import { ApprovalModule } from './pages/approvals/approvals.module';
import { HealthMonitorModule } from './pages/health-monitor/health-monitor.module';
import { SubscriptionModule } from './pages/subscriptions/subscription.module';
import { DashboardComponent } from './core/dashboard/dashboard.component';
import { DateFormatPipe } from './pipes/date-format-pipe.pipe';
import { CustomPipeModule } from './pipes/pipe.module';
import { UnauthorizedComponent } from './core/unauthorized/unauthorized.component';


/**
 * Here we pass the configuration parameters to create an MSAL instance.
 * For more info, visit: https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/configuration.md
 */

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication(msalConfig);
}

/**
 * MSAL Angular will automatically retrieve tokens for resources 
 * added to protectedResourceMap. For more info, visit: 
 * https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-angular/docs/v2-docs/initialization.md#get-tokens-for-web-api-calls
 */
export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  const protectedResourceMap = new Map<string, Array<string>>();

  protectedResourceMap.set(protectedResources.hipManagementApi.endpoint, protectedResources.hipManagementApi.scopes);

  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap
  };
}

/**
 * Set your default interaction type for MSALGuard here. If you have any
 * additional scopes you want the user to consent upon login, add them here as well.
 */
export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: loginRequest
  };
}

@NgModule({
  declarations: [
    AppComponent,
    ViewProductComponent,
    ViewSubscriptionComponent,
    NotFoundComponent,
    DashboardComponent,
    DateFormatPipe,
    UnauthorizedComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    HttpClientModule,
    NgxPaginationModule, //npm install ngx-pagination --save
    Ng2SearchPipeModule, // npm i ng2-search-filter --save
    Ng2OrderModule, //npm install  ng2-order-pipe --save
    ReactiveFormsModule,
    ProductModule,
    ApiModule,
    SharedModule,
    ApprovalModule,
    HealthMonitorModule,
    SubscriptionModule,
    CustomPipeModule
  ],
  providers: [
    { provide: Window, useValue: window },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MsalInterceptor,
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory
    },
    MsalService,
    MsalGuard,
    MsalBroadcastService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: Hip2ManagementInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
