import { Component, OnInit } from '@angular/core';
import { Hip2ManagementService } from '../../shared/services/hip2-management.service';
import { Api } from '../apis/models/api';
import { CreateApi } from '../apis/models/api-create';
import { DeleteApi } from '../apis/models/api-delete';
import { UpsertApi } from '../publisher/models/upsert-api';
import { Product } from './models/product';
import { ProductModel } from '../models/product-model';

import { DeleteProduct } from './models/product-delete';
import { SubscriptionRequest} from '../subscriptions/models/subscriptions-api';
import { SubscriptionPutRequest} from '../subscriptions/models/subscription-put-api';
import {RejectSubscriptionRequest} from '../subscriptions/models/subscription-reject-api';
import {SubscriptionApproveRequest} from '../subscriptions/models/subscription-approve';
import { CmdbRequest } from '../models/cmdb-request'

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit {

  myData: any[] = [];
  headArray: any[] = [];
  gridArray: any[] = [];
  dataKeys: any[] = [];
  productName: any;
  p: number = 1;
  itemsPerPage: number = 5;
  createProduct!: ProductModel;
  deleteProductObject!: DeleteProduct;
  createApi!: CreateApi;
  upsertApiObject!: UpsertApi;
  deleteApiObject!: DeleteApi;
  addProductObject!: ProductModel;
  editProductObject!: ProductModel;
  totalProducts!: number;
  subscriptionRequest!:SubscriptionRequest;
  subscriptionPutRequest!:SubscriptionPutRequest;
  rejectSubscriptionReject!:RejectSubscriptionRequest;
  subscriptionApproveRequest!:SubscriptionApproveRequest;
  cmdbRequest!:CmdbRequest

  constructor(private hipManagementService: Hip2ManagementService) { }

  ngOnInit(): void {
    var uniqueId = Math.floor(Math.random() * 100);
    this.cmdbRequest = {
      cmdbId:"10007223"
    }
   
    this.getApimApiId("ac-api-Hip2-Demo-999")

    this.subscriptionApproveRequest = {
      subscriptionName: "10001236_appId_Demo-3334"+uniqueId,
      apimProductId: "ac-prd-Hip2-Demo-999",
      remarks: "this-is-a-test-subscription-999",
    
    }

  

    this.subscriptionPutRequest = {
    
        subscriptionName: "10001236_appId_Demo-111",
        usageNotes: "Testing Trial",
        apimProductId:"ac-prd-Hip2-Demo-999"
    
    }


    
    this.createProduct = {
      name: "ac-prd-Rajtest123",
      description: "ac-prd-Rajtestingproduct for update",
      businessAreaCode: "CO",
      apimProductId: "ac-prd-Rajtest123",
      clientAppRegistrationID: "",
      isPublished: false,
      requiresManualApproval: false,
      cmdbId: "10001236",
      requireSubscription: true,
      productApprovers: [
        "rajavel.mohan@external.atlascopo.com"
      ],
      apiList: [
        {
          apimApiId: "api-hip2-notification"
        }, {
          apimApiId: "Atlas-Copco-Communications-API"
        }
      ]
    }

    this.createApi = {
      parentId: "7dcb7294-1dd6-ebb6-623a-c44843ec546b",
      versionNr:1,
      name: "ac-api-hip-rjtest123",
      description: "this is test api",
      cmdbId: "10001236",
      urlSuffix: "api/API/HIP2",
      monitor: false,
      apimApiId: "ac-api-hip-rjtest123"
    }

    this.upsertApiObject = {
      serviceUrl: "https://api-dev.atlascopco.group/test/100/",
      disabled: false,
      format:"swagger-json",
      parentId: "7dcb7294-1dd6-ebb6-623a-c44843ec546b",
      versionNr: 1,
      name: "ac-api-hip-rjtest123",
      description: "this is test api",
      cmdbId: "10001236",
      urlSuffix: "api/API/HIP2",
      monitor: false,
      apimApiId: "ac-api-hip-rjtest123"
    }

    this.addProductObject = {
     
      name: "co-acdc-davdav-10001236",
      description: "this is for Demo 123 abc",
      businessAreaCode: "CO",
      apimProductId: "co-acdc-davdav3333-10001236",
      clientAppRegistrationID:"69a62d34-dc0d-4811-93c1-23807152d417",
      isPublished: true,
      requiresManualApproval: false,
      cmdbId: "10001236",
      requireSubscription: true,
      productApprovers: [
        "Ayush.sharma@external.atlascopo.com"
      ],
      apiList: [
        {
          apimApiId: "AccountsAPI-10001236-v1"
        }
      ]
    }
    


    this.editProductObject = {
      name: "co-acdc-davdav-10001236",
      description: "this is for Demo 123",
      businessAreaCode: "CO",
      apimProductId: "co-acdc-davdav3333-10001236",
      clientAppRegistrationID:"69a62d34-dc0d-4811-93c1-23807152d417",
      isPublished: true,
      requiresManualApproval: false,
      cmdbId: "10001236",
      requireSubscription: true,
      productApprovers: [
        "Ayush.sharma@external.atlascopo.com"
      ],
      apiList: [
        {
          apimApiId: "AccountsAPI-10001236-v1"
        }
      ]
    }
    


    //  this.createNewProduct();
    // this.updateProduct();
    this.deleteProductObject = {
      productId: "0A143BA3-7B09-4862-A4EF-BF7CFDC2C985",
      isDeletedBy: "Rajavel"
    };
    //  this.deleteProduct();

    //  this.createNewApi();
    // this.updateApi();
    this.deleteApiObject = {
      apiId: "37766fc8-2498-4f64-a801-bc3c5fdb2502",
      isDeletedBy: "Rajavel"
    };
    // this.deleteApi();

    //  this.getApimOperations("ac-api-core-10001236-v1");
    //   this.getApimProducts("ac-api-core-10001236-v1");
      // this.upsertApi();
    //  this.addProduct();
    //  this.editProduct();
    //  this.deleteApimProduct("ac-prd-Rajtest123");
    //  this.deleteApimApi("ac-api-hip-rjtest123");
    //  this.deleteApimSubscription("63ae971bfb42681fe85f83b6");
    //  this.getApimApisByProduct("ac-prd-Hip2-Demo-999");
    //  this.getApiSpecification("ac-api-core-10001236-v1");

//  this.getApiCmdbGetDetails("APP-10007223");
//   this.getApiCmdbGetApplications("");
 //  this.deleteApimSubscription("63ae971bfb42681fe85f83b6");
 //  this.getApimApisByProduct("ac-prd-Hip2-Demo-999");
 //  this.getApiSpecification("ac-api-core-10001236-v1");

 //  this.getApiCmdbGetDetails("APP-10007223");
 //  this.getApiCmdbGetApplications("");


       
  //  this.getSubscriptionsByUserEmail("ayush.sharma@external.atlascopco.com");
  //  this.getSubscriptionKey("10001236_appId_Demo-54321--12122");
  //  this.getApisForSubscription("D3C69287-9D3A-4EAB-AEAC-4EE4354FFB6C");
  //  this.getSubscriptionsByApi("11336B39-1A20-4F96-AF1B-3463E91CB7FB");
//  this.createSubscriptionApi();   
//  this.putSubscriptionApiName();
//  this.putRejectSubscriptionApi();
//  this.postApproveProductSubscriptionApi();



    

  }
  dataCount(value: string) {
    console.log(this.itemsPerPage = Number(value));

  }

  Search() {
    if (this.productName == '') {
      this.ngOnInit();
    } else {
      this.p = 1;
      this.gridArray = this.gridArray!.filter((res: any) => { return res.name.toLocaleLowerCase().match(this.productName.toLocaleLowerCase()) });
    }
  }

    //***below need to be moved to individual component ***//
  //** Product module API calls ***//

  async getTotalProducts() {
    this.totalProducts = await this.hipManagementService.getTotalProducts();
    console.log("totalproductsresponse:" + this.totalProducts);
  }
  getApisByProductId(productId: string) {
    this.hipManagementService.getApisByProductId(productId).subscribe(r => {
      let apis = r;
      console.log("Apis:" + JSON.stringify(apis));
    });

  }

  getProductsByApiId(apiid: string) {
    this.hipManagementService.getProductsByApiId(apiid).subscribe(r => {
      let products = r;
      console.log("Products:" + JSON.stringify(products));
    });
  }

  createNewProduct() {
    this.hipManagementService.createProduct(this.createProduct).subscribe(r => {
      let productResult = r;
      console.log("CreatedProduct:" + JSON.stringify(productResult));
    });
  }

  updateProduct() {
    this.hipManagementService.updateProduct(this.createProduct).subscribe(r => {
      let productResult = r;
      console.log("UpdatedProduct:" + JSON.stringify(productResult));
    });
  }

  deleteProduct() {
    this.hipManagementService.deleteProduct(this.deleteProductObject).subscribe(r => {
      console.log("DeletedProduct:" + JSON.stringify(r));
    });
  }

  //***End***//

  //** API module API calls ***//



  async getTotalApis() {
    let count = await this.hipManagementService.getTotalApis();
    console.log("totalapiscount:" + count);
  }

  getApiByName(apiName: string) {
    this.hipManagementService.getApiByName(apiName).subscribe(r => {
      let api = r;
      console.log("Api By Name:" + JSON.stringify(api));
    });
  }
 
  createNewApi() {
    this.hipManagementService.createApi(this.createApi).subscribe(r => {
      let apiResult = r;
      console.log("CreatedApi:" + JSON.stringify(apiResult));
    },
      err => {
        console.log("Create Api Failed:" + err.error);
      });
  }

  updateApi() {
    this.hipManagementService.updateApi(this.createApi).subscribe(r => {
      let apiResult = r;
      console.log("UpdatedApi:" + JSON.stringify(apiResult));            
    },
    err => {
      console.log("Update Api Failed:" + err.error);
    });
  }

  deleteApi() {
    this.hipManagementService.deleteApi(this.deleteApiObject).subscribe(r => {
      console.log("DeletedApi:" + r);
    },
      err => {
        console.log("Delete Api Failed:" + err.error);
      });
  }
  //***End***//
  //**** Publisher module API calls ******////
 

  getApimOperations(apiName: string) {
    this.hipManagementService.getApimOperationsByApiName(apiName).subscribe(r => {
      let apiOperations = r;
      console.log("ApimOperations:" + JSON.stringify(apiOperations));
    });
  }

  getApimProducts(apiId: string) {
    this.hipManagementService.getApimProductsByApiId(apiId).subscribe(r => {
      let apimProducts = r;
      console.log("ApimProducts:" + JSON.stringify(apimProducts));
    });
  }

  // upsertApi() {
  //   this.hipManagementService.upsertApi(this.upsertApiObject).subscribe(r => {
  //     let apiResult = r;
  //     console.log("UpsertedApi:" + JSON.stringify(apiResult));
  //   },
  //     err => {
  //       console.log("Upsert Api Failed:" + err.error);
  //     });
  // }

 

  addProduct() {
    this.hipManagementService.createProduct(this.addProductObject).subscribe(r => {
      let productResult = r;
      console.log("AddProduct:" + JSON.stringify(productResult));
    },
      err => {
        console.log("Add Product Failed:" + err.error);
      });
  }
  editProduct() {
    this.hipManagementService.createProduct(this.editProductObject).subscribe(r => {
      let productResult = r;
      console.log("EditProduct:" + JSON.stringify(productResult));
    },
      err => {
        console.log("Edit Product Failed:" + err.error);
      });
  }

  deleteApimProduct(paramProductId: string) {
    this.hipManagementService.deleteApimProduct(paramProductId).subscribe(r => {
      console.log("DeletedApimProduct:" + r);
    },
    err => {
      console.log("Delete ApimProduct Failed:" + err.error);
      });
  }

  deleteApimApi(paramApiId: string) {
    this.hipManagementService.deleteApimApi(paramApiId).subscribe(r => {
      console.log("DeletedApimApi:" + r);
    },
      err => {
        console.log("Delete ApimApi Failed:" + err.error);
      });
  }

  deleteApimSubscription(paramSubscriptionName: string) {
    this.hipManagementService.deleteApimSubscription(paramSubscriptionName).subscribe(r => {
      console.log("DeletedApimSubscription:" + r);
    },
    err => {
      console.log("Delete ApimSubscription Failed:" + err.error);
      });
  }

  getApimApisByProduct(apimProductId: string) {
    this.hipManagementService.getApimApisByProduct(apimProductId).subscribe(r => {
      console.log("GetApimProductId:" + r);
    },
    err => {
      console.log("Get ApimProductId Failed:" + err.error);
      });
  }

  
  getApiSpecification(apimApiId: string) {
    this.hipManagementService.getApiSpecification(apimApiId).subscribe(r => {
      console.log("GetApimApi:" + r);
    },
      err => {
        console.log("Get ApimApi Failed:" + err.error);
      });
  }

  getApimApiId(apimApiId: string) {
    this.hipManagementService.getApimApiPublisherId(apimApiId).subscribe(r => {
      let apimApiId = r;
      console.log("ApimOperations:" + JSON.stringify(apimApiId));
    });
  }
  
  //  cmdbapi
  getApiCmdbGetDetails(cmdbId: string) {
    this.hipManagementService.getApiCmdbGetDetails(cmdbId).subscribe(response => {
      console.log("GetApiCMBDId:" + response);
    },
      err => {
        console.log("Get ApiCMBDId Failed:" + err.error);
      });
  }
  

  getApiCmdbGetApplications(wildcardText: string) {
    this.hipManagementService.getApiCmdbGetApplications(wildcardText).subscribe(response => {
      console.log("Get-Applications-Response:" + JSON.stringify(response));
    });
  }

   // subscriptions

  

   getSubscriptionsByUserEmail(email: string) {
    this.hipManagementService.getSubscriptionsByUserEmail(email).subscribe(r => {
      console.log("GetSubscriptionUserEmail:" + r);
    },
      err => {
        console.log("Get SubscriptionUserEmail Failed:" + err.error);
      });
    }
  
  getSubscriptionKey(subscriptionName: string) {
    this.hipManagementService.getSubscriptionKey(subscriptionName).subscribe(r => {
      console.log("GetSubscriptionName:" + r);
    },
      err => {
        console.log("Get SubscriptionName Failed:" + err.error);
      });
    }

  getApisForSubscription(subscriptionId: string) {
    this.hipManagementService.getApisForSubscription(subscriptionId).subscribe(r => {
      console.log("GetSubscriptionId:" + r);
    },
      err => {
        console.log("Get SubscriptionId Failed:" + err.error);
      });
    }

  
      
  getSubscriptionsByApi(apiId: string) {
    this.hipManagementService.getSubscriptionsBy(apiId).subscribe(r => {
      console.log("GetApiId:" + r);
    },
      err => {
        console.log("Get ApiId Failed:" + err.error);
      });
    }

    

    createSubscriptionApi() {
      this.hipManagementService.createSubscription(this.subscriptionRequest).subscribe({
        next:(resp:any) => {
        let subscriptionResponse = resp;
        console.log("2.CREAT-SUBSCRIPTION-Response:" + JSON.stringify(subscriptionResponse));
        },
        error:(err:string) => {
          console.log("2.CREAT-SUBSCRIPTION-ErrorResponse:" + JSON.stringify(err));
        }
      });
    }

    putRejectSubscriptionApi() {
      this.hipManagementService.rejectSubscription(this.rejectSubscriptionReject).subscribe({
        next:(resp:any) => {
        let subscriptionRejectResponse = resp;
        console.log("1.REJECT-SUBSCRIPTION-API-Response:" + JSON.stringify(subscriptionRejectResponse));
        },
        error:(err:string) => {
          console.log("1.REJECT-SUBSCRIPTION-API-ErrorResponse:" + JSON.stringify(err));
        }
      });
    }

    postApproveProductSubscriptionApi() {
      this.hipManagementService.approveProductSubscription(this.subscriptionApproveRequest).subscribe({
        next:(resp:any) => {
        let approveSubscriptionApiResponse = resp;
        console.log("1.APPROVE-PRODUCT-SUBSCRIPTION-API-Response:" + JSON.stringify(approveSubscriptionApiResponse));
        },
        error:(err:string) => {
          console.log("1.APPROVE-PRODUCT-SUBSCRIPTION-API-ErrorResponse:" + JSON.stringify(err));
        }
      });
    }
  
  
  }

  


  

  

  

