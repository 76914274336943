
<h2>Products List</h2>
<!-- <div class="listing-table">
  <h2 class="mt-4">GetProducts</h2>
  <div class="listing-table-filter">
    <div class="navbar container">
      <input type="text"
             class="form-control"
             name="ProductName"
             [(ngModel)]="productName"
             (ngModelChange)="Search()"
             placeholder="Search" />
      <select name=""
              class="form-control"
              #PerPage
              (change)="dataCount(PerPage.value)">
        <option>5</option>
        <option>10</option>
        <option>15</option>
      </select>
    </div>
    <pagination-controls (pageChange)="p = $event"></pagination-controls>
  </div>
  <app-dynamic-table *ngIf="gridArray.length > 0 else loading" [HeadArray]="headArray" [GridArray]="gridArray" [DataKeys]="dataKeys" [itemsPerPage]="itemsPerPage" [p]="p"></app-dynamic-table>
  
</div>

<ng-template #loading>
  <h2>Loading Products</h2>
</ng-template>
 -->
