import { RouterModule, Routes } from "@angular/router";
import { ManageSubscriptionsComponent } from "./manage-subscriptions/manage-subscriptions.component";
import { MsalGuard } from "@azure/msal-angular";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "src/app/app-routing.module";
import { MySubscriptionsComponent } from './my-subscriptions/my-subscriptions.component';
import { SharedModule } from "src/app/shared/shared.module";
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPaginationModule } from 'ngx-pagination';
import { ViewSubscriptionComponent } from './view-subscription/view-subscription.component';
import { ProductSubscriptionComponent } from './product-subscription/product-subscription.component';
import { ViewMySubscriptionComponent } from './view-my-subscription/view-my-subscription.component';
import { CustomPipeModule } from "src/app/pipes/pipe.module";
import { ApiCatalogRoleEnum } from "src/app/core/enum/UserRoleEnum";
import { DeveloperManageSubscriptionsComponent } from './developer-manage-subscriptions/developer-manage-subscriptions.component';
import { DeveloperProductSubscriptionComponent } from './developer-product-subscription/developer-product-subscription.component';
import { AuthGuard } from "src/app/shared/guard/auth.guard";
import { ViewDeveloperManageSubscriptionComponent } from "./view-developer-manage-subscription/view-developer-manage-subscription.component";

const routes:Routes =[
    {
        path: 'publisher',
        children: [
            {
                path:'manage-subscriptions',
                component: ManageSubscriptionsComponent,
                data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/manage-subscriptions","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN,ApiCatalogRoleEnum.APPROVER]},
                canActivate: [MsalGuard,AuthGuard]
            }, 
            { path: 'manage-subscriptions/view-subscription/:subscriptionName/:apimProductId',
              component: ViewSubscriptionComponent,
              data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/manage-subscriptions","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN,ApiCatalogRoleEnum.APPROVER]} ,
              canActivate: [MsalGuard,AuthGuard] 
            },
            { path: 'manage-subscriptions/:productId',
             component: ProductSubscriptionComponent,
             data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/manage-subscriptions","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN,ApiCatalogRoleEnum.APPROVER]},
             canActivate: [MsalGuard,AuthGuard] },

        ]        
    },
    {
        path: 'developer',
        children: [{
            path: 'my-subscriptions',
            component: MySubscriptionsComponent,
            data: {"menu" : "Developer", "defaultModuleRoute" : "/developer/my-subscriptions"},
            canActivate: [MsalGuard]
        },{
            path: 'view-my-subscription/:subscriptionName/:apimProductId',
            component: ViewMySubscriptionComponent,
            data: {"menu" : "Developer", "defaultModuleRoute" : "/developer/my-subscriptions"},
            canActivate: [MsalGuard]
        },{
            path: 'manage-subscriptions',
            component: DeveloperManageSubscriptionsComponent,
            data: {"menu":"Developer", "defaultModuleRoute":"/developer/manage-subscriptions"}
        },
        {
            path: 'manage-subscriptions/view-subscription/:subscriptionName/:apimProductId',
            component: ViewDeveloperManageSubscriptionComponent,
            data: {"menu":"Developer", "defaultModuleRoute":"/developer/manage-subscriptions"}
        },
        {
            path: 'manage-subscriptions/:apimProductId',
            component: DeveloperProductSubscriptionComponent,
            data: {"menu":"Developer", "defaultModuleRoute":"/developer/manage-subscriptions"}
        }
    ]
    }
]

@NgModule({
    declarations:[ManageSubscriptionsComponent, MySubscriptionsComponent, ProductSubscriptionComponent, ViewMySubscriptionComponent, DeveloperManageSubscriptionsComponent, DeveloperProductSubscriptionComponent, ViewDeveloperManageSubscriptionComponent],
    imports: [RouterModule.forRoot(routes), SharedModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        NgxPaginationModule,
        FormsModule,
        ReactiveFormsModule,
        SharedModule,
        CustomPipeModule
    ],
    exports: [RouterModule]
    })
export class SubscriptionModule{

}