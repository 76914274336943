import { Component, OnInit,  NgZone, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, Subscription, debounceTime, timer } from 'rxjs';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { User } from '../models/user';
import { Location } from '@angular/common';
import { CmdbDetails } from '../../models/cmdb-details';
import { SubscriptionRequest } from '../../subscriptions/models/subscriptions-api';
import { NotifySubscription } from '../../subscriptions/models/notify-subscription';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';

@Component({
  selector: 'app-product-subcription-flow',
  templateUrl: './product-subcription-flow.component.html',
  styleUrls: ['./product-subcription-flow.component.scss']
})
export class ProductSubcriptionFlowComponent implements OnInit {
breadcrumbProductId:string = "";
breadcrumbProductName:string = "";
isBasicTabActivated:boolean = true;
isManagementTabActivated:boolean = false;
isReviewTabActivated:boolean = false;
pageInfo:string = "A subscription is created against a Product, containing one or more APIs. Once subscribed, an application gets access to all APIs inside a Product in a secure way.\n\nYou are about to create a new Product subscription in Azure API Management."
detailsInfo:{"title":string,"value":string}[]=[];
appNameAndNumber : FormControl = new FormControl('', [Validators.required, Validators.minLength(3)]);
basicStepForm :FormGroup = new FormGroup({});
ManagementStepForm :FormGroup = new FormGroup({});
applicationDataSet:any[]=[];
searchApplicationValueChanged: Subject<string> = new Subject();
searchApplicationSubscription!: Subscription;
applicationId:string = "";
loaderActive:boolean = false;
isBasicBackgroundCheckCompleted:boolean = false;
isExistingClientAppRegistrationFound:boolean = false;
appRegDataSet:any[]=[];
userSpecificClientAppRegDataSet:any[]=[];
baseTabClientAppRegProps:any= {heading: 'Client App Registration Name',placeholder: 'Select Client App Registration Name ',required: true,disable: false,info: 'Select the application registration name from Azure AD where the product subscription belongs to. Only application registrations that belong to the selected application from CMDB can be added to the product subscription.','errMsg': 'Please select a valid application registration name from Azure AD '}
isbaseTabCompleted:boolean = false;
selectedExistingClientAppRegistration:{id:string,value:string}={id:"",value:""};
isSpecifyMyOwnClientAppRegOptionSelected:boolean = false;
isReuseExistingClientAppRegOptionSelected:boolean = false;
userSelectedExistingClientAppRegOption:string = "";
userSelectedCustomClientAppRegOption:string = "";
userSelectedCustomClientAppRegOptionId:string = "";
isUserOwnerForSelectedExistingClientAppRegOption:boolean = false;
isHIPOwnerForSelectedExistingClientAppRegOption:boolean = false;
selectedExistingClientAppRegApprovers: string[] = [];
selectedExistingClientAppRegApproversEmail: string[] = [];
selectedCustomUserClientAppRegApprovers: string[] = [];
selectedCustomUserClientAppRegApproversEmail: string[] = [];
usageNotes:FormControl = new FormControl ();
userSpecificClientAppReg:FormControl = new FormControl();
isUserOwnerForCustomSelectedClientAppRegOption:boolean = false;
isHIPOwnerForCustomSelectedClientAppRegOption:boolean = false;
isCreateNewClientAppRegOptionSelected:boolean = false;
userSubject: Subject<string> = new Subject();
approversDataSet:any[] = [];
searchUserSubscription!: Subscription;
isPreviousNavigationlogged:boolean = false;
selectedApplicationId:string = '';
selectedApplicationName:string = '';
selectedOwnerList:string[]=[];
selectedOwnerEmailList:string[]=[];
selectedCmdbDetails:CmdbDetails ={
  cmdbId: '',
  costCenter: '',
  businessArea: '',
  familyCode: '',
  applicationOwner: '',
  applicationName: '',
  applicationOwnerEmail: ''
};
successFull:boolean=false;
failure:boolean=false;
feedbackActive:boolean=false;
failureMsg:string="";
successMsg:string="";
showSuccess:boolean=false;
showFailure:boolean=false;
isSubmitted:boolean=false;
isSubmitDisabled:boolean=false;
clickCounter:number=0;
isCustomSelectedAppRegListEmpty:boolean = false;
submitButtonProps={
  icon: '',
  content: 'Submit',
  classType: 'primary',
  type:'button'
}
constructor(private route:ActivatedRoute, private router: Router, private hipManagementService: Hip2ManagementService, private location: Location, private errorHandleService: ErrorHandleService,  private cdr: ChangeDetectorRef, private zone: NgZone ){
  let queryParams = this.route.snapshot.queryParams;
  this.breadcrumbProductName = queryParams['productName']??"";
  this.isPreviousNavigationlogged = (this.router.getCurrentNavigation()?.previousNavigation !=null)
}

  ngOnInit() {
     this.breadcrumbProductId=this.route.snapshot.paramMap.get("productId")??"";
     this.basicStepForm = new FormGroup({
      appNameAndNumber : this.appNameAndNumber,
      displayAppNameAndNumber: new FormControl(""),
      clientAppRegistartion: new FormControl(""),
      clientAppReguserOption: new FormControl("")
     });
     this.ManagementStepForm = new FormGroup({
      owners: new FormControl("",Validators.required)
     })
     this.hipManagementService.getProductByProductIdDeveloperSection(this.breadcrumbProductId).subscribe(r => {
      //used to check 404 request at route level for url tampering issue
     },error=>{
      this.errorHandleService.handleError(error);
     });
  }

  getBasicStepFormControl(control:string): FormControl{
      return this.basicStepForm.get(control) as FormControl
  }

  getManagementStepFormControl(control:string): FormControl{
    return this.ManagementStepForm.get(control) as FormControl
}
  preventSubmit(event: any){      
    event.preventDefault();
  }

  applicationSearch(searchTerm: string){     
    this.searchApplicationValueChanged.next(searchTerm);       
 }
 
 getCmdbApplications(searchText: string){             
  this.hipManagementService.getCmdbApplications(searchText).subscribe({          
      next:(response:any)=>{              
        if(response.length > 0) {
          this.applicationDataBind(response);
        }
        else {                
          this.applicationDataSet=[];
        }              
      },
      error:(error:any)=> {              
        this.applicationDataSet = [];
        // this.resetApplicationInfo();
        this.detailsInfo=[];
        this.basicStepForm.patchValue({
          appNameAndNumber:""
        });
      }
  });        
}

applicationDataBind(data:any){
  this.applicationDataSet=[];
  data.forEach((element: { applicationId: any; applicationName: any; }) => {
    this.applicationDataSet.push({
      'id':element.applicationId,
      'value':element.applicationName                
    })
  })
}

ngAfterContentInit() : void {
  this.searchApplicationSubscription=this.searchApplicationValueChanged.pipe(debounceTime(1500)).subscribe(value =>{   
  this.userSelectedExistingClientAppRegOption="";
    if (value.length > 2) {
      this.getCmdbApplications(value);
    }
    else{
      this.applicationDataSet=[];
    }          
  });           

  this.searchUserSubscription=this.userSubject.pipe(debounceTime(1500)).subscribe(value =>{      
    if (value.length > 2) {
      this.getAdUsers(value);          
    }
    else {
      this.approversDataSet = [];
    }      
  });  
 }

 appSelected(event: any){ 
  this.isbaseTabCompleted=false;
  this.selectedExistingClientAppRegistration={id:"",value:""};   
  if (event) {
      this.applicationId = event.id;        
      this.getCmdbDetails(this.applicationId);  
  }
  else{
    this.applicationId='';
    this.detailsInfo=[];
    this.isBasicBackgroundCheckCompleted = false;
    this.isManagementTabActivated=false;  
    this.isReviewTabActivated=false;                                
  }
 }

 getCmdbDetails(cmdbId:string){
  this.loaderActive = true;
  this.hipManagementService.getCmdbDetails(cmdbId).subscribe({ 
    next:(response:any)=>{
      this.loaderActive = false;
      if (response !== null){
        // this.AppOwner.setValue(response.applicationOwner);
        // this.businessArea = response.businessArea;        
        // this.populateProductId();          
        this.detailsInfo = [
          {'title':'Application Name', 'value':response.applicationName },
          {'title':'Application Number', 'value':response.applicationId },          
          {'title':'Application Owner', 'value':response.applicationOwner },          
          {'title':'Business Area', 'value': response.businessArea },
          {'title':'Unit Code', 'value': response.familyCode },
          {'title':'Cost Center', 'value': response.costCenter }
        ];
        this.selectedApplicationId=response.applicationId;
        this.selectedApplicationName=response.applicationName;
        this.selectedCmdbDetails={
          applicationName:response.applicationName,
          applicationOwner:response.applicationOwner,
          cmdbId:response.applicationId,
          businessArea: response.businessArea,
          familyCode: response.familyCode,
          costCenter: response.costCenter,
          applicationOwnerEmail:response.applicationOwnerEmail
        }
        this.baseBackgroundCheck(response.applicationId); 
        this.cdr.detectChanges();
      }      
    },
    error:(error:any)=>{
      this.loaderActive = false;    
      this.isBasicBackgroundCheckCompleted = false;
      this.isManagementTabActivated=false;  
      this.isReviewTabActivated=false;   
      this.cdr.detectChanges();         
    }           
  });
}

baseBackgroundCheck(cmdbId:string){
this.loaderActive = true;
this.resetClientAppRegOption();
this.baseTabClientAppRegProps.disable=true; 
this.isbaseTabCompleted=false;
this.isSpecifyMyOwnClientAppRegOptionSelected = false;
this.hipManagementService.getHIPEligibleApplication(cmdbId).subscribe({
  next: (response:any) =>{
         console.log("done background check", response);
         this.loaderActive = false;
         this.isBasicBackgroundCheckCompleted=true;
         this.isExistingClientAppRegistrationFound=true;
         if(response.length > 0){
          this.appRegistrationDataBind(response);
          this.zone.run(() => {
            this.cdr.detectChanges();
          });
        }else{
          this.isBasicBackgroundCheckCompleted=true;
          this.isExistingClientAppRegistrationFound=false;
          this.appRegDataSet=[];
        }
  },
 error:(error:any)=>{
  this.loaderActive = false;
  this.isBasicBackgroundCheckCompleted=true;
  this.isExistingClientAppRegistrationFound=false;
  this.appRegDataSet=[];
  this.baseTabClientAppRegProps.disable=true;
 }
})

}

appRegistrationDataBind(data:any){   
  this.appRegDataSet=[];
  data.forEach((element: { clientAppName: any; appId: any; }) => {      
    this.appRegDataSet.push({                      
      'id': element.appId,
      'value': element.clientAppName                
    });  
  });
}

clientSpecificAppRegistrationDataBind(data:any){   
  this.userSpecificClientAppRegDataSet=[];
  this.isCustomSelectedAppRegListEmpty = false;
  data.forEach((element: { clientAppName: any; appId: any; }) => {      
    this.userSpecificClientAppRegDataSet.push({                      
      'id': element.appId,
      'value': element.clientAppName                
    });  
  });
}

onClientAppRegOptionChange(event:any){
this.isCustomSelectedAppRegListEmpty = false;
this.isReviewTabActivated=false;
this.isManagementTabActivated=false;
if(event == "I want to let HIP create a new Client App Registration"){
  this.baseTabClientAppRegProps.disable=true;
  this.isCreateNewClientAppRegOptionSelected=true;
  this.isSpecifyMyOwnClientAppRegOptionSelected = false;
  this.isbaseTabCompleted=true;
  this.userSelectedExistingClientAppRegOption = "";
  this.userSelectedCustomClientAppRegOption = "";
  this.cdr.detectChanges();
 }else if(event == "I want to specify my own Client App Registration in the next step"){
  this.baseTabClientAppRegProps.disable=true;
  this.isbaseTabCompleted=true;
  this.isSpecifyMyOwnClientAppRegOptionSelected = true;
  this.isCreateNewClientAppRegOptionSelected=false;
  this.userSelectedExistingClientAppRegOption = "";
  this.userSelectedCustomClientAppRegOption = "";
  this.cdr.detectChanges();
}else if(event == "I want to reuse such existing Client App Registration by selecting one from the list below"){
  this.baseTabClientAppRegProps.disable=false;
  this.isSpecifyMyOwnClientAppRegOptionSelected = false;
  this.isbaseTabCompleted=false;  
  this.isCreateNewClientAppRegOptionSelected=false;
  this.isCreateNewClientAppRegOptionSelected = false;
  this.userSelectedCustomClientAppRegOption = "";
  this.cdr.detectChanges();
}
this.usageNotes.reset();
this.ManagementStepForm.reset();
}

resetClientAppRegOption(){
  this.basicStepForm.patchValue({
    clientAppRegistartion: new FormControl(""),
    clientAppReguserOption: new FormControl("")
  })
  this.userSelectedCustomClientAppRegOption = "";
  this.userSelectedCustomClientAppRegOptionId="";
  this.isManagementTabActivated=false;
  this.isReviewTabActivated=false;
  this.isCreateNewClientAppRegOptionSelected = false;
  this.isSpecifyMyOwnClientAppRegOptionSelected = false;
  this.isCustomSelectedAppRegListEmpty = false;
}

onSelectedExistingClientAppRegistrartion(event:any){
this.selectedExistingClientAppRegistration=this.basicStepForm.get("clientAppRegistartion")?.value;
this.isbaseTabCompleted=true;
this.isReviewTabActivated=false;
this.userSelectedExistingClientAppRegOption=event
}

onSelectedUserClientAppRegistrartion(event:any){
  this.userSelectedCustomClientAppRegOption=event;
  let selectedAppOption=this.userSpecificClientAppReg.value;
  this.selectedCustomUserClientAppRegApprovers=[];
  this.selectedCustomUserClientAppRegApproversEmail=[];
  this.loaderActive=true;
  this.userSelectedCustomClientAppRegOptionId=selectedAppOption.id;
  this.isReviewTabActivated=false;
  this.hipManagementService.getApplicationOwnersWithValidation(selectedAppOption.id).subscribe(
    {
      next : (response)=>{
        this.isUserOwnerForCustomSelectedClientAppRegOption=response.isUserOwner;
        this.isHIPOwnerForCustomSelectedClientAppRegOption=response.isHIPOwner;
        response.owners.forEach(element => {
          this.selectedCustomUserClientAppRegApprovers.push(element.owner);
          this.selectedCustomUserClientAppRegApproversEmail.push(element.email);
        });
        this.loaderActive=false;
      },
      error: (error)=>{
        console.log(error);
        this.isUserOwnerForCustomSelectedClientAppRegOption=false;
        this.isHIPOwnerForCustomSelectedClientAppRegOption=false;
        this.selectedCustomUserClientAppRegApprovers=[];
        this.selectedCustomUserClientAppRegApproversEmail=[];
        this.loaderActive=false;
      }
    }
  )
  }

triggerNext(id: any, isCurrentTabValid: any, fromTab:string){   
  if(isCurrentTabValid){
       if(fromTab=="basic"){
           this.isManagementTabActivated=true;
       }
       if(fromTab=="management"){
        this.isReviewTabActivated = true;
       }
       if(id=="management"){
        if(!this.isSpecifyMyOwnClientAppRegOptionSelected && this.isExistingClientAppRegistrationFound)
        {
          this.loaderActive=true;
          this.selectedExistingClientAppRegApprovers=[];
          this.selectedExistingClientAppRegApproversEmail=[];
          this.hipManagementService.getApplicationOwnersWithValidation(this.selectedExistingClientAppRegistration.id).subscribe(
            {
              next : (response)=>{
                this.isUserOwnerForSelectedExistingClientAppRegOption=response.isUserOwner;
                this.isHIPOwnerForSelectedExistingClientAppRegOption=response.isHIPOwner;
                response.owners.forEach(element => {
                  this.selectedExistingClientAppRegApprovers.push(element.owner);
                  this.selectedExistingClientAppRegApproversEmail.push(element.email);
                });
                this.loaderActive=false;
              },
              error: (error)=>{
                console.log(error);
                this.isUserOwnerForSelectedExistingClientAppRegOption=false;
                this.isHIPOwnerForSelectedExistingClientAppRegOption=false;
                this.loaderActive=false;
                this.selectedExistingClientAppRegApprovers=[];
                this.selectedExistingClientAppRegApproversEmail=[];
              }
            }
          )
        } else if(this.isSpecifyMyOwnClientAppRegOptionSelected){
          this.loaderActive = true;
          this.hipManagementService.getNonHIPApplications(this.applicationId).subscribe(
            {
              next : (response)=>{      
                this.isCustomSelectedAppRegListEmpty = false;
                this.clientSpecificAppRegistrationDataBind(response);
                this.loaderActive=false;
              },
              error: (error)=>{
                console.log(error);
                this.userSpecificClientAppRegDataSet=[];
                this.isCustomSelectedAppRegListEmpty = true;
                this.isUserOwnerForCustomSelectedClientAppRegOption = false;
                this.isHIPOwnerForCustomSelectedClientAppRegOption = false;
                this.loaderActive=false;
              }
            }
          )
        }
       
       }
       this.resetScroll();
       var element = Array.from(document.getElementsByName(id));    
       element[0].click();
  } 
}

triggerBackStep(id:any){
  this.resetScroll();
  var element = Array.from(document.getElementsByName(id));    
  element[0].click();
}
resetScroll(){    
  const BodyElement: HTMLElement | null = document.getElementById('bodyContent');
  if(BodyElement != null ){
    BodyElement.scrollTop = 0;
  }
}

userSearch(searchTerm: string){             
  this.userSubject.next(searchTerm);            
 }

 getAdUsers(searchText: string) {    
  this.hipManagementService.getListOfADUsersOwners(searchText).subscribe({  
    next:(response:any)=>{
      if(response.length > 0) {
        this.approversDataBind(response);              
      }
      else {
        this.approversDataSet =[];
      }        
    },
    error:(error:any)=>{
      this.approversDataSet =[];
    }     
  });
}

approversDataBind(data:any) {
  this.approversDataSet=[];
  data.forEach((element: User) => {      
    this.approversDataSet.push({                      
      'id': element.email,
      'label': element.name,
      'customLabel': element.name + ' ['+element.email+']'                                
    });  
  });
}

onAbort(isEmailNotificationRequired:boolean){
  if(isEmailNotificationRequired){
  let subscriptionDetail:NotifySubscription ={
    apimProductName: '',
    cmdbId: '',
    appRegistrationDisplayName: '',
    clientAppRegistrationAppId: ''
  }
  if(this.isExistingClientAppRegistrationFound){
    subscriptionDetail={
      apimProductName: this.breadcrumbProductId,
      cmdbId: this.selectedCmdbDetails.cmdbId,
      appRegistrationDisplayName:this.isSpecifyMyOwnClientAppRegOptionSelected?this.userSelectedCustomClientAppRegOption:this.selectedExistingClientAppRegistration.value,
      clientAppRegistrationAppId: this.isSpecifyMyOwnClientAppRegOptionSelected?this.userSelectedCustomClientAppRegOptionId:this.selectedExistingClientAppRegistration.id
    }
  }else if(this.isSpecifyMyOwnClientAppRegOptionSelected && !this.isExistingClientAppRegistrationFound){
    subscriptionDetail={
      apimProductName: this.breadcrumbProductId,
      cmdbId: this.selectedCmdbDetails.cmdbId,
      appRegistrationDisplayName:this.userSelectedCustomClientAppRegOption,
      clientAppRegistrationAppId:this.userSelectedCustomClientAppRegOptionId
    }
  }
  
  this.hipManagementService.notifySubscription(subscriptionDetail).subscribe(
    {
     next:(response)=>{
      //currently no activity is done upon successful notification. Will update here in case of new requirement
   },error: (error)=>{
      console.log(error);
   }     
}
   )
}
  if(this.isPreviousNavigationlogged){
      this.location.back();
  } else{
    this.router.navigate(["/developer/all-products"])
  }
}

onOwnerUpdate(){
  let ownerList=this.ManagementStepForm.get("owners")?.value??[];
  this.selectedOwnerList =[];
  this.selectedOwnerEmailList=[];
  ownerList?.forEach((user:{label:string,id:string})=> {
    this.selectedOwnerList.push(user.label);
    this.selectedOwnerEmailList.push(user.id);
  });
}

onSubmitSubscription(){
  this.isSubmitDisabled=true;
  if(this.clickCounter > 0){
    return;
  }
  this.clickCounter++;
 let subscriptionPayloadData:SubscriptionRequest={
   businessAreaCode: '',
   cmdbId: '',
   costCenter: '',
   famCode: '',
   apimProductName: '',
   usageNotes: '',
   clientAppRegistrationAppId: '',
   requireClientAppRegistration: false,
   owners: []
 };
 if(this.isExistingClientAppRegistrationFound){
  subscriptionPayloadData = {
    businessAreaCode: this.selectedCmdbDetails.businessArea,
    cmdbId: this.selectedCmdbDetails.cmdbId,
    costCenter: this.selectedCmdbDetails.costCenter,
    famCode: this.selectedCmdbDetails.familyCode,
    apimProductName: this.breadcrumbProductId,
    usageNotes: this.usageNotes.value,
    clientAppRegistrationAppId: this.isSpecifyMyOwnClientAppRegOptionSelected?this.userSelectedCustomClientAppRegOptionId:this.selectedExistingClientAppRegistration.id,
    requireClientAppRegistration: false,
    owners: this.isSpecifyMyOwnClientAppRegOptionSelected?this.selectedCustomUserClientAppRegApproversEmail:this.selectedExistingClientAppRegApproversEmail
}
 }else if (this.isCreateNewClientAppRegOptionSelected){
  subscriptionPayloadData = {
    businessAreaCode: this.selectedCmdbDetails.businessArea,
    cmdbId: this.selectedCmdbDetails.cmdbId,
    costCenter: this.selectedCmdbDetails.costCenter,
    famCode: this.selectedCmdbDetails.familyCode,
    apimProductName: this.breadcrumbProductId,
    usageNotes: this.usageNotes.value,
    clientAppRegistrationAppId: "",
    requireClientAppRegistration: true,
    owners: this.selectedOwnerEmailList
}
 }else if(this.isSpecifyMyOwnClientAppRegOptionSelected && !this.isExistingClientAppRegistrationFound){
  subscriptionPayloadData = {
    businessAreaCode: this.selectedCmdbDetails.businessArea,
    cmdbId: this.selectedCmdbDetails.cmdbId,
    costCenter: this.selectedCmdbDetails.costCenter,
    famCode: this.selectedCmdbDetails.familyCode,
    apimProductName: this.breadcrumbProductId,
    usageNotes: this.usageNotes.value,
    clientAppRegistrationAppId: this.userSelectedCustomClientAppRegOptionId,
    requireClientAppRegistration: false,
    owners: this.selectedCustomUserClientAppRegApproversEmail
}
 }
 this.loaderActive=true;
this.hipManagementService.createSubscription(subscriptionPayloadData).subscribe(
 {
  next:(response)=>{
console.log(response);
this.loaderActive=false;
this.feedbackActive=true;
this.successFull=true;
this.failure=false;
this.showSuccess=true;
this.isSubmitted=true;
this.isSubmitDisabled=true;
this.successMsg=" Subscription for "+this.breadcrumbProductName+" got created successfully";
timer(5000).subscribe(x => this.showSuccess = false);       
},error: (error)=>{
console.log(error);
this.loaderActive=false;
this.feedbackActive=true;
this.successFull=false;
this.failure=true;
this.failureMsg=error.message;
this.showFailure=true;
this.clickCounter--;
timer(5000).subscribe(x => this.showFailure = false);       
}
  
 }
)
}
}
