import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { ViewModel } from '../../models/view-model';
import { ActivatedRoute } from '@angular/router';
import { Router} from'@angular/router';
import { User } from '../models/user';
import {DateFormatPipe} from "../../../pipes/date-format-pipe.pipe"
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
@Component({
  selector: 'app-developer-view-product',
  templateUrl: './developer-view-product.component.html',
  styleUrls: ['./developer-view-product.component.scss'],
  providers:[DateFormatPipe]  
})
export class DeveloperViewProductComponent implements OnInit{
dataset: ViewModel={productName: '',
  description: '',
  applicationOwner: '',
  businessArea: '',
  published: '',
  requireApproval:'',
  subscriptionRequired:'',
  productID:'',
  registration:'',
  appRegistrationId:'',
  apiData: [],
  approvers: [],
  apitags: []};
detailsInfo:any[]=[];
apiTags:string[] = [];
productInfo:{parentData:{productName:string}}={parentData:{productName:""}}
loaderActive: boolean = true;
breadCrumbProductName:string = "";
breadCrumbProductId:string="";
apimProductId: string ="";
ProductName = new  FormControl('', [Validators.required, Validators.minLength(3)]);
ProductId = new FormControl('');
ViewProductForm : FormGroup = new FormGroup({}); 
appRegistrationId: string ="";
appRegistrationName: string ="";
pageInfo:string = "A Product is a collection of one or more APIs. Approvers can be assigned to keep control over any new Product subscriptions. Once approved, a subscribing application gets access to all APIs inside a Product.\n\n You are viewing a Product that has been created in Azure API Management."
 
approverList: string[] = [];
enableColonDateFormat:boolean = true;
productRoutingState:any ={};
enableAPIQueryParams:boolean = true;

constructor(private hipManagementService: Hip2ManagementService,
  protected route: ActivatedRoute, private router:Router, private dateFormatPipe: DateFormatPipe, private errorHandleService: ErrorHandleService){ 
    let passedData:any={}; // Type is any as it will map input data with different structure from many pages i.e ds table , View Product 
    passedData=this.router.getCurrentNavigation()?.extras.state??{productName:""};
    if(passedData.parentData != undefined){
      this.breadCrumbProductName=passedData.parentData.name;
      this.breadCrumbProductId=passedData.parentData.apimProductName;
      sessionStorage.setItem('productDisplayNameValue',this.breadCrumbProductName);
      sessionStorage.setItem('productId',this.breadCrumbProductId);
    }else{
      this.breadCrumbProductName=sessionStorage.getItem('productDisplayNameValue')?? "";
      this.breadCrumbProductId=sessionStorage.getItem('productId')?? "";
    }
  }

ngOnInit(): void {
  this.ViewProductForm =new FormGroup({
    ProductName : this.ProductName,
    
  });
  if(this.breadCrumbProductId == ""){
    sessionStorage.setItem('productId',this.route.snapshot.paramMap.get('productId') ?? "");
  }
  this.breadCrumbProductId=this.route.snapshot.paramMap.get('productId') ?? "";
  this.getProductByProductId(this.breadCrumbProductId!.toString());// Harded value, should be pass here from Manage product Grid.

}

getProductByProductId(apimProductId: string) {
  this.hipManagementService.getProductByProductIdDeveloperSection(apimProductId).subscribe(r => {
    let product = r;
    this.breadCrumbProductName=product.productName;
    sessionStorage.setItem('productDisplayNameValue',this.breadCrumbProductName);
    this.productRoutingState ={name:product.productName }
    this.productInfo={parentData:{productName:this.breadCrumbProductName}}
  for (let index = 0; index < product.approvers.length; index++) {
    let email = product.approvers[index];
    this.hipManagementService.getListOfADUsers_Approvers(email).subscribe({
      next:(response)=>{
       response.forEach((userData: User) => {
       this.approverList.push(userData.name);
       });
      }})
  }
  let productAPIs=[];
for (let index = 0; index < (product.apis).length; index++) {
  let element = product.apis[index];
  let api={
    apimApiName: element.apimApiName,
    name:element.name,
    apiOwner:element.apiOwner,
    apiLink:'/developer/api-definition/'+element.apimApiName
  }
  productAPIs.push(api);
}
    this.dataset = {
      productName: product.productName,
      productID: product.apimProductName,
      applicationOwner: product.appOwner,
      businessArea:product.businessAreaCode,
      description: product.description,
      published: product.state=="published"?"Yes":"No",
      subscriptionRequired:product.subscriptionRequired.toString().toLowerCase()=="true"?"Yes":"No",
      registration: this.appRegistrationName,
      appRegistrationId: product.productAppRegistration,
      requireApproval: product.approvalRequired.toString().toLowerCase()=="true"?"Yes":"No",
      approvers: product.approvers.length==0?["none"]:this.approverList,
      apitags: this.apiTags,
      apiData: productAPIs
    }
    this.appRegistrationId=product.productAppRegistration;
    this.detailsInfo = [
      {'title':'Application Name', 'value':product.applicationName},
      {'title':'Application Number', 'value':product.cmdbId},
      {'title':'Application Owner', 'value':product.appOwner},
      {'title':'Business Area', 'value': product.businessAreaCode },
      {'title':'Unit Code', 'value':product.unitCode },
      {'title':'Cost Center', 'value': product.costCenter }
    ];  
        this.loaderActive = false;  

    this.hipManagementService.getTagsByProductId(apimProductId).subscribe(a => {
      this.dataset.apitags = a;
    });
    
    this.hipManagementService.getAdAppRegistrationNameByAppId(this.appRegistrationId).subscribe(a => {
      this.dataset.registration = a[0].clientAppName;
    });

  },error =>{
this.errorHandleService.handleError(error);
  });
}

onTagSearch(event:any){
  this.router.navigate(['/developer/all-products'],{state: {search: event}});
}
}

