import { Component, Input, EventEmitter, Output, OnInit, ElementRef, HostListener } from '@angular/core';
import {Router} from '@angular/router'
import { AppEnvironmentLink } from '../../models/app-environment-link';

@Component({
  selector: 'app-primary-nav',
  templateUrl: './primary-nav.component.html',
  styleUrls: ['./primary-nav.component.scss']
})
export class PrimaryNavComponent implements OnInit {
  @Input() isActive: any;
  @Output() toggleActive = new EventEmitter<boolean>();

  @Input() internalAppEnvironmentList:Array<AppEnvironmentLink>=[];
  @Input() prodAppEnvironmentList:Array<AppEnvironmentLink>=[];
  @Input() userName:string ='user'
  @Input() userRole:string = 'DEVELOPER'//Handled default user role in child component
  @Output() logout = new EventEmitter();
  showEnvironmentDropDown: boolean = false;
  currentHIPUri:string ='';
  currentEnvironment:string =''
  showUserActivityDropDown:boolean= false;
  userActivityList:Array<String>=['LOGOUT'];
  isProdEnv:boolean = false;
  constructor(private route:Router,private elementRef: ElementRef){}

  ngOnInit() {
   this.currentHIPUri=location.origin;
   for (let index = 0; index < this.internalAppEnvironmentList.length; index++) {
    let link = this.internalAppEnvironmentList[index].envLink; 
    if(this.currentHIPUri==link){
      this.currentEnvironment=this.internalAppEnvironmentList[index].envName;
      this.isProdEnv=false;
    }
   }
   for (let index = 0; index < this.prodAppEnvironmentList.length; index++) {
    let link = this.prodAppEnvironmentList[index].envLink;
    let secondaryEnvironmentLink = this.prodAppEnvironmentList[index].secondaryEnvironmentLink;
    if(this.currentHIPUri==link || this.currentHIPUri == secondaryEnvironmentLink){
      this.currentEnvironment=this.prodAppEnvironmentList[index].envName;
      this.isProdEnv=true;
    }
   }
  }
  

  toggleClose() {
    this.isActive = !this.isActive;
    this.toggleActive.emit(this.isActive);
    
  }
  toggleAppEnvironemntDropDown(){
    this.showUserActivityDropDown = false;
    this.showEnvironmentDropDown= !this.showEnvironmentDropDown;
  }
  toggleUserActivityDropDown(){
    this.showEnvironmentDropDown=false;
    this.showUserActivityDropDown= !this.showUserActivityDropDown
  }
  manageUserActivity(activity:String){
    if(activity=='LOGOUT'){
      this.logout.emit();
    }
  }

  @HostListener('document:click', ['$event'])
  handleClickOutside(event: any) {    
    if (!this.elementRef.nativeElement.contains(event.target)) {
      this.showEnvironmentDropDown = false;
      this.showUserActivityDropDown = false;
    }
  }
}
