import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { RouterModule, Routes } from '@angular/router';
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { ViewApisComponent } from '../apis/view-apis/view-apis.component';
import { ManageApiComponent } from './manage-api/manage-api.component';
import { EditApisComponent } from './edit-apis/edit-apis.component';
import { ApiDefinitionComponent } from './api-definition/api-definition.component';
import { DeveloperApiDefinitionComponent } from './developer-api-definition/developer-api-definition.component';
import { ApiCatalogRoleEnum } from 'src/app/core/enum/UserRoleEnum';
import { AuthGuard } from 'src/app/shared/guard/auth.guard';


const routes: Routes = [
  { path: 'publisher',
  children:[
    { path: 'manage-apis/view-api/:apiId', component: ViewApisComponent,data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/manage-apis","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN,ApiCatalogRoleEnum.APPROVER]} ,canActivate: [MsalGuard,AuthGuard] },
    { path: 'manage-apis',data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/manage-apis","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN]},component:ManageApiComponent, canActivate: [MsalGuard,AuthGuard]},
    { path: 'manage-apis/edit-api/:apiId',data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/manage-apis","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN]} ,component: EditApisComponent, canActivate: [MsalGuard,AuthGuard] },
    { path: 'api-definition/:apiId',data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/manage-apis","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN,ApiCatalogRoleEnum.APPROVER]} ,component: ApiDefinitionComponent, canActivate: [MsalGuard,AuthGuard] }
  ]     
  },
  {
    path: 'developer',
    children:[
      { path: 'api-definition/:apiId', component: DeveloperApiDefinitionComponent,data: {"menu" : "Developer", "defaultModuleRoute" : "/developer/all-products"} ,canActivate: [MsalGuard] },
    ]
  }   
]


@NgModule({
    declarations: [ViewApisComponent, ManageApiComponent, EditApisComponent, ApiDefinitionComponent,DeveloperApiDefinitionComponent],
    imports: [RouterModule.forRoot(routes),SharedModule,
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      FormsModule,
      HttpClientModule,
      NgxPaginationModule, //npm install ngx-pagination --save
      Ng2SearchPipeModule, // npm i ng2-search-filter --save
      Ng2OrderModule, //npm install  ng2-order-pipe --save
      ReactiveFormsModule,],
    exports: [RouterModule]  
  })
  export class ApiModule {
  
  }

