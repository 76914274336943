import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpHandler, HttpRequest, HttpEvent, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { tap } from "rxjs/operators";
import { Environment } from '../../../environments/environment';
import { ErrorHandleService } from '../services/error-handle.service';

export const InterceptorSkipHeader = 'X-Skip-Interceptor'; 
export const Authorization = 'Authorization';

@Injectable()
export class Hip2ManagementInterceptor implements HttpInterceptor {

  constructor() { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    if (req.headers.has(InterceptorSkipHeader)) {
      const headers = req.headers.delete(Authorization);
      return next.handle(req.clone({ headers }));
    }

    return next.handle(req).pipe(
      tap({
        next: (event) => this.handleResponse(req, event),
        error: (err) => this.handleError(req, err)
      })
    );
  }

  handleResponse(req: HttpRequest<any>, event: any) {
    console.log('Handling response for ', req.url, event);
    if (event instanceof HttpResponse) {
      console.log('Request for ', req.url,
        ' Response Status ', event.status,
        ' With body ', event.body);
    }
  }

  handleError(req: HttpRequest<any>, event: any) {
    console.error('Request for ', req.url,
      ' Response Status ', event.status,
      ' With error ', event.error);
  }
}
