<nav class="sidebar" [class.close]="isClose">
  <div class="menu-bar">
    <div class="menu">
      <ng-container *ngFor="let data of SideBar_Data">
        <div class="menu-wrapper" *ngIf="data.subMenu.length === 0; else drp">
          <div class="image-text hightlightMenuHeader" [class.hightlightMenuHeader]="activeParentRoutePath==data.url" *ngIf="data.url; else onlyImage">
            <a [routerLink]="[data.url]" [routerLinkActive]="['active']">
              <span class="image">
                <img src={{data.icon}} alt="Dashboard">
              </span>

              <div class="text header-text">
                <span>{{data.title}}</span>
              </div>
            </a>
          </div>
          <ng-template #onlyImage>
            <div class="image-text">
              <span class="image">
                <img src={{data.icon}} alt="Dashboard">
              </span>
              <div class="text header-text">
                <span>{{data.title}}</span>
              </div>
            </div>

          </ng-template>
        </div>
        <ng-template #drp>
          <div class="menu-wrapper">
            <a href="javascript:void(0)" class="menu-drp" [class.openActive]="data.isActive==false?!data.isUserCollapsedMenu:true && !data.isUserCollapsedMenu" (click)="data.isUserCollapsedMenu = !data.isUserCollapsedMenu" id={{data.title}}>
              <img src={{data.icon}} alt="" class="menu-icon-tier1">
              <span class="text navdrop-text">{{data.title}}</span>
            </a>
            <ul class="menu-links" [class.active]="data.isActive==false?!data.isUserCollapsedMenu:true && !data.isUserCollapsedMenu">
              <li class="nav-link hightlightMenu" [class.hightlightMenu]="activeParentRoutePath == drpData.url" *ngFor="let drpData of data.subMenu">
                <a style="cursor: pointer;" (click)="onMenuClick(drpData.url)" [routerLinkActive]="['active']" (isActiveChange)="data.isActive = true">
                  <img src={{drpData.icon}} alt="" class="menu-icon-tier1">
                  <span class="text">{{drpData.title}}</span>
                </a>
              </li>
            </ul>
          </div>
        </ng-template>
      </ng-container>
    </div>
  </div>

  <div class="catalog-wrapper" *ngIf="!hideEsbUrl">
    <a [href]="getTopicUrl()" target="_blank" style="display: flex;" class="image-text">
      <img src="./assets/sideNav/ESB-catalog-tag.svg" alt="">
      <span class="text"><strong>{{this.linkTo.type}}</strong> Catalog</span>
    </a>
  </div>
</nav>
