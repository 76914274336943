
export const Environment = {
  production: true,
  redirectUri: () => `${window.location.origin}/`,
  clientId: '4a3c97f5-23cb-4965-a220-92c3c5df0404',
  authority: 'https://login.microsoftonline.com/556e6b1f-b49d-4278-8baf-db06eeefc8e9',
  scopes: ["api://8a9277c9-9c7d-4a76-8cda-f697f2aa9940/access-as-user"],
  managementHIPUri: 'https://ase-p-app-10001236-hip-mgmt-prd.azurewebsites.net',  
  esbUri:'', 
  piiLoggingEnabled: true, // log personal and organizational data
  appTitle: 'API Catalog - Production - HIP 2.0',
  environment: 'prd'
};
