    <app-bread-crumb [links]="[
    {
      'href': '/developer/all-products',
      'title': 'Products & APIs'
    },
    {
        'href': '/developer/product-subscription/'+this.breadcrumbProductId,
        'title': this.breadcrumbProductName,
        'isQueryParamsReq': true,
        'queryparams': {'productName' : this.breadcrumbProductName}     
    },
    {
        'href': '/developer/product-subscription/'+this.breadcrumbProductId,
        'title': 'Create Subscription',
        'isQueryParamsReq': true,
        'queryparams': {'productName' : this.breadcrumbProductName}
    }
    ]"></app-bread-crumb>
    <section class="content-section"  #contentSection [class.disable-edit]="isSubmitted" >
        <div class="form-section form-min-height">
            <div class="container form-head">
                <h2 class="title">New Subscription</h2>
                <p>Complete the required information below step-by-step to create a new Subscription.</p>      
            </div>
            <div class="container">
                <div class="row">
                    <ul class="nav nav-tabs ds_c-tablist" role="tablist">
                        <li class="nav-item">
                        <a
                          class="nav-link active"
                          [class.activeClick]="isBasicTabActivated && !isSubmitted"
                          data-bs-toggle="tab"
                          data-bs-target="#basic"
                          href="" 
                          name="basic"
                          >Basic</a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          [class.activeClick]="isManagementTabActivated && !isSubmitted"
                          data-bs-toggle="tab"
                          data-bs-target="#management"
                          href=""
                          name="management"
                          >Management</a>
                      </li>
                      <li class="nav-item">
                        <a
                          class="nav-link"
                          [class.activeClick]="isReviewTabActivated && !isSubmitted"
                          data-bs-toggle="tab"
                          href=""
                          name="review"
                          data-bs-target="#review"
                          >Review & Create
                        </a>
                      </li>
                    </ul>
                    <div class="tab-content">
                        <div id="basic" class="container tab-pane active">
                                <p>To guide you through this product subscription wizard, please identify the application that you want to subscribe to this product. </p> 
                                <div class="row col-12 col-sm-12 col-md-8">          
                                  <app-auto-suggest
                                    [props]="{
                                      heading: 'Application Name / Number',
                                      required: true,
                                      disable: false,
                                      info: 'Select the application name or number from CMDB where the product subscription belongs to',
                                      placeholder: 'Type at least 3 characters to search in CMDB',
                                      'errMsg': 'Please enter a valid application name or number from CMDB '
                                    }"
                                   [dataSets]=""
                                   [displayMultiple]=true
                                   [control]="getBasicStepFormControl('appNameAndNumber')"
                                   [displayControl]="getBasicStepFormControl('displayAppNameAndNumber')"
                                   (keydown.enter)="preventSubmit($event)"
                                   [dataSets]="applicationDataSet"
                                   (search)="applicationSearch($event)"
                                   (newItemEvent)="appSelected($event)"
                                  ></app-auto-suggest>
                                  </div>
                                  <div class="row" *ngIf="isBasicBackgroundCheckCompleted">
                                      <p class="grey-backgroud" *ngIf="isExistingClientAppRegistrationFound">
                                        We could find some existing Client App Registration(s) created by HIP in the past. Please, indicate how you want to proceed:
                                      </p>
                                      <p class="grey-backgroud" *ngIf="!isExistingClientAppRegistrationFound">
                                        We couldn't find any existing Client App Registration created by HIP in the past. Please, indicate how you want to proceed:                                      </p>
                                    <div class="grey-backgroud" *ngIf="isExistingClientAppRegistrationFound">
                                        <app-radio-buttons [props]="{
                                      'required': 0,
                                      'id': 'publish',
                                      'radio':[{checked: false, label: 'I want to reuse such existing Client App Registration by selecting one from the list below'}, {checked: false, label: 'I want to specify my own Client App Registration in the next step'}]
                                    }"  [isOverrideLabelStyle]="true"
                                    (EnableEvent)="onClientAppRegOptionChange($event)"
                                    [control]="getBasicStepFormControl('clientAppReguserOption')"
                                    ></app-radio-buttons>
                                  </div>
                                  <div class="grey-backgroud" *ngIf="!isExistingClientAppRegistrationFound">
                                    <app-radio-buttons [props]="{
                                  'required': 0,
                                  'id': 'publish',
                                  'radio':[{checked: false, label: 'I want to let HIP create a new Client App Registration'}, {checked: false, label: 'I want to specify my own Client App Registration in the next step'}]
                                }"  [isOverrideLabelStyle]="true"
                                [control]="getBasicStepFormControl('clientAppReguserOption')"
                                (EnableEvent)="onClientAppRegOptionChange($event)"
                                ></app-radio-buttons>
                              </div>
                              <div class="row drop-down-wrapper" *ngIf="isExistingClientAppRegistrationFound && !isSpecifyMyOwnClientAppRegOptionSelected">
                              <app-search-dropdown
                                     [props]="baseTabClientAppRegProps"
                                   (keydown.enter)="preventSubmit($event)"
                                   [control]="getBasicStepFormControl('clientAppRegistartion')"
                                   [dataSets]="appRegDataSet"
                                   (changed)="onSelectedExistingClientAppRegistrartion($event)"
                                   [selectedValue]="userSelectedExistingClientAppRegOption"
                                   ></app-search-dropdown>
                                  </div>
                            </div>
                            <div class="col-sm buttons-align-end button-wrapper button-alignment">
                              <app-buttons
                            [props]="isbaseTabCompleted==true?
                              {
                                icon: '',
                                content: 'Next Step',
                                classType: 'primary',
                                type:'button'
                              }
                            :{
                              icon: '',
                              content: 'Next Step',
                              href: '#',
                              classType: 'disable',
                              type:'button'
                            }"
                            (click)=" triggerNext(
                              'management',
                              this.isbaseTabCompleted,
                              'basic'
                            )
                          "  
                            ></app-buttons>
                            </div>
                        </div>
                        <div id="management" class="container tab-pane">
                            <div class="row" *ngIf="isExistingClientAppRegistrationFound && !isSpecifyMyOwnClientAppRegOptionSelected">
                            <p>You have chosen to reuse the following Client App Registration.</p>
                            <h3 class="title">Client App Registration Name</h3>
                            <app-search-dropdown
                                   [props]="{placeholder: 'Select Client App Registration Name ',required: false,disable: true,info: 'Select the application registration name from Azure AD where the product subscription belongs to. Only application registrations that belong to the selected application from CMDB can be added to the product subscription.','errMsg': 'Please select a valid application registration name from Azure AD '}"
                                 (keydown.enter)="preventSubmit($event)"
                                 [control]="getBasicStepFormControl('clientAppRegistartion')"
                                 [dataSets]="appRegDataSet"
                                 (changed)="onSelectedExistingClientAppRegistrartion($event)"
                                 [selectedValue]="userSelectedExistingClientAppRegOption"
                                 ></app-search-dropdown>
                                 <ng-container *ngIf="!loaderActive">
                                 <div *ngIf="isUserOwnerForSelectedExistingClientAppRegOption && isHIPOwnerForSelectedExistingClientAppRegOption" class="success_Icon success-message"><img src="../../../../assets/successIcon.svg"/><p class="paragraph">We verified that you are listed as an owner of this Client App Registration.</p></div>
                                 <div *ngIf="!isUserOwnerForSelectedExistingClientAppRegOption" class="unSuccess_Icon error-message"><img src="../../../../assets/errorIcon.svg"/><p class="paragraph">We detected that you are not listed as an owner of this Client App Registration. Please, contact the people below to make you an owner too.</p></div>   
                                 <div *ngIf="isUserOwnerForSelectedExistingClientAppRegOption && !isHIPOwnerForSelectedExistingClientAppRegOption" class="unSuccess_Icon error-message"><img src="../../../../assets/errorIcon.svg"/><p class="paragraph">We detected that HIP is not listed as an owner of this Client App Registration. Please, check the documentation below to fix this. </p></div>
                                </ng-container>
                                 <div class="row row-wrapper">
                                  <h3 class="title">Client App Registration Owners</h3>
                                  <app-render-control-values [value]="selectedExistingClientAppRegApprovers" [type]="'tag'"></app-render-control-values>
                                 </div>
                                 <p class="note-section-wrapper">
                                 <strong>Note:</strong> We would like to remind you of the available documentation about "<a href="https://ac-its.atlassian.net/wiki/spaces/AHIP/pages/22216724/How+to+create+an+Application+Registration+for+a+Product+Subscription" target="_blank" class="link-font-size">How to create/configure an App Registration?</a>"
                                  </p>
                                 <p class="second-option-wrapper" *ngIf="!isUserOwnerForSelectedExistingClientAppRegOption">
                                 Your Product subscription request stops here. Please, come back when you are added as an owner.
                                 </p>
                                 <p class="second-option-wrapper" *ngIf="isUserOwnerForSelectedExistingClientAppRegOption && !isHIPOwnerForSelectedExistingClientAppRegOption">
                                  Your Product subscription request stops here. Please, come back when HIP is added as an owner. 
                                </p>
                                 <div class="buttons-align-end button-wrapper" *ngIf="!isUserOwnerForSelectedExistingClientAppRegOption || !isHIPOwnerForSelectedExistingClientAppRegOption">
                                 <app-buttons
                                 [props]="
                                   {
                                     icon: '',
                                     content: 'Abort',
                                     classType: 'primary',
                                     type:'button'
                                   }" 
                                   (click)="onAbort(true)"
                                 ></app-buttons>
                                </div>
                                <div>            
                                  <app-text-field 
                                    [props]="(isUserOwnerForSelectedExistingClientAppRegOption && isHIPOwnerForSelectedExistingClientAppRegOption)?{
                                      heading: 'Usage Notes',
                                      placeholder: 'Enter',
                                      required: false,
                                      disable: false,
                                      info: '',
                                      errMsg: ''
                                    }:{
                                      heading: 'Usage Notes',
                                      placeholder: 'Enter',
                                      required: false,
                                      disable: true,
                                      info: '',
                                      errMsg: ''
                                    }"                                  
                                    (keydown.enter)="preventSubmit($event)"
                                    [control]="usageNotes"
                                  ></app-text-field>
                                  </div>
                                  <div class="col-sm buttons-align-end button-wrapper button-alignment">
                                    <app-buttons
                                  [props]="
                                    {
                                      icon: '',
                                      content: 'Back',
                                      classType: 'tertiary',
                                      type:'button'
                                    }
                                  " 
                                  (click)="triggerBackStep('basic')"
                                  ></app-buttons>
                                    <app-buttons
                                  [props]="(isUserOwnerForSelectedExistingClientAppRegOption==true && isHIPOwnerForSelectedExistingClientAppRegOption==true)?
                                    {
                                      icon: '',
                                      content: 'Next Step',
                                      classType: 'primary',
                                      type:'button'
                                    }
                                  :{
                                    icon: '',
                                    content: 'Next Step',
                                    href: '#',
                                    classType: 'disable',
                                    type:'button'
                                  }" 
                                  (click)=" triggerNext(
                              'review',
                              (isUserOwnerForSelectedExistingClientAppRegOption==true && isHIPOwnerForSelectedExistingClientAppRegOption==true),
                              'management'
                            ) "
                                  ></app-buttons>
                                  </div>
                            </div>
                            <div class="row" *ngIf="isSpecifyMyOwnClientAppRegOptionSelected">
                              <p>
                                You have chosen to specify your own Client App Registration. Please, select one from the list below.
                              </p>
                              <app-search-dropdown
                                     [props]="{heading: 'Client App Registration Name',placeholder: 'Select Client App Registration Name ',required: true,disable: false,info: 'Select the application registration name from Azure AD where the product subscription belongs to. Only application registrations that belong to the selected application from CMDB can be added to the product subscription.','errMsg': 'Please select a valid application registration name from Azure AD '}"
                                   (keydown.enter)="preventSubmit($event)"
                                   [control]="userSpecificClientAppReg"
                                   [dataSets]="userSpecificClientAppRegDataSet"
                                   (changed)="onSelectedUserClientAppRegistrartion($event)"
                                   ></app-search-dropdown>
                                   <div *ngIf="!loaderActive && isCustomSelectedAppRegListEmpty" class="unSuccess_Icon error-message"><img src="../../../../assets/errorIcon.svg"/><p class="paragraph">We couldn't find any existing Client App Registration for the application that you want to subscribe to this product. Please, check the documentation below to create one.</p></div>   
                                   <ng-container *ngIf="!loaderActive && userSelectedCustomClientAppRegOption.length>0">
                                   <div *ngIf="isUserOwnerForCustomSelectedClientAppRegOption && isHIPOwnerForCustomSelectedClientAppRegOption" class="success_Icon success-message"><img src="../../../../assets/successIcon.svg"/><p class="paragraph">We verified that you are listed as an owner of this Client App Registration.</p></div>
                                   <div *ngIf="!isUserOwnerForCustomSelectedClientAppRegOption" class="unSuccess_Icon error-message"><img src="../../../../assets/errorIcon.svg"/><p class="paragraph">We detected that you are not listed as an owner of this Client App Registration. Please, contact the people below to make you an owner too.</p></div>   
                                   <div *ngIf="isUserOwnerForCustomSelectedClientAppRegOption && !isHIPOwnerForCustomSelectedClientAppRegOption" class="unSuccess_Icon error-message"><img src="../../../../assets/errorIcon.svg"/><p class="paragraph">We detected that HIP is not listed as an owner of this Client App Registration. Please, check the documentation below to fix this. </p></div>
                                   <div class="row row-wrapper">
                                    <h3 class="title">Client App Registration Owners</h3>
                                    <app-render-control-values [value]="selectedCustomUserClientAppRegApprovers" [type]="'tag'"></app-render-control-values>
                                   </div>
                                  </ng-container>
                                   <p class="note-section-wrapper">
                                   <strong>Note:</strong> We would like to remind you of the available documentation about "<a href="https://ac-its.atlassian.net/wiki/spaces/AHIP/pages/22216724/How+to+create+an+Application+Registration+for+a+Product+Subscription" target="_blank" class="link-font-size">How to create/configure an App Registration?</a>"
                                    </p>
                                    <ng-container *ngIf="!loaderActive && isCustomSelectedAppRegListEmpty">
                                    <p class="second-option-wrapper" *ngIf="isCustomSelectedAppRegListEmpty">
                                      Your Product subscription request stops here. Please, come back when you have created your own Client App Registration. 
                                    </p>
                                    <div class="buttons-align-end button-wrapper" *ngIf="isCustomSelectedAppRegListEmpty">
                                      <app-buttons
                                      [props]="
                                        {
                                          icon: '',
                                          content: 'Abort',
                                          classType: 'primary',
                                          type:'button'
                                        }" 
                                        (click)="onAbort(false)"
                                      ></app-buttons>
                                     </div>
                                     <app-text-field 
                                      [props]="{
                                        heading: 'Usage Notes',
                                        placeholder: 'Enter',
                                        required: false,
                                        disable: true,
                                        info: '',
                                        errMsg: ''
                                      }"                                  
                                      (keydown.enter)="preventSubmit($event)"
                                      [control]="usageNotes"
                                    ></app-text-field>
                                  </ng-container>
                                  <ng-container *ngIf="!loaderActive && userSelectedCustomClientAppRegOption.length>0">
                                   <p class="second-option-wrapper" *ngIf="!isUserOwnerForCustomSelectedClientAppRegOption">
                                   Your Product subscription request stops here. Please, come back when you are added as an owner.
                                   </p>
                                   <p class="second-option-wrapper" *ngIf="isUserOwnerForCustomSelectedClientAppRegOption && !isHIPOwnerForCustomSelectedClientAppRegOption">
                                    Your Product subscription request stops here. Please, come back when HIP is added as an owner. 
                                  </p>
                                   <div class="buttons-align-end button-wrapper" *ngIf="!isUserOwnerForCustomSelectedClientAppRegOption || !isHIPOwnerForCustomSelectedClientAppRegOption">
                                   <app-buttons
                                   [props]="
                                     {
                                       icon: '',
                                       content: 'Abort',
                                       classType: 'primary',
                                       type:'button'
                                     }" 
                                     (click)="onAbort(true)"
                                   ></app-buttons>
                                  </div>
                                  <div>            
                                    <app-text-field 
                                      [props]="(isUserOwnerForCustomSelectedClientAppRegOption && isHIPOwnerForCustomSelectedClientAppRegOption)?{
                                        heading: 'Usage Notes',
                                        placeholder: 'Enter',
                                        required: false,
                                        disable: false,
                                        info: '',
                                        errMsg: ''
                                      }:{
                                        heading: 'Usage Notes',
                                        placeholder: 'Enter',
                                        required: false,
                                        disable: true,
                                        info: '',
                                        errMsg: ''
                                      }"                                  
                                      (keydown.enter)="preventSubmit($event)"
                                      [control]="usageNotes"
                                    ></app-text-field>
                                    </div>
                                    </ng-container>
                                    <div class="col-sm buttons-align-end button-wrapper button-alignment">
                                      <app-buttons
                                    [props]="
                                      {
                                        icon: '',
                                        content: 'Back',
                                        classType: 'tertiary',
                                        type:'button'
                                      }
                                    " 
                                    (click)="triggerBackStep('basic')"
                                    ></app-buttons>
                                      <app-buttons
                                    [props]="(isUserOwnerForCustomSelectedClientAppRegOption==true && isHIPOwnerForCustomSelectedClientAppRegOption==true)?
                                      {
                                        icon: '',
                                        content: 'Next Step',
                                        classType: 'primary',
                                        type:'button'
                                      }
                                    :{
                                      icon: '',
                                      content: 'Next Step',
                                      href: '#',
                                      classType: 'disable',
                                      type:'button'
                                    }"  
                                    (click)=" triggerNext(
                              'review',
                              (isUserOwnerForCustomSelectedClientAppRegOption==true && isHIPOwnerForCustomSelectedClientAppRegOption==true),
                              'management'
                            ) "
                                    ></app-buttons>
                                    </div>
                              </div>
                              <div class="row" *ngIf="isCreateNewClientAppRegOptionSelected">
                                <p>
                                  You have chosen to let HIP create a new Client App Registration. Please, provide one or more owners.
                                </p>
                                  <app-multi-select-dropdown
                                    [props]="{
                                      heading: 'App Registration Owner',
                                      placeholder: 'Search in Entra ID',
                                      required: true,
                                      disable: false,
                                      info: 'Select one or more owners',
                                      'errMsg': 'Please select at least one owner'
                                    }"
                                    style="position: relative"
                                    (keydown.enter)="preventSubmit($event)"
                                    [isCustomLabelForOptionsRequired] = "true"
                                    (search)="userSearch($event)"
                                    [dataSets]="approversDataSet"
                                    [control]="getManagementStepFormControl('owners')"
                                    (change)="onOwnerUpdate()"          
                                    ></app-multi-select-dropdown>
                                    <div>            
                                      <app-text-field 
                                        [props]="{
                                          heading: 'Usage Notes',
                                          placeholder: 'Enter',
                                          required: false,
                                          disable: false,
                                          info: '',
                                          errMsg: ''
                                        }"                                 
                                        (keydown.enter)="preventSubmit($event)"
                                        [control]="usageNotes"
                                      ></app-text-field>
                                      </div>
                                      <div class="col-sm buttons-align-end button-wrapper button-alignment">
                                        <app-buttons
                                      [props]="
                                        {
                                          icon: '',
                                          content: 'Back',
                                          classType: 'tertiary',
                                          type:'button'
                                        }
                                      " 
                                      (click)="triggerBackStep('basic')"
                                      ></app-buttons>
                                        <app-buttons
                                      [props]="(ManagementStepForm.valid)?
                                        {
                                          icon: '',
                                          content: 'Next Step',
                                          classType: 'primary',
                                          type:'button'
                                        }
                                      :{
                                        icon: '',
                                        content: 'Next Step',
                                        href: '#',
                                        classType: 'disable',
                                        type:'button'
                                      }" 
                                      (click)=" triggerNext(
                                        'review',
                                        ManagementStepForm.valid,
                                        'management'
                                      ) " 
                                      ></app-buttons>
                                      </div>
                                </div>
                          </div>
                        <div id="review" class="container tab-pane fade">
                          <p>You have reached the final step of this product subscription wizard. Please, verify the provided information below before submitting your subscription.</p>
                          <div class="row">
                            <div>
                              <h3 class="title">Application Name / Number</h3>
                              <h3 class="title bold">{{this.selectedApplicationName}} / {{this.selectedApplicationId}}</h3>
                          </div>
                          </div>
                          <div class="grey-backgroud row-wrapper-disable" *ngIf="isExistingClientAppRegistrationFound">
                            <app-radio-buttons [props]="{
                          'required': 0,
                          'id': 'publish',
                          'radio':[{checked: false, label: 'I want to reuse such existing Client App Registration by selecting one from the list below'}, {checked: false, label: 'I want to specify my own Client App Registration in the next step'}]
                        }"  [isOverrideLabelStyle]="true"
                        [control]="getBasicStepFormControl('clientAppReguserOption')"
                        [isDisableRadioButtonEnabled]="true"
                        ></app-radio-buttons>
                      </div>
                      <div class="grey-backgroud row-wrapper-disable" *ngIf="!isExistingClientAppRegistrationFound">
                        <app-radio-buttons [props]="{
                      'required': 0,
                      'id': 'publish',
                      'radio':[{checked: false, label: 'I want to let HIP create a new Client App Registration'}, {checked: false, label: 'I want to specify my own Client App Registration in the next step'}]
                    }"  [isOverrideLabelStyle]="true"
                    [control]="getBasicStepFormControl('clientAppReguserOption')"
                    [isDisableRadioButtonEnabled]="true"
                    ></app-radio-buttons>
                  </div>
                  <div class="row drop-down-wrapper">
                    <div class="col-12 col-sm-12" *ngIf="!isCreateNewClientAppRegOptionSelected">
                      <h3 class="title">Client App Registration Name</h3>
                      <h3 class="title bold" *ngIf="isExistingClientAppRegistrationFound && !isSpecifyMyOwnClientAppRegOptionSelected">{{this.userSelectedExistingClientAppRegOption}}</h3>
                      <h3 class="title bold" *ngIf="isSpecifyMyOwnClientAppRegOptionSelected">{{this.userSelectedCustomClientAppRegOption}}</h3>
                  </div>
                  <div class="row row-wrapper">
                    <h3 class="title">Client App Registration Owners</h3>
                    <app-render-control-values *ngIf="isExistingClientAppRegistrationFound && !isSpecifyMyOwnClientAppRegOptionSelected" [value]="selectedExistingClientAppRegApprovers" [type]="'tag'"></app-render-control-values>
                    <app-render-control-values *ngIf="isSpecifyMyOwnClientAppRegOptionSelected" [value]="selectedCustomUserClientAppRegApprovers" [type]="'tag'"></app-render-control-values>
                    <app-render-control-values *ngIf="isCreateNewClientAppRegOptionSelected" [value]="this.selectedOwnerList" [type]="'tag'"></app-render-control-values>
                   </div>
                  </div>
                  <div class="row row-wrapper">
                    <h3 class="title">Usage Notes</h3>
                    <h3 class="title bold">{{this.usageNotes.value}}</h3>
                  </div>
                  <p class="small-note-section-wrapper">
                    <strong>Note:</strong> We would like to remind you of the available documentation about "<a href="https://ac-its.atlassian.net/wiki/spaces/AHIP/pages/22216835/How+to+manage+Client+Secrets" target="_blank" class="link-font-size">How to manage Client Secrets within an App Registration?</a>"
                  </p>
                  <div class="col-sm buttons-align-end button-wrapper button-alignment">
                    <div class="feedbackMsg" [class.feedbackActive]="this.feedbackActive">
                      <ng-container *ngIf="successFull" >
                        <span class="successColor">{{successMsg}}</span>
                        <img src="./assets/successIcon.svg"/>
                      </ng-container>
                      <ng-container *ngIf="failure" >
                        <span class="failureColor">{{failureMsg}}</span>
                        <img class="error" src="../../../../assets/Health-monitor/Unhealthy.svg"/>
                      </ng-container>
                      
                    </div>
                    <app-buttons
                  [props]="isSubmitDisabled?{
                    icon: '',
                    content: 'Back',
                    classType: 'disable',
                    type:'button'
                  }:
                    {
                      icon: '',
                      content: 'Back',
                      classType: 'tertiary',
                      type:'button'
                    }
                  " 
                  (click)="triggerBackStep('management')"
                  ></app-buttons>
                    <app-buttons
                  [props]="isSubmitDisabled?{
                    icon: '',
                    content: 'Submit',
                    classType: 'disable',
                    type:'button' 
                  }:
                    {
                      icon: '',
                      content: 'Submit',
                      classType: 'primary',
                      type:'button'
                    }
                  "  
                  (click)="onSubmitSubscription()"
                  ></app-buttons>
                  </div>
                  </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="info-section container">
          <app-information-section [information]="pageInfo" [detailsInfo]="this.detailsInfo"></app-information-section>
        </div>
        <app-general-loader *ngIf="loaderActive"></app-general-loader>
      </section>
      <app-alert *ngIf="showSuccess" [success]="true">
        <div class="success_Icon"><img src="../../../../assets/successIcon.svg"/><p>{{successMsg}}</p></div>   
      </app-alert>
      <app-alert *ngIf="showFailure" [failure]="true">
        <div class="unSuccess_Icon"><img src="../../../../assets/Health-monitor/Unhealthy.svg"/><p>{{failureMsg}}</p></div>   
      </app-alert>
