import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class ErrorHandleService {

  constructor(private router: Router) { }

  public handleError(error: HttpErrorResponse){
  switch(error.status){
  case 404:
    this.router.navigate(['/not-found']);
    break;
  case 403:
  case 401:
    this.router.navigate(['/unauthorized']);
    break;  
}
  }
}
