import { NgModule } from '@angular/core';
import { DynamicTableComponent } from './components/dynamic-table/dynamic-table.component';
import { PrimaryNavComponent } from './components/primary-nav/primary-nav.component';
import { MenuComponent } from '../core/menu/menu.component';
import { AutoSuggestComponent } from './components/auto-suggest/auto-suggest.component';
import { BreadCrumbComponent } from './components/bread-crumb/bread-crumb.component';
import { ButtonsComponent } from './components/buttons/buttons.component';
import { CheckboxComponent } from './components/checkbox/checkbox.component';
import { ClickToTopComponent } from './components/click-to-top/click-to-top.component';
import { DsTableComponent } from './components/ds-table/ds-table.component';
import { FooterComponent } from './components/footer/footer.component';
import { InformationSectionComponent } from './components/information-section/information-section.component';
import { MultiSelectDropdownComponent } from './components/multi-select-dropdown/multi-select-dropdown.component';
import { RadioButtonsComponent } from './components/radio-buttons/radio-buttons.component';
import { SecondaryNavComponent } from './components/secondary-nav/secondary-nav.component';
import { SelectDrpdwnComponent } from './components/select-drpdwn/select-drpdwn.component';
import { ShimmerTableComponent } from './components/shimmer-table/shimmer-table.component';
import { TextAreaComponent } from './components/text-area/text-area.component';
import { TextFieldComponent } from './components/text-field/text-field.component';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from '../app-routing.module';
import {ProductCardComponent} from './components/product-card/product-card.component'
import {RenderControlValuesComponent} from './components/render-control-values/render-control-values.component'
import {GeneralLoaderComponent} from './components/general-loader/general-loader.component';
import { AlertComponent } from './components/alert/alert.component';
import { ProductListingCardComponent } from './components/product-listing-card/product-listing-card.component'
import { AutoSearchTagComponent } from './components/auto-search-tag/auto-search-tag.component';
import { HealthCardComponent } from './components/health-card/health-card.component';
import { DonutChartComponent } from './components/donut-chart/donut-chart.component';
import { TextMaskComponent } from './components/text-mask/text-mask.component';
import { HealthTimelineComponent } from './components/health-timeline/health-timeline.component'
import { SearchDropdownComponent } from './components/search-dropdown/search-dropdown.component';
import { ShimmerCardComponent } from './components/shimmer-card/shimmer-card.component';
import { ModalComponent } from './components/modal/modal.component';
import { PopUpsComponent } from './components/pop-ups/pop-ups.component';
import { CopyClipboardComponent } from './components/copy-clipboard/copy-clipboard.component';
import { SliderSwitchComponent } from './components/slider-switch/slider-switch.component';
import { LabelComponent } from './components/label/label.component';



@NgModule({
    declarations: [
    DynamicTableComponent,
    MenuComponent,
    PrimaryNavComponent,
    SecondaryNavComponent,
    FooterComponent,
    TextFieldComponent,
    TextAreaComponent,
    RadioButtonsComponent,
    MultiSelectDropdownComponent,
    AutoSuggestComponent,
    ButtonsComponent,
    InformationSectionComponent,
    CheckboxComponent,
    SelectDrpdwnComponent,
    DsTableComponent,
    ShimmerTableComponent,
    BreadCrumbComponent,
    ClickToTopComponent,
    ProductCardComponent,
    RenderControlValuesComponent,
    GeneralLoaderComponent,
    AlertComponent,
    ProductListingCardComponent,    
    AutoSearchTagComponent, HealthTimelineComponent, 
   ShimmerCardComponent, SearchDropdownComponent, ModalComponent, PopUpsComponent,  TextMaskComponent, HealthTimelineComponent, 
   ShimmerCardComponent, SearchDropdownComponent, CopyClipboardComponent, SliderSwitchComponent, LabelComponent,
    // DonutChartComponent, 
    // HealthCardComponent    

    ],
    imports: [    
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        HttpClientModule,
        NgxPaginationModule, //npm install ngx-pagination --save
        Ng2SearchPipeModule, // npm i ng2-search-filter --save
        Ng2OrderModule, //npm install  ng2-order-pipe --save
        ReactiveFormsModule,
        ],
    exports: [   DynamicTableComponent,
        MenuComponent,
        PrimaryNavComponent,
        SecondaryNavComponent,
        FooterComponent,
        TextFieldComponent,
        TextAreaComponent,
        RadioButtonsComponent,
        MultiSelectDropdownComponent,
        AutoSuggestComponent,
        ButtonsComponent,
        InformationSectionComponent,
        CheckboxComponent,
        SelectDrpdwnComponent,
        DsTableComponent,
        ShimmerTableComponent,
        BreadCrumbComponent,
        ClickToTopComponent,
        ProductCardComponent,
        RenderControlValuesComponent,
        GeneralLoaderComponent,
        AlertComponent,
        ProductListingCardComponent,
        AutoSearchTagComponent,
        TextMaskComponent, 
        HealthTimelineComponent,
        ModalComponent,
        CopyClipboardComponent,
        ShimmerCardComponent,
        SearchDropdownComponent,
        PopUpsComponent,
        SliderSwitchComponent,
        LabelComponent
        ]  
  })
  export class SharedModule {
  
  }

