import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-render-control-values',
  templateUrl: './render-control-values.component.html',
  styleUrls: ['./render-control-values.component.scss']
})
export class RenderControlValuesComponent implements OnInit{
  @Input() value : any = {}
  @Input() type : string = ''
  @Input() isRenderLabelOnlyEnabled:boolean = false;
  @Input() isClickableLabel:boolean=false;
  @Input() isTagLabel:boolean = false;
  renderTag: boolean = false
  @Output() tagClickEvent:EventEmitter<string>=new EventEmitter();

  ngOnInit(): void {
    if(this.type == 'tag'){
      this.renderTag = true
    }else{
      this.value
    }
  }
  onLabelClick(tagValue:any){
    if(this.isClickableLabel){
      this.tagClickEvent.emit(tagValue);
    }
  }
}
