<app-bread-crumb [links]="[
{
  'href': '/publisher/manage-products',
  'title': 'Manage Products'
},
{
  'href': '/publisher/manage-products/add-product',
  'title': 'Add Product'
}
]"></app-bread-crumb>
<section class="content-section">
    <form class="form-section" [formGroup]="ProductForm" (ngSubmit)="onSubmit()" [class.loading]="loaderActive" [class.disableClick]="successFull">
      <div class="container form-head">
          <h2 class="title">New Product</h2>
          <p>Complete the required information below step-by-step to add a new Product.</p>

      </div>
      <div class="container">
        <div class="row">
          <ul class="nav nav-tabs ds_c-tablist" role="tablist">
            <li class="nav-item">
            <a
              class="nav-link active"
              [class.activeClick]="true"
              data-bs-toggle="tab"
              data-bs-target="#basic"
              href="" 
              name="basic"
              >Basic</a>
          </li>
          <li class="nav-item" *ngIf="!isDraftProduct">
            <a
              class="nav-link"
              [class.activeClick]="this.basicTabProductForm.valid"
              data-bs-toggle="tab"
              data-bs-target="#management"
              href=""
              name="management"
              >Management</a>
          </li>
          <li class="nav-item">
            <a
              class="nav-link"
              [class.activeClick]="((this.basicTabProductForm.valid && managementTabProductForm.valid && (this.Approvers.valid))|| (isDraftProduct && getControl('Product').valid && getControl('AppNameAndNumber').valid ))"
              data-bs-toggle="tab"
              href=""
              name="review"
              data-bs-target="#review"
              >Review & Create
            </a>
          </li>
        </ul>
        <div class="tab-content">
          <div id="basic" class="container tab-pane active">
            <p>To guide you through this product creation wizard, please provide the following details.</p>
            <div class="row checkbox-wrapper">        
              <app-checkbox class="col-12 col-sm" [label]="draftProductLabel" [id]="draftProductLabel" [isCheckBoxRenderedInsideList]="false" [infoDetail]="draftProductInfo" (changeEvent)="onDraftProductToggle($event)" [selectedOptions]="draftProductOption"></app-checkbox>
            </div>
            <div class="row">
              <app-text-field class="col-sm" [props]="{
                'heading': 'Product Name',
                'placeholder': 'Enter',
                'required': true,
                'disable': false,
                'info': 'Enter the name of the product',
                'errMsg': 'Please enter a valid product name between 3 to 59 characters'
              }"                    
              [control]="getControl('Product')"
              (focusout)="populateProductId()"
              (keydown.enter)="preventSubmit($event)"
              [checkError]="checkError"
              ></app-text-field>
              <app-text-field
              class="col-sm"
              [props]="{
                heading: 'Product Id',
                placeholder: '',
                required: false,
                disable: true,
                info: '',
                errMsg: ''
              }"                                  
              [control]="getControl('ProductId')"
              (keydown.enter)="preventSubmit($event)"
              [checkError]="checkError"
            ></app-text-field>
            </div>
            <div class="row">          
              <app-auto-suggest
                [props]="{
                  heading: 'Application Name / Number',
                  required: true,
                  disable: false,
                  info: 'Select the application name or number from CMDB where the product belongs to',
                  placeholder: 'Type at least 3 characters to search in CMDB',
                  'errMsg': 'Please enter a valid application name or number from CMDB '
                }"
               (search)="applicationSearch($event)"
               [dataSets]="applicationDataSet"
               (newItemEvent)="appSelected($event)"
               [displayMultiple]=true
               [control]="getControl('AppNameAndNumber')"
               [displayControl]="getControl('displayAppNameAndNumber')"
               (keydown.enter)="preventSubmit($event)"
                class="col-sm"
                [checkError]="checkError"
              ></app-auto-suggest>
              <app-text-field
                class="col-sm"
                [props]="{
                  heading: 'Application Owner',
                  placeholder: '',
                  required: false,
                  disable: true,
                  info: '',
                  errMsg: ''
                }"                         
                [control]="getControl('AppOwner')"
                (keydown.enter)="preventSubmit($event)"
                [checkError]="checkError"
              ></app-text-field>
            </div>
            <div class="row">
              <app-text-area
              class="col-sm" 
              [props]="{
                heading: 'Description',
                placeholder: 'Enter',
                required: true,
                disable: isDraftProduct,
                info: 'Enter a detailed description about the product ',
                'errMsg': 'Please enter a product description'
              }"
              (keydown.enter)="preventSubmit($event)"
              [control]="getControl('Description')"
              [checkError]="checkError"
            ></app-text-area>
            </div>
            <div class="row">        
              <app-multi-select-dropdown
                class="col-sm-12"
                [props]="{
                  heading: 'APIs',
                  placeholder: 'Select APIs ',
                  inputPlaceholder:'Enter to search on APIs',
                  required: true,
                  disable: false,
                  info: 'Select one or more APIs that belong to the product. Only APIs that belong to the selected application from CMDB can be added to the product.  ',
                  'errMsg': 'Please select at least one API'
                }"
              (search)="apiSearch($event)"
              [dataSets]="apisDataSet"            
              [control]="getControl('APIs')"
              (keydown.enter)="preventSubmit($event)"
                style="position: relative"
                [checkError]="checkError"
                [defaultDataSet]="defaultApisDataSet"
                [disable]="isDraftProduct"            
              ></app-multi-select-dropdown>
            </div>
            <div class="col-sm buttons-align-end button-wrapper button-alignment">
              <app-buttons
            [props]="(this.basicTabProductForm.valid==true || (isDraftProduct && getControl('Product').valid && getControl('AppNameAndNumber').valid ))?
              {
                icon: '',
                content: 'Next Step',
                classType: 'primary',
                type:'button'
              }
            :{
              icon: '',
              content: 'Next Step',
              href: '#',
              classType: 'disable',
              type:'button'
            }" 
            (click) = "triggerNext((this.basicTabProductForm.valid || (isDraftProduct && getControl('Product').valid && getControl('AppNameAndNumber').valid )),'basic','management')"
            ></app-buttons>
            </div>
           </div>
          <div id="management" class="container tab-pane">
            <p>We are going to create the following Product App Registration.</p>
            <div class="row">
              <app-text-field class="col-sm" [props]="{
                'heading': 'Product App Registration Name',
                'disable': true
              }"                    
              [control]="productAppRegName"
              ></app-text-field>
              </div>
            <p>Please, provide one or more owners.</p>
            <div class="row">
              <app-multi-select-dropdown
              class="col-sm-12"
              [props]="{
                heading: 'Product App Registration Owners',
                placeholder: 'Search in Entra ID ',
                required: true,
                disable: false,
                info: 'Select one or more owners',
                'errMsg': 'Please select at least one App Registration owner'
              }"
             (search)="appRegistrationOwnerSearch($event)"
             [dataSets]="appRegistrationOwnersDataSet"          
              style="position: relative"
              [control]="getControl('AppRegistrationOwners')"
              (keydown.enter)="preventSubmit($event)"
              [checkError]="checkError"
              [isCustomLabelForOptionsRequired] = "isCustomOptionForAppRegOwnersRequired"
              [disable]="isDraftProduct"
            ></app-multi-select-dropdown>
            </div>
            <div class="row">
              <div class="col-sm radio-wrapper">           
                <app-radio-buttons [props]="{
                'heading':'Published',
                'required': 0,
                'id': 'publish',
                 info: 'Should the product be published or not? Only published products are visible for developers to subscribe to.  ',
                'radio':[{checked: false, label: 'Yes'}, {checked: false, label: 'No'}]
              }" [control]="getControl('RadioWrapper.Published')"></app-radio-buttons>
              <app-radio-buttons [props]="{
                'heading':'Require&nbsp;Subscription',
                'required': 0,
                'id': 'subs',
                disable: true,
                'radio':[{checked: false, label: 'Yes'}, {checked: false, label: 'No'}]  
              }" [control]="getControl('RadioWrapper.RequiredSubsc')"></app-radio-buttons>
              <app-radio-buttons [props]="{'heading':'Require&nbsp;Approval',
                'required': 0,
                'id': 'appro',
                info: 'Should new product subscriptions be approved or not? We recommend to enable approval to keep track of who is using the product and APIs.  ',
                'radio':[{checked: false, label: 'Yes'}, {checked: false, label: 'No'}]
              }" [control]="getControl('RadioWrapper.RequiredApproval')"
              (EnableEvent)="enableApproval($event)"></app-radio-buttons>
            </div>
            </div>
            <div class="row" style="margin-top: 2rem;">          
              <app-multi-select-dropdown
              class="col-sm-12"
                [props]="{
                  heading: 'Approvers',
                  placeholder: 'Search in Entra ID ',
                  required: false,
                  disable: false,
                  info: 'Select one or more approvers to review new product subscriptions',
                  'errMsg': 'Please select at least one approver'
                }"
               (search)="userSearch($event)"
               [dataSets]="approversDataSet"          
                style="position: relative"
                [control]="getControl('Approvers')"
                (keydown.enter)="preventSubmit($event)"
                [checkError]="checkError"
                [disable]="disableApprover || isDraftProduct"
                [requiredField]="approverRequired"
                [isCustomLabelForOptionsRequired] = "isCustomOptionForApproverRequired"
              ></app-multi-select-dropdown>
            </div>
            <div class="col-sm buttons-align-end button-wrapper button-alignment">
              <app-buttons
            [props]="
              {
                icon: '',
                content: 'Back',
                classType: 'tertiary',
                type:'button'
              }
            " 
            (click)="triggerBackStep('basic')"
            ></app-buttons>
              <app-buttons
            [props]="(managementTabProductForm.valid && (this.Approvers.valid))?
              {
                icon: '',
                content: 'Next Step',
                classType: 'primary',
                type:'button'
              }
            :{
              icon: '',
              content: 'Next Step',
              href: '#',
              classType: 'disable',
              type:'button'
            }" 
            (click)="triggerNext((this.managementTabProductForm.valid && this.Approvers.valid),'management','review')" 
            ></app-buttons>
            </div>
          </div>
          <div id="review" class="container tab-pane fade">
            <p>You have reached the final step of this product creation wizard. Please, verify the provided information below before submitting your product.</p>
            <div class="row">
              <div class="col-12 col-sm">
                <h3 class="title">Product Name</h3>
                <h3 class="title bold">{{this.Product.value}}
                </h3>
                </div>
                <div class="col-12 col-sm">
                  <h3 class="title">Product Id</h3>
                  <h3 class="title bold">{{this.ProductId.value}}
                  </h3>
                </div>
            </div>
            <div class="row">
              <div class="col-12 col-sm">
              <h3 class="title">Application Name / Number</h3>
              <h3 class="title bold">{{ this.AppNameAndNumber.value.value }} / {{this.AppNameAndNumber.value.id}}
              </h3>
              </div>
              <div class="col-12 col-sm">
                <h3 class="title">Application Owner</h3>
                <h3 class="title bold">{{this.AppOwner.value}}</h3>
              </div>
          </div>
          <div class="row">
            <div class="col-12 col-sm">
                <h3 class="title">Draft Product</h3>
                <h3 class="title bold">{{this.isDraftProduct==true?'Yes':'No'}}</h3>
            </div>
            <div class="col-12 col-sm"  *ngIf="isDraftProduct;">
              <h3 class="title">Published</h3>
              <h3 class="title bold">{{this.published.value}}</h3>
          </div>
        </div>  
        <div class="row">
          <div class="col-12 col-sm-12  description-overflow">
              <h3 class="title">Description</h3>
              <h3 class="title bold">{{this.description.value}}</h3>
          </div>
      </div>   
          <ng-container *ngIf="!isDraftProduct;">
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12">
              <h3 class="title">APIs</h3>
              <app-render-control-values [value]="this.APIs.value" [type]="'tag'" [isRenderLabelOnlyEnabled]="true"></app-render-control-values>
          </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm-12">
            <h3 class="title">Product App Registration Name</h3>
            <h3 class="title bold">{{this.productAppRegName.value}}</h3>
        </div>
    </div>
        <div class="row">
          <div class="col-12 col-sm-12 col-md-12">
              <h3 class="title">Product App Registration Owners</h3>
              <app-render-control-values [value]="this.appRegOwners.value" [type]="'tag'" [isRenderLabelOnlyEnabled]="true"></app-render-control-values>
          </div>
      </div>
      <div class="row">
        <div class="col-12 col-sm">
            <h3 class="title">Published</h3>
            <h3 class="title bold">{{this.published.value}}</h3>
        </div>
        <div class="col-12 col-sm">
            <h3 class="title">Require Subscription</h3>
            <h3 class="title bold">{{this.requiredSubscription.value}}</h3>
        </div>  
        <div class="col-12 col-sm-12 col-md-5">
            <h3 class="title">Require Approval</h3>
            <h3 class="title bold">{{this.requiredApproval.value}}</h3>
        </div>  
    </div>
    <div class="row" *ngIf="this.requiredApproval.value=='Yes'">
      <div class="col-12 col-sm-12 col-md-12">
          <h3 class="title">Approvers</h3>
          <app-render-control-values [value]="this.Approvers.value" [type]="'tag'" [isRenderLabelOnlyEnabled]="true"></app-render-control-values>
      </div>
  </div>
  <p class="note-section-wrapper">
    <strong>Note: </strong>We would like to remind you of the available documentation about "<a href="https://ac-its.atlassian.net/wiki/x/mwDBE" target="_blank" class="link-font-size">How to work with role-based access?</a>"
  </p>
</ng-container>
  <div class="col-sm buttons-align-end button-wrapper button-alignment">
       <div class="feedbackMsg" [class.feedbackActive]="this.feedbackActive">
              <ng-container *ngIf="successFull" >
                <span class="successColor">{{successMsg}}</span>
                <img src="./assets/successIcon.svg"/>
              </ng-container>
              <ng-container *ngIf="failure" >
                <span class="failureColor">{{failureMsg}}</span>
                <img class="error" src="../../../../assets/Health-monitor/Unhealthy.svg"/>
              </ng-container>
              
            </div>
            <ng-container *ngIf="!isDraftProduct; else draftProductSubmitButtons">
            <app-buttons
            [props]="
              {
                icon: '',
                content: 'Back',
                classType: 'tertiary',
                type:'button'
              }
            " 
            (click)="triggerBackStep('management')"
            ></app-buttons>
            <app-buttons
            [props]="{
              icon: '',
              content: 'Submit',
              href: '#',
              classType: 'primary',
              type:'submit'
            }"              
          ></app-buttons>
        </ng-container>
        <ng-template #draftProductSubmitButtons>
          <app-buttons
          [props]="
            {
              icon: '',
              content: 'Back',
              classType: 'tertiary',
              type:'button'
            }
          " 
          (click)="triggerBackStep('basic')"
          ></app-buttons>
          <app-buttons
          [props]="{
            icon: '',
            content: 'Submit',
            href: '#',
            classType: 'primary',
            type:'submit'
          }"              
        ></app-buttons>
        </ng-template>
  </div>
          </div>
        </div>
        </div>
      </div>
      <app-general-loader *ngIf="loaderActive"></app-general-loader>
    </form>
    <div class="info-section container">
      <app-information-section [information]="pageInfo" [detailsInfo]="informationData">
    </app-information-section>
    </div>
  </section>

  <app-alert *ngIf="showSuccess" [success]="true">
    <div class="success_Icon"><img src="./assets/successIcon.svg"/><p>{{successMsg}}</p></div>   
  </app-alert>
  <app-alert *ngIf="showFailure" [failure]="true">
    <div class="unSuccess_Icon"><img src="../../../../assets/Health-monitor/Unhealthy.svg"/><p>{{failureMsg}}</p></div>   
  </app-alert>