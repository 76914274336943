import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-buttons',
  templateUrl: './buttons.component.html',
  styleUrls: ['./buttons.component.scss']
})
export class ButtonsComponent {
  @Input() props: any= {};
  url: boolean = false;
  @Output() btnEmitedClickEvent = new EventEmitter<string>();

  ngOnInit() {
    if(this.props.routePath){
      this.url= true;
    }
    
  }
  
  constructor(private router: Router) {}
  
  navigateToRoute(routePath: string): void {
    console.debug(routePath);
    let state={};
    let queryParams ={}
    if(this.props.state!=undefined){
     state=this.props.state;
    }
    if(this.props.isqueryParamsReq !=undefined){
      if(this.props.isqueryParamsReq){
           queryParams = this.props.queryParams;
           this.router.navigate([routePath],{state:state,queryParams: queryParams});
      }else{
        this.router.navigate([routePath],{state:state});
      }
    }else{
      this.router.navigate([routePath],{state:state});
    }
  }

  emitClickEvent(props:any){
    if (props.isBreadCrumbClickEvent){
      this.btnEmitedClickEvent.emit(props.content);   
    }
  }

}
