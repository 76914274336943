
import { AfterContentInit, Component, ElementRef, HostListener, OnInit, ViewChildren } from '@angular/core';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { StateService } from 'src/app/shared/services/state.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { viewApis} from 'src/app/pages/apis/models/api';
import { ActivatedRoute } from '@angular/router';
import { Router} from'@angular/router';
import { DateFormatPipe } from 'src/app/pipes/date-format-pipe.pipe';
import { User } from 'src/app/pages/products/models/user';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';

@Component({
  selector: 'app-view-apis',
  templateUrl: './view-apis.component.html',
  styleUrls: ['./view-apis.component.scss'],
  providers: [DateFormatPipe]
})
export class ViewApisComponent {
  apimApiId: string ="";
  editAccess:boolean = false;
  buttonProperties:any=[];
  detailsInfo:any[]=[];
  apiOwnerList:string[] = [];
  productList:any=[];
  pageInfo:string=" An API enables a standardized way of data exchange between applications. An API specification is a clear contract defining how to interact with an application's data. An API belongs to one or more Products. Once an API becomes part of a Product, an application can subscribe to that Product to get access to that API.\n\nYou are viewing an API that has been created in Azure API Management."
  
  constructor(private hipManagementService: Hip2ManagementService,private route: ActivatedRoute, private router: Router, private dateFormatPipe: DateFormatPipe, private errorHandleService: ErrorHandleService){
    let passedData:any={}; // Type is any as it will map input data with different structure from many pages i.e ds table , View Product 
    passedData=this.router.getCurrentNavigation()?.extras.state??{productName:""};
    if(passedData.parentData != undefined){
      this.breadCrumbApiName=passedData.parentData.apiName;
      sessionStorage.setItem(this.route.snapshot.paramMap.get('apiId') ?? "",this.breadCrumbApiName);
    }else{
      this.breadCrumbApiName=sessionStorage.getItem(this.route.snapshot.paramMap.get('apiId') ?? "")??"";
    }
  }
  
  loaderActive: boolean = false;
  tags:string[] = [];
  dataset: any = {};
  ViewApiForm : FormGroup = new FormGroup({});
  DisplayName: FormControl = new FormControl('', [Validators.required]);
  @ViewChildren('contentSection') contentSections!: ElementRef[];
  @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];
  sectionHeight = 0;

  breadCrumbApiId:string='';
  breadCrumbApiName:string='';
  enableColonDateFormat:boolean=true;
 
@HostListener('window:resize')
onResize() {
  this.setContentSectionHeight();
}

setContentSectionHeight() {
  const windowHeight = window.innerHeight;
  let bodyWrapperHeight: any;
  this.bodyWrapper?.forEach(section =>{
    bodyWrapperHeight = windowHeight - section.nativeElement.offsetTop;
  });
    this.contentSections?.forEach(section => {
      const offsetTop = section.nativeElement.offsetTop;
      console.log('window-height', bodyWrapperHeight, 'offset-Top', offsetTop);
      
      const sectionHeight = bodyWrapperHeight - offsetTop - 42;
      section.nativeElement.style.minHeight = `${sectionHeight}px`;
    });
}

  ngOnInit(): void {
     this.breadCrumbApiId = this.route.snapshot.paramMap.get('apiId')?? "";
     this.ViewApiForm =new FormGroup({
      DisplayName : this.DisplayName,
      
    });
    this.buttonProperties=[ {classType: 'tertiary', content: 'Open API Definition', icon: '', routePath: 'publisher/api-definition/'+this.breadCrumbApiId }];
    this.getApiDetailsById(this.breadCrumbApiId);    
    this.getProductDetailsByApiId(this.breadCrumbApiId);        
  }

  ngAfterViewInit(): void {
    this.setContentSectionHeight();
  }
  ngAfterContentInit(): void {
    this.setContentSectionHeight();
  }
  getApiDetailsById(apimApiId: string) {
    this.loaderActive = true;
    this.hipManagementService.getApimApiPublisherId(apimApiId).subscribe({
      next: (apiResponse:any) => {
        if(apiResponse) {
          if(!(apiResponse.hasAccess)){
            this.router.navigate(['/unauthorized']);
          }else{
          this.editAccess = !(apiResponse.hasReadOnlyAccess);
          if(this.editAccess){            
            this.buttonProperties = this.buttonProperties.concat([{content:'Edit', classType:'primary', icon:'./assets/buttons/editIcon.svg', routePath: 'publisher/manage-apis/edit-api/'+this.breadCrumbApiId }]);            
          }
          let apiData = apiResponse.apimApiDetail;
          this.breadCrumbApiName=apiData.displayName;
          localStorage.setItem('apiDisplayName', apiData.displayName.toString());
          if(apiData.apiOwner.length>0){
            this.hipManagementService.getListOfADUsersOwners(apiData.apiOwner).subscribe({
              next:(response)=>{
               response.forEach((userData: User) => {
               this.apiOwnerList.push(userData.name);
               });
              }})
            }
          this.dataset = {
            displayName:apiData.displayName,
            appName: apiData.applicationName,
            appNumber: apiData.apiName,
            apiOwner: apiData.apiOwner.length==0?["none"]:this.apiOwnerList,
            applicationOwner: apiData.applicationOwner,
            product: apiData.product,
            apiVersion: apiData.version,
            apiDescription: apiData.apiDescription ,
            tags: apiData.tags,
            unitCode: apiData.unitCode,
            costCenter:apiData.costCenter,
            dateRegistered: apiData.dateRegistered,
            urlSuffix: apiData.urlSuffix,
            businessArea:apiData.businessArea    
          }
          this.detailsInfo = [
            {'title':'Application Name', 'value':apiData.applicationName},
            {'title':'Application Number', 'value':apiData.cmdbId},
            {'title':'Application Owner', 'value':apiData.applicationOwner},
            {'title':'Business Area', 'value': apiData.businessArea },
            {'title':'Unit Code', 'value':apiData.unitCode },
            {'title':'Cost Center', 'value': apiData.costCenter },
            {'title':'API Creator', 'value': apiData.createdBy },
            {'title':'Date Registered', 'value': this.dateFormatPipe.transform(apiData.dateRegistered,this.enableColonDateFormat)}
            
          ];  
          this.dataset.tags.forEach((element: { displayName: string; }) => {
            this.tags.push(element.displayName)
          });
          this.loaderActive = false; 
        }}
        else {
          this.loaderActive = false;
        } 
      },
      error: (error) => {
        this.loaderActive = false;
        this.errorHandleService.handleError(error);            
      }   
    });
  }

  getProductDetailsByApiId(apimApiId: string) {
    this.loaderActive = true;    
    this.hipManagementService.getApimProductsAndOwnersByApiId(apimApiId).subscribe({
      next: (productResponse:any) => {
        this.loaderActive = false;
        if(productResponse.length > 0){
          productResponse.forEach((element: { productName: string; apimProductId: string; applicationOwner: string; }) => {
            this.productList.push({
              productName:element.productName.trim(),
              productId:element.apimProductId.trim(),              
              applicationOwner:element.applicationOwner.trim()
            })
          });
        }

        
      },
      error: (err: string) => {
        this.loaderActive = false;            
      }   
    });
  }

  }
