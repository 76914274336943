<div [class.prod_card-wrapper]="isCardList">
    <ng-container *ngFor="let i of [0,1,2,3,4,5,6,7,8]">
        <div class="prod_card shimmer">
            <div class="flexContainer">
                <div class="businessTag"></div>
                <div class="cardDetail">
                    <h3></h3>
                    <p></p>
                </div>
            </div>
            <div class="apiWrappers" >
                <div class="shimmer-icon"></div>
                <div class="shimmer-button"></div>
            </div>
        </div>

    </ng-container>
    
