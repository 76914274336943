import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CustomCapitalizePipe } from 'src/app/pipes/capitalize-pipe/custom-capitalize-pipe.pipe';
import { DateFormatPipe } from 'src/app/pipes/date-format-pipe.pipe';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { User } from '../../products/models/user';
import { ViewMySubcriptionModel } from '../models/viewSubscription-model';
  
@Component({
  selector: 'app-view-developer-manage-subscription',
  templateUrl: './view-developer-manage-subscription.component.html',
  styleUrls: ['./view-developer-manage-subscription.component.scss'],
  providers:[DateFormatPipe,CustomCapitalizePipe]
})
export class ViewDeveloperManageSubscriptionComponent implements OnInit {
 
  productInfo:{parentData:{productName:string}}={parentData:{productName:""}}
  loaderActive: boolean = false;
  breadCrumbSubscriptionName:string = "";
  breadCrumbSubscriptionDisplayName:string = "";
  breadCrumbProductId:string="";
  breadCrumbProductName:string="";
  breadCrumbAppName:string ="";
  breadcrumbAppId:string ="";
  subscriberFullName:string="";
  keyValue:string="";
  showSubscriptionAttribute:boolean=false;
  pageInfo:string = "A subscription is created against a Product, containing one or more APIs. Once subscribed, an application gets access to all APIs inside a Product in a secure way.\n\n You are viewing a Product subscription that has been created in Azure API Management."
  isStateActive:boolean = true;
  breadCrumbsubscriptionId:string='';
  approverList: string[] = [];
  enableColonDateFormat:boolean = true;
  dataset: ViewMySubcriptionModel={
    subscriptionName: '',
    subscriptionDisplayName:'',
    productName: '',
    subscriberName: '',
    proxyClientID:'',
    subscriptionKey:'',
    state:'',
    apiData:[],
    userNotes:'',
    remarks:'',
    productAppregistrationID:'',
    allowTracing: false,
    subscriptionAttributeList:[]
    };
    detailsInfo:{"title":string,"value":string}[]=[];
    constructor(private route:ActivatedRoute,private hipManagementService: Hip2ManagementService, private dataPipe: DateFormatPipe,private capitalizePipe :CustomCapitalizePipe, private errorHandleService:ErrorHandleService, private router:Router){

    }
    ngOnInit() {
      let subscriptionName = this.route.snapshot.paramMap.get('subscriptionName')?? "";
      let apimProductId = this.route.snapshot.paramMap.get('apimProductId')?? "";
      this.breadCrumbProductId=apimProductId;
      let queryParams = this.route.snapshot.queryParams;
      this.breadCrumbAppName=queryParams['appName']??"";
      this.breadcrumbAppId=queryParams['appId']??"";
      this.breadCrumbProductName=queryParams['productName']??"";
      this.breadCrumbSubscriptionDisplayName=queryParams['subDisplayName']??"",
      this.breadCrumbSubscriptionName=subscriptionName;
      this.getSubscriptionBySubscriptionName(subscriptionName,apimProductId);
    }
    getSubscriptionBySubscriptionName(subscriptionName: string,apimProductId: string) {
      this.loaderActive=true;
      this.hipManagementService.getDeveloperSubscriptionBySubscriptionName(subscriptionName,apimProductId).subscribe(response => {
        let subscription = response;     
        if(subscription.hasAccess){     
        this.breadCrumbProductName=subscription.productName;   
        this.breadCrumbSubscriptionName=subscription.subscriptionName;
        this.breadCrumbSubscriptionDisplayName=subscription.subscriptionDisplayName;
        this.productInfo={parentData:{productName:this.breadCrumbProductId}}
        this.subscriberFullName=subscription.subscriberName;
        let subscriberName=(this.subscriberFullName).split(" ");
        let formattedSubscriberName = "";
        subscriberName.forEach(name => {
          let nameValue=this.capitalizePipe.transform(name);
          formattedSubscriberName=formattedSubscriberName.length==0?nameValue:(formattedSubscriberName+" "+nameValue);
        });
        console.log("product owner",subscription.productApprovers);
        subscription.productApprovers.forEach((approverEmail:string) => {
          this.hipManagementService.getListOfADUsers_Approvers(approverEmail).subscribe({
            next:(response)=>{
             response.forEach((userData: User) => {
             this.approverList.push(userData.name);
             });
            }})
        });
        this.dataset = {
          subscriptionName: subscription.subscriptionName,
          subscriptionDisplayName:subscription.subscriptionDisplayName,
          productName: subscription.productName,
          subscriberName: formattedSubscriberName,
          proxyClientID: subscription.proxyClientID,
          subscriptionKey:subscription.subscriptionKey,
          state: subscription.state=="submitted"?"Pending Approval":this.capitalizePipe.transform(subscription.state),
          apiData:[],
          userNotes:subscription.usageNotes,
          productAppregistrationID:subscription.productAppregistrationID,
          allowTracing:subscription.allowTracing,
          remarks: subscription.state=="rejected"?(subscription.statusChangedReason.length>0?subscription.statusChangedReason:"-"): 
          (subscription.remarks.length>0?subscription.remarks:"-"),
          subscriptionAttributeList: subscription.subscriptionAttributeList
        }
        this.isStateActive = (subscription.state =='active')?true:false;
        let formattedActivatedDate= subscription.activatedDate!=undefined?this.dataPipe.transform(subscription.activatedDate,this.enableColonDateFormat):"  - | -"
        this.detailsInfo = [
          {'title':'Application Name', 'value':subscription.applicationName},
          {'title':'Application Number', 'value':subscription.cmdbId},
          {'title':'Application Owner', 'value':subscription.appOwner},
          {'title':'Business Area', 'value':subscription.businessAreaCode},
          {'title':'Unit Code', 'value':subscription.unitCode},
          {'title':'Cost Center', 'value':subscription.costCenter},
          {'title':'Date Requested', 'value': this.dataPipe.transform(subscription.dateRequested, this.enableColonDateFormat)},
          {'title':'Date Activated', 'value': formattedActivatedDate}
        ];      
        this.hipManagementService.getDeveloperSubscriptionApisByApimProductId(apimProductId).subscribe(a => {
          let productAPIs=[];
          for (let index = 0; index < a.length; index++) {
            let element = a[index];
            let api={
              apimApiName: element.apimApiName,
              name:element.name,
              apiOwner:element.apiOwner,
              apiLink:'/publisher/view-api/'+element.apimApiName
            }
            productAPIs.push(api);
          }
          this.dataset.apiData = productAPIs;
          this.loaderActive = false;  
          sessionStorage.setItem('productNameValue', this.breadCrumbProductId);
          sessionStorage.setItem('productDisplayNameValue', subscription.productName);
        },error=>{
          console.log("error",error);
          this.loaderActive = false;  
        });
      }else{
        this.router.navigate(['/unauthorized']);
    }},
    error=>{
   this.errorHandleService.handleError(error);
    });
    }
}
