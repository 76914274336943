import { Component,EventEmitter,ElementRef, HostListener, Input, OnInit, Output, ViewChildren, OnChanges, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';
import { ProductApis } from 'src/app/pages/products/models/product-apis';

@Component({
  selector: 'app-ds-table',
  templateUrl: './ds-table.component.html',
  styleUrls: ['./ds-table.component.scss']
})
export class DsTableComponent implements OnInit, OnChanges{
  @Input() HeadArray : string[] =[];
  @Input() GridArray : string[] =[];
  @Input() itemsPerPage : any;
  @Input() pageNo : any;
  @Input() DataKeys : any[] =[];
  @Input() sorting : any[] =[];
  
  @Input() totalRecords: number = 0;
  
  @Input() childDataInfo:string= 'data'
  @Input() tableInfo:string= 'data'
  @Input() isChildTableRequired:boolean = true;
  @Output() tableExpand = new EventEmitter<any>();
  @Output() download = new EventEmitter<string>();
  @Output() downloadClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() cancelClicked: EventEmitter<string> = new EventEmitter<string>();
  @Output() reactivateClicked: EventEmitter<string>=new EventEmitter<string>();
  @Output() deleteClicked: EventEmitter<string>=new EventEmitter<string>();

  productName:string='';
  isLoadingChildData:boolean = true;
 

  // updated on 7th April
  insideTableKey : any[] = [];
  @Input() tableDetailLink: boolean = false;
  key:string = ''
  sortHeaderList : string[]=[];
  sortFieldValues:any[] =[];
constructor(private router: Router){

}

  ngOnInit(): void {
    // Updated on 7th April 2023 added function getInsideTableKeys()
    this.tableDetailLink;
    if(this.totalRecords>0){
    for (let index = 0; index < this.GridArray.length; index++) {
      let rowData:any= this.GridArray[index];//type is any as its generic
      if(rowData.detail != undefined){
        if((rowData.detail.tableData).length>0){
          this.getInsideTableKeys(rowData);
          break;
        }
      }

    }
    } 
    this.key=this.sorting[0]['key']  
    this.sorting.forEach(field => {
      this.sortHeaderList.push(field.head);
      let sortfieldObject={
        header: field.head,
        isReverse:false,
        key: field.key,
        isVisible: this.key==field.key?true:false
      } 
      this.sortFieldValues.push(sortfieldObject);
    });

  }

  ngOnChanges(){
    for (let index = 0; index < this.GridArray.length; index++) {
      let rowData:any= this.GridArray[index];//type is any as its generic
      if(rowData.Action.apimProductName==this.productName)
      {
        if(rowData.detail != undefined){
        if((rowData.detail.tableData).length>0){
          this.getInsideTableKeys(rowData);
          break;
        }
      }
      }

    }
    this.isLoadingChildData=false;
  }

  getInsideTableKeys(arr: any){
if(this.totalRecords>0){
  this.insideTableKey = Object.keys(arr.detail.tableData[0]);
  if(this.insideTableKey.includes("apimApiName")){
    this.insideTableKey.splice(this.insideTableKey.indexOf("apimApiName"),1);
  }
  if(this.insideTableKey.includes("productId")){
    this.insideTableKey.splice(this.insideTableKey.indexOf("productId"),1);
  }
}
    console.debug('this is insideTableKey', this.insideTableKey);
  }
  renderApiDetails(product: any){
    this.isLoadingChildData=true;
    if(product.Action.apimProductName!=null){
    this.productName=product.Action.apimProductName;
    }
    this.tableExpand.emit(product);
  }

  reverse:boolean = false;
  sort(keyValue:string, reverseValue:boolean){
    this.key = keyValue;
    this.reverse = !reverseValue
    this.sortFieldValues.forEach(field => {
      if(field.key == keyValue){
        field.isReverse=this.reverse
        field.isVisible=true;
      }else{
        field.isVisible=false;
      }
    });
  }


  //params type is any as it may have any structure depends upon parent component
  onChildLinkClick(data:any){
  if(data.apimApiName != undefined){
    let apiurl= "publisher/view-api/"+data.apimApiName;
    this.router.navigateByUrl(apiurl);
    sessionStorage.setItem(data.apimApiName,data.name)
  }
  }
  downloadSubscription(event: Event,product:any) {
    event.preventDefault(); // Prevent default anchor behavior (navigation)
    this.downloadClicked.emit(product); // Emit event for download action
  }
  OnCancelClick(event:Event, data:any){
    event.preventDefault();
    this.cancelClicked.emit(data);
  }
  OnReactivateClick(event:Event, data:any){
    event.preventDefault();
    this.reactivateClicked.emit(data);
  }
  OnDeleteClick(event:Event, data:any){
    event.preventDefault();
    this.deleteClicked.emit(data);
  }
}
