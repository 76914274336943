import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-shimmer-card',
  templateUrl: './shimmer-card.component.html',
  styleUrls: ['./shimmer-card.component.scss']
})
export class ShimmerCardComponent {
  @Input() isCardList: boolean = false;
}
