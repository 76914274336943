import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-select-drpdwn',
  templateUrl: './select-drpdwn.component.html',
  styleUrls: ['./select-drpdwn.component.scss']
})
export class SelectDrpdwnComponent implements OnInit{
  
  isActive: boolean = false;
  inputField: number =  0;
  @Input() Options: any =[];
  @Input() perPageRender: number = 0;
  @Output() perPageValue = new EventEmitter <number>();

  constructor(private elementRef: ElementRef) {}

  ngOnInit() {
    this.Options;
    this.perPageRender
    console.log('perpage render',this.perPageRender);
    
    this.inputField = this.perPageRender;
  }
  toggleDrp(){
      this.isActive = !this.isActive;
  }
  getData(event: any){
    this.inputField = event.target.innerHTML;
    this.perPageRender = event.target.innerHTML;
    this.perPageValue.emit(this.perPageRender);
   this.isActive = false;
   }

   @HostListener('document:click', ['$event'])
   handleClickOutside(event: any) {
    
     
     if (!this.elementRef.nativeElement.contains(event.target)) {
       this.isActive = false;
     }
   }
   
}
