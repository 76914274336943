import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTableComponent implements OnInit {

  @Input() HeadArray: string[] = [];
  @Input() GridArray: string[] = [];
  @Input() itemsPerPage: any;
  @Input() p: any;
  @Input() DataKeys: any[] = [];

  key: string = 'Product_name';
  reverse: boolean = false;
  sort(key: string) {
    this.key = key;
    this.reverse = !this.reverse
  }

  ngOnInit(): void {    
    console.log("dynamic");    
  }

}
