import {  NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { RouterModule, Routes } from '@angular/router';
import { MyApprovalsComponent } from './my-approvals/my-approvals.component';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/shared.module';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { ViewApprovalPendingSubscriptionComponent } from './view-approval-pending-subscription/view-approval-pending-subscription.component';
import { CustomPipeModule } from 'src/app/pipes/pipe.module';
import { ApiCatalogRoleEnum } from 'src/app/core/enum/UserRoleEnum';
import { AuthGuard } from 'src/app/shared/guard/auth.guard';



const routes:Routes = [
    {
        path: 'publisher',
        children:[
            {
                path:'my-approvals',
                component: MyApprovalsComponent,
                data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/my-approvals","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN,ApiCatalogRoleEnum.APPROVER]},
                canActivate: [MsalGuard,AuthGuard]
            },
            {
                path:'my-approvals/view-pending-subscription-approval/:subscriptionName/:apimProductId',
                component:ViewApprovalPendingSubscriptionComponent,
                data: {"menu":"Publisher","defaultModuleRoute": "/publisher/my-approvals","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN,ApiCatalogRoleEnum.APPROVER]},
                canActivate: [MsalGuard,AuthGuard]
            }
        ]
    }
]

@NgModule({
    declarations:[MyApprovalsComponent, ViewApprovalPendingSubscriptionComponent],
    imports: [RouterModule.forRoot(routes),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,SharedModule,
        NgxPaginationModule, //npm install ngx-pagination --save
        Ng2SearchPipeModule, // npm i ng2-search-filter --save
        Ng2OrderModule, //npm install  ng2-order-pipe --save
        CustomPipeModule
    ],
      exports: [RouterModule]  
})
export class ApprovalModule{

}