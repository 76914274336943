<div #bodyWrapper class="section">
    <app-bread-crumb [links]="[
    {
      'href': '/publisher/health-monitor',
      'title': 'Health Monitor',
      'description': ' An overview of your Products and APIs health as a publisher.  '
    }
    ]"></app-bread-crumb>
  
    <!-- <div class="healthcardWrapper">
        <ng-container *ngFor="let card of HealthCards" >
          <app-health-card [cardData]=card></app-health-card>
        </ng-container>
    </div> -->
     <!-- <app-health-timeline [history]="history"></app-health-timeline> -->
     <p class="paragraph">Page is currently under development</p>
    </div>