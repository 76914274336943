import { Component } from '@angular/core';

@Component({
  selector: 'app-shimmer-table',
  templateUrl: './shimmer-table.component.html',
  styleUrls: ['./shimmer-table.component.scss']
})
export class ShimmerTableComponent {

}
