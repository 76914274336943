import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-health-card',
  templateUrl: './health-card.component.html',
  styleUrls: ['./health-card.component.scss']
})
export class HealthCardComponent {
  @Input() cardData: any = {}
}
