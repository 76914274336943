
<table cellspacing="0" class="table_64">
  <thead>
    <tr class="header-col">
      <th *ngFor="let heading of HeadArray; let i = index">
        {{
 heading
        }}<span *ngIf="i === 0" (click)="sort('Product_name')">&nbsp;&nbsp;<span *ngIf="!reverse"><i class="fa-solid fa-arrow-down-long"></i></span><span *ngIf="reverse"><i class="fa-solid fa-arrow-up-long"></i></span></span>
      </th>
    </tr>
  </thead>
  <tbody class="accordion accordion-flush bodytable" id="accordionFlushExample">
    <ng-container *ngFor="
            let data of GridArray
              | orderBy : key : reverse
              | paginate : { itemsPerPage: itemsPerPage, currentPage: p };
            let i = index
          ">
      <tr>
        <ng-container *ngFor="let key of DataKeys; let j = index">
          <!-- {{key}}{{data|json}} -->
          <td [class.blue-content]="j === 0">
            <span *ngIf="j=== 0" class="caret" (click)="data.isExpanded = !data.isExpanded"><i [class.fa-rotate-90]="data.isExpanded" class="fa-solid fa-caret-right"></i></span>
            {{data[key]}}
          </td>
        </ng-container>
      </tr>
      <tr id="flush-collapseOne"
          class="toggle-row"
          [class.active]="data.isExpanded">
        <td colspan="10">
          <div>
            <table>
              <thead>
                <tr>
                  <th *ngFor="let heading of data?.Detail?.headings">
                    {{ heading }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let insideData of data?.apis">
                  <td><button class="btn ds_c-button--secondary">{{ insideData.name }}</button></td>
                  <!-- <td>{{ insideData.Version }}</td> -->
                  <td>{{ insideData.description }}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </td>
      </tr>      
    </ng-container>
  </tbody>
</table>
