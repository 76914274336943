import { ModalService } from './../../../shared/services/modal.service';
import { FormGroup, FormControl,  } from '@angular/forms';
import { Component, OnInit, ViewChildren, ElementRef, HostListener } from '@angular/core';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { ManageProductList } from '../models/manage-product-list';
import {ProductApiTableDisplayStructure,ProductTableDisplayStructure} from '../models/product-table'
import { Subject, Subscription, debounceTime } from 'rxjs';
import { DateFormatPipe } from 'src/app/pipes/date-format-pipe.pipe';
import { ApisList } from '../models/apis-list';
import { ProductApis } from '../models/product-apis';
import { Router } from '@angular/router';
@Component({
  selector: 'app-manage-product',
  templateUrl: './manage-product.component.html',
  styleUrls: ['./manage-product.component.scss'],
  providers: [DateFormatPipe]
})
export class ManageProductComponent implements OnInit {
  
  loaderActive: boolean = false;
  loaderEnabled: boolean = true;
  searchProductValue: string ='';
  apiList: ProductApis[]=[];
  productList:any=null;
  gridArray: any[]=[];
  selectedBusinessValue: string[] = []; // for maintaining state of business Area filters by labels
  dataKeys : string[]=[];
  pageNo: number = 1;
  itemsPerPage : number = 10;
  filterForm: FormGroup = new FormGroup({});
  businessFilter: FormControl = new FormControl('');
  tableDetailLink: boolean = false; // added on 7th April
  @ViewChildren('contentSection') contentSections!: ElementRef[];
  @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];
sectionHeight = 0;
//To store the total no of records in grid 
totalRecordCount:number =0;
searchProductSubscription!: Subscription;
searchProductValueChanged:Subject<string> = new Subject<string>();
//header list for the manage product grid
productTableHeaderList: string [] =[
"Product Name",
"Application Number",
"Business Area",
"Unit Code",
"Application Owner",
"Cost Center",
"State",
"Date Published",
"Actions"];

//header list for api sub table inside product grid
apiTableHeaderList: string [] =[
  "API",
  "Version",
  "Owner",
  "Description"
]


//Holds the business area search box list data.
businessAreaList: any[] = [  {
  'id': '1',
  'label': 'CT'
},
{
  'id': '2',
  'label': 'CO'
},
{
  'id': '3',
  'label': 'IT'
},
{
  'id': '4',
  'label': 'PT'
},
{
  'id': '5',
  'label': 'VT'
}]

sortingHeaderList=[{head: 'Product Name', key: 'productName'}
,{head: 'Application Number' , key: 'applicationNumber'},{head:'Business Area', key: 'businessArea'},{head:'Unit Code', key: 'unitCode'}
,{head: 'Application Owner', key: 'applicationOwner'}, {head: 'Cost Center', key: 'costCenter'}, {head: 'State', key: 'state'},{head:'Date Published' ,key: 'datePublished'}
];
isSearchBusinessFilterEnabled:boolean = false;
childTableInfo:string='APIs'
tableInfo:string='products'
enableColonDateFormat:boolean=false;

 
  

// listen for window resize events
@HostListener('window:resize')
onResize() {
  this.setContentSectionHeight();
}
// set the height of all elements with the content-section class

setContentSectionHeight() {
  const windowHeight = window.innerHeight;
  let bodyWrapperHeight: any;
  if(this.bodyWrapper!=undefined){
    this.bodyWrapper.forEach(section =>{
      bodyWrapperHeight = windowHeight - section.nativeElement.getBoundingClientRect().top;
      // console.log('bodyWrapperHeight is', bodyWrapperHeight, windowHeight);
    });
    
  }
 if(this.contentSections!=undefined){
  this.contentSections.forEach(section => {
    const offsetTop = section.nativeElement.getBoundingClientRect().top;      
    const sectionHeight = windowHeight - Math.floor(offsetTop) - (window.innerWidth * 0.0052 * 4.8); //(2.5 * 48);
    // console.log('bodyWrapperHeight is', bodyWrapperHeight," section offset is", offsetTop," and section Height is ", sectionHeight," footer height ", window.innerWidth * 0.0052 * 4.8);
    
    section.nativeElement.style.minHeight = `${Math.floor(sectionHeight)}px`;
  });
 }
}

  constructor(private hipManagementService: Hip2ManagementService, private dateFormatPipe: DateFormatPipe, public modal: ModalService, private router: Router) {
    let searchValue = this.router.getCurrentNavigation()?.extras?.state?.['searchValue']??"";
    this.searchProductValue=searchValue;
   }
 
   //below function will render items per page - emitting from app-select-drpdwn dropdown component
  public renderPageValue(value: number) {
    this.itemsPerPage = value;
    this.pageNo = 1;
    this.loadProductAndAPIs()  
   }

   ngOnInit(): void {
    this.filterForm = new FormGroup({
      filter: this.businessFilter
    });
    this.loadProductAndAPIs();
    // using below code you can check if there is any change in data from control value
    this.controlDataChange()
    this.setContentSectionHeight();
  }
  
  

  ngAfterContentInit(): void {
    //Called after ngOnInit when the component's or directive's content has been initialized.
    //Add 'implements AfterContentInit' to the class.
    this.searchProductSubscription = this.searchProductValueChanged.pipe(debounceTime(1500)).subscribe(value=>{
      this.searchProductValue=value;
      if(this.searchProductValue.length>2 || this.searchProductValue.length==0){
        this.pageNo=1;
        this.loadProductAndAPIs();    
        this.setContentSectionHeight();

      }     
    })
    this.setContentSectionHeight();
  }
  controlDataChange(){
    //fetching the selected business value and calling the API to get the data based on selected business value
    this.businessFilter.valueChanges.subscribe((x) => {
      let businessValue = x.map((obj:any)=>{return obj.label});
     this.selectedBusinessValue = businessValue;
     this.pageNo=1;
     this.loadProductAndAPIs();
    });    

  }

  //render pagination value for table
  onTablePageChange(event: any){
    this.pageNo = event;
    this.loadProductAndAPIs();
  }
  loadProductAndAPIs(){
    let businessAreaCode = '';
    //get the selected business code
    this.selectedBusinessValue.forEach(element => {
      businessAreaCode=businessAreaCode.length==0?element:(businessAreaCode+','+element)
    });
    let filter = (this.searchProductValue.trimStart()).trimEnd();
    // we are currently doing client side table sorting
    let sortDirection = 'asc';
    let pageIndex =  this.pageNo;
    let pageSize = this.itemsPerPage;
    let apiVersion = '1.0';
    //temporary using shimmer. will be replacing with loader after loader implemention
    this.loaderEnabled = true;
    this.hipManagementService.getProductsAndApis(businessAreaCode, filter, sortDirection,
      pageIndex, pageSize, apiVersion).subscribe
      ({
          next: (productsAndApi: ManageProductList) => {
            //resetting the grid array in order to get latest data
            this.gridArray=[]
            //console.log ("products loop", productsAndApi.productData ); 
            productsAndApi.productData.forEach(productDetail => {
              let isDraftProduct:boolean=productDetail.isDraftProduct;
                this.productList = {
                  "uniqueId":productDetail.id,
                  "productName":productDetail.displayName,
                  "applicationNumber":productDetail.cmdbId,
                  "businessArea": productDetail.businessAreaCode,
                  "unitCode": productDetail.unitCode,
                  "applicationOwner": productDetail.appOwner,
                  "costCenter": productDetail.costCenter,
                  "state": productDetail.state=='notPublished'?"Unpublished":((productDetail.state).charAt(0).toUpperCase())+(productDetail.state).slice(1),
                  "datePublished": productDetail.datePublished!=undefined?this.dateFormatPipe.transform(productDetail.datePublished,this.enableColonDateFormat):"  - | -",
                  "Action": 
                  {  
                    "apimProductName":productDetail.apimProductName,
                    "publish": "publish",
                    "unpublish": "",
                    "edit": isDraftProduct?"/publisher/manage-products/edit-draft-product/"+productDetail.apimProductName:"/publisher/manage-products/edit-product/"+productDetail.apimProductName,
                    "info": "/publisher/manage-products/view-product/"+productDetail.apimProductName
                }
                  ,
                  "detail":{
                    "headings":this.apiTableHeaderList,
                    "tableData":[]
                 }
                }
                this.gridArray.push(this.productList);
            }); 
           
            if(this.gridArray.length>0){
              this.dataKeys = Object.keys(this.gridArray[0]);
              this.dataKeys.splice(this.dataKeys.indexOf("detail"), 1);
              
            }
            this.totalRecordCount = productsAndApi.totalCount;
            this.loaderEnabled = false;
            this.resetScroll();
            this.setContentSectionHeight();
          },
          error: (errorDetail: string) => {
      this.loaderEnabled = false;
      console.debug('Could not make connection because of error ', errorDetail);
      this.setContentSectionHeight();
          }
          
        });
       
  }
  SearchProduct(productValue: string){
    this.searchProductValueChanged.next(productValue);
  }
  ngOnDestroy(){
    this.searchProductSubscription.unsubscribe();
  }
  apiListData(product: any)
  {  
    this.apiList=[];
    this.hipManagementService.getApisByApimProductId(product.Action.apimProductName).subscribe(
      {      
        next: (api:ProductApis[]) => {                   
          if(api.length > 0) {   
           // this.childTableInfo='';  
           let updatedApiList:any = [];
           api.forEach(element => {
            let apidetail ={
              apimApiName: element.apimApiName,
              name: element.name,
              version: element.version,
              apiOwner: element.apiOwner,
              description: element.description,
            }
            updatedApiList.push(apidetail);
           });  
            this.apiList=updatedApiList;
          }
          else{
           //this.childTableInfo="";
           console.log('childTableInfo',this.childTableInfo)
         }
         let gridArrayWithAPI = [...this.gridArray]
         gridArrayWithAPI.find(i => i.uniqueId ===product.uniqueId).detail.tableData=this.apiList;
         this.gridArray= [...gridArrayWithAPI]
        },
        error: (err: string) => {            
          this.apiList = [];
          let gridArrayWithAPI = [...this.gridArray]
          gridArrayWithAPI.find(i => i.uniqueId ===product.uniqueId).detail.tableData=this.apiList;
          this.gridArray= [...gridArrayWithAPI]
        } 
      });   
      this.loaderActive= false;
  }

  openModal(){ 
    this.modal.toggleModal('delete');
  }
  resetScroll(){    
    const BodyElement: HTMLElement | null = document.getElementById('bodyContent');
    if(BodyElement != null ){
      BodyElement.scrollTop = 0;
    }
  }
}
