
<ng-container *ngIf="dataset.length > 0; else noData">
    <div class="prod_card-wrapper">
        <ng-container *ngFor="let dataItem of dataset | 
                            paginate: {itemsPerPage : itemsPerPage, currentPage: pageNo, totalItems: totalRecords}">
           <div class="prod_card">
                <div class="flexContainer">
                    <div class="businessTag" [ngClass]="{'navy': dataItem.businessArea === 'CT', 'atlasBlue': dataItem.businessArea === 'CO', 'altasTan': dataItem.businessArea === 'IT', 'atlasGreen': dataItem.businessArea === 'PT', 'atlasRed': dataItem.businessArea === 'VT'}"><span class="middle">{{dataItem.businessArea}}</span></div>
                    <div class="cardDetail">
                        <h3>{{dataItem.name}}</h3>
                        <p class="paragraph" [class.showMore]="dataItem.showMore" id="description{{dataItem.apimProductName}}">{{dataItem.description}}</p>
                        <div class="tag" [class.showMore]="dataItem.showMore" id="tag{{dataItem.apimProductName}}">
                        <app-render-control-values [value]="dataItem.tags" [type]="'tag'" [isClickableLabel]="isClickableLabelReq" (tagClickEvent)="onTagClick($event)"></app-render-control-values>
                        </div>
                    </div>
                </div>
                <div class="apiWrappers"  [class.showfull]="dataItem.showMore" id="api{{dataItem.apimProductName}}">
                  <ng-container *ngFor="let api of dataItem.apiTagList">
                      <a [routerLink]="'/developer/api-definition/'+ api.apimApiName" class="link_secondary" [queryParams]="{'apiName': api.name, 'productId':dataItem.apimProductName, 'productName':dataItem.name}">{{api.name}}</a>
                    </ng-container>
                  </div>
              <div class="actionWrapper">
                <div class="writeActions">
                    <ng-container *ngIf="collapsible">
                        <span class="more" *ngIf="dataItem.EnableMoreOption" (click)="dataItem.showMore = !dataItem.showMore" [class.rotate]="dataItem.showMore">More</span>
                    </ng-container>
                </div>
                <div class="readActions">
                    <a   [routerLink]="dataItem.info" (click)="onViewClick(dataItem)" [state]="{parentData: dataItem}"><img src="./assets/table/info.svg"></a>
                    <app-buttons  [props]="{
                        icon: '',
                        content: 'Subscribe',
                        routePath: '/developer/product-subscription/'+dataItem.apimProductName,
                        classType: 'primary',
                        type:'submit',
                        state: dataItem,
                        isqueryParamsReq: true,
                        queryParams:{productName: dataItem.name}
                      }"
                      ></app-buttons>
                </div>
              </div>                
            </div>
        
        </ng-container>    
      </div>                               
</ng-container>

    <ng-template #noData>

        <tbody>
          <tr>
            <td class="NoData">
              <div>
              <ng-container
              *ngFor="
                let dataItem of dataset                      
                  | paginate : { itemsPerPage: itemsPerPage, currentPage: pageNo, totalItems: totalRecords};
              ">
              </ng-container>
              <p class="text-message">No products found</p>
            </div>
            </td>
          </tr>
        </tbody>
      </ng-template>

