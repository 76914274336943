import { Component, OnDestroy } from '@angular/core';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { StateService } from 'src/app/shared/services/state.service';
import { ActivatedRoute } from '@angular/router';
import { Router} from'@angular/router';
import {DateFormatPipe} from "../../../pipes/date-format-pipe.pipe"
import { ViewMySubcriptionModel } from '../models/viewSubscription-model';
import { CustomCapitalizePipe } from 'src/app/pipes/capitalize-pipe/custom-capitalize-pipe.pipe';
import { User } from '../../products/models/user';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
import { ModalService } from 'src/app/shared/services/modal.service';
import { FormControl, Validators } from '@angular/forms';
import {IQueryHttpFileByApim, IHttpFileResponse } from 'src/app/shared/services/query-httpfile-by-apim'
import { Environments } from 'src/app/types/Environments';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { Environment } from 'src/environments/environment';
import { TracingModel } from 'src/app/shared/models/tracing-model';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { InterceptorSkipHeader } from 'src/app/shared/interceptors/hip2-management-interceptor';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-view-my-subscription',
  templateUrl: './view-my-subscription.component.html',
  styleUrls: ['./view-my-subscription.component.scss'],
  providers:[DateFormatPipe,CustomCapitalizePipe]
})
export class ViewMySubscriptionComponent implements OnDestroy {
  allowTracing: boolean = false;
  isChecked: boolean = false;
  dataset: ViewMySubcriptionModel={
    subscriptionName: '',
    subscriptionDisplayName:'',
    productName: '',
    subscriberName: '',
    proxyClientID:'',
    subscriptionKey:'',
    state:'',
    apiData:[],
    userNotes:'',
    remarks:'',
    productAppregistrationID:'',
    allowTracing:false,
  
    subscriptionAttributeList:[]
    };
    detailsInfo:{"title":string,"value":string}[]=[];
    productInfo:{parentData:{productName:string}}={parentData:{productName:""}}
    loaderActive: boolean = true;
    breadCrumbSubscriptionName:string = "";
    breadCrumbSubscriptionDisplayName:string = "";
    breadCrumbProductId:string="";
    breadCrumbProductName:string="";
    subscriberFullName:string="";
    keyValue:string="";
    selectedApiName!: string;
    showSubscriptionAttribute:boolean=false;
    pageInfo:string = "A subscription is created against a Product, containing one or more APIs. Once subscribed, an application gets access to all APIs inside a Product in a secure way.\n\n You are viewing a Product subscription that has been created in Azure API Management."
    isStateActive:boolean = true;
    infoMessagePostmanOption:String="This feature is not implemented yet. Please, <a href='https://ac-its.atlassian.net/browse/HIP-3160' target='_blank'>vote</a> for it, so it gets ranked higher in the backlog."
    approverList: string[] = [];
    enableColonDateFormat:boolean = true;
    environmentSelection!: Environments;
    downloadOptionControl=new FormControl('Open API',[Validators.required]);
    downloadTestOptionControl=new FormControl('Visual Studio Code script',[Validators.required]);
    stateService: any;
    destroyed = new Subject<void>();
    successMsg:string = '';
  showSuccess: boolean = false;
  showFailure:boolean = false;
  failure:boolean = false;
  failureMsg:string = '';
  toggleDisabled: boolean = false;
  showToggle:boolean=true;
  currentToggleState: boolean = false;
  originalToggleState: boolean = false;
  yamlURL:string='';
  jsonURL:string='';
    constructor(private hipManagementService: Hip2ManagementService,
     protected route: ActivatedRoute, private router:Router, private dataPipe: DateFormatPipe,private capitalizePipe :CustomCapitalizePipe, private errorHandleService:ErrorHandleService, public modal: ModalService, private http: HttpClient){
        let passedData:any={}; // Type is any as it will map input data with different structure from many pages i.e ds table , View Product 
        passedData=this.router.getCurrentNavigation()?.extras.state??{productName:""};
        if(passedData.parentData != undefined){
          this.breadCrumbProductName=passedData.parentData?.detail?.tableData[0]?.productName??"";
          this.breadCrumbSubscriptionDisplayName=passedData.parentData?.subscriptionName??"";
          sessionStorage.setItem(this.route.snapshot.paramMap.get('apimProductId') ?? "",this.breadCrumbProductName);
        }else{
          this.breadCrumbProductName=sessionStorage.getItem(this.route.snapshot.paramMap.get('apimProductId') ?? "")??""
          this.breadCrumbSubscriptionDisplayName=sessionStorage.getItem("subscriptionDisplayName")??"";
        }
      }
  ngOnDestroy(): void {
    this.modal.unRegister('api_def_download');
    this.modal.unRegister('test_download');
  }
    
    ngOnInit(): void {
      this.breadCrumbProductId = this.route.snapshot.paramMap.get('apimProductId') ?? "";
      this.breadCrumbSubscriptionName = this.route.snapshot.paramMap.get('subscriptionName') ?? "";     
      this.getSubscriptionBySubscriptionName(this.breadCrumbSubscriptionName, this.breadCrumbProductId);    
      this.modal.register('api_def_download');
      this.modal.register('test_download');
      this.downloadHttpFile;
      let envValue=Environment.environment;   
      if(envValue=='prd'){
          this.showToggle=false;
      }
    }
    
    getSubscriptionBySubscriptionName(subscriptionName: string,apimProductId: string) {
      this.hipManagementService.getDeveloperSubscriptionBySubscriptionName(subscriptionName,apimProductId).subscribe(response => {
        let subscription = response;     
        if(subscription.hasAccess){     
        this.breadCrumbProductName=subscription.productName;   
        this.breadCrumbSubscriptionName=subscription.subscriptionName;
        this.breadCrumbSubscriptionDisplayName=subscription.subscriptionDisplayName;
        sessionStorage.setItem("subscriptionDisplayName",subscription.subscriptionDisplayName);
        sessionStorage.setItem(this.route.snapshot.paramMap.get('apimProductId') ?? "",this.breadCrumbProductName);
        sessionStorage.setItem(this.route.snapshot.paramMap.get('productId') ?? "",this.breadCrumbProductId);
        this.productInfo={parentData:{productName:this.breadCrumbProductId}}
        this.subscriberFullName=subscription.subscriberName;
        let subscriberName=(this.subscriberFullName).split(" ");
        let formattedSubscriberName = "";
        subscriberName.forEach(name => {
          let nameValue=this.capitalizePipe.transform(name);
          formattedSubscriberName=formattedSubscriberName.length==0?nameValue:(formattedSubscriberName+" "+nameValue);
        });
        subscription.productApprovers.forEach((approverEmail:string) => {
          this.hipManagementService.getListOfADUsers_Approvers(approverEmail).subscribe({
            next:(response)=>{
             response.forEach((userData: User) => {
             this.approverList.push(userData.name);
             });
            }})
        });
        this.dataset = {
          subscriptionName: subscription.subscriptionName,
          subscriptionDisplayName:subscription.subscriptionDisplayName,
          productName: subscription.productName,
          subscriberName: formattedSubscriberName,
          proxyClientID: subscription.proxyClientID,
          subscriptionKey:subscription.subscriptionKey,
          state: subscription.state=="submitted"?"Pending Approval":this.capitalizePipe.transform(subscription.state),
          apiData:[],
          userNotes:subscription.usageNotes,
          productAppregistrationID:subscription.productAppregistrationID,
          remarks: subscription.state=="rejected"?(subscription.statusChangedReason.length>0?subscription.statusChangedReason:"-"): 
          (subscription.remarks.length>0?subscription.remarks:"-"),
          subscriptionAttributeList: subscription.subscriptionAttributeList,
          allowTracing:subscription.allowTracing
        }
        this.originalToggleState=subscription.allowTracing;
        this.isChecked = response.allowTracing;
        this.isStateActive = (subscription.state =='active')?true:false;
        let formattedActivatedDate= subscription.activatedDate!=undefined?this.dataPipe.transform(subscription.activatedDate,this.enableColonDateFormat):"  - | -"
        this.detailsInfo = subscription.state =='cancelled'?[
          {'title':'Application Name', 'value':subscription.applicationName},
          {'title':'Application Number', 'value':subscription.cmdbId},
          {'title':'Application Owner', 'value':subscription.appOwner},
          {'title':'Business Area', 'value':subscription.businessAreaCode},
          {'title':'Unit Code', 'value':subscription.unitCode},
          {'title':'Cost Center', 'value':subscription.costCenter},
          {'title':'Date Requested', 'value': this.dataPipe.transform(subscription.dateRequested, this.enableColonDateFormat)},
          {'title':'Date Cancelled', 'value': subscription.cancelledDate!=undefined?this.dataPipe.transform(subscription.cancelledDate, this.enableColonDateFormat):"  - | -"}
        ]: [
          {'title':'Application Name', 'value':subscription.applicationName},
          {'title':'Application Number', 'value':subscription.cmdbId},
          {'title':'Application Owner', 'value':subscription.appOwner},
          {'title':'Business Area', 'value':subscription.businessAreaCode},
          {'title':'Unit Code', 'value':subscription.unitCode},
          {'title':'Cost Center', 'value':subscription.costCenter},
          {'title':'Date Requested', 'value': this.dataPipe.transform(subscription.dateRequested, this.enableColonDateFormat)},
          {'title':'Date Activated', 'value': formattedActivatedDate}
        ];     
        this.hipManagementService.getDeveloperSubscriptionApisByApimProductId(apimProductId).subscribe(a => {
          let productAPIs=[];
          for (let index = 0; index < a.length; index++) {
            let element = a[index];
            let api={
              apimApiName: element.apimApiName,
              name:element.name,
              apiOwner:element.apiOwner,
              apiLink:'/publisher/view-api/'+element.apimApiName
            }
            productAPIs.push(api);
          }
          this.dataset.apiData = productAPIs;
          this.loaderActive = false;  
          sessionStorage.setItem('productNameValue', this.breadCrumbProductId);
          sessionStorage.setItem('productDisplayNameValue', subscription.productName);
        },error=>{
          console.log("error",error);
          this.loaderActive = false;  
        });
      }else{
        this.router.navigate(['/unauthorized']);
    }},
    error=>{
   this.errorHandleService.handleError(error);
    });
    }
    
    onApiViewClick(apiData:any,productName:any){
      sessionStorage.setItem('apiDisplayNameValue', apiData);
      sessionStorage.setItem('productNameValue', this.breadCrumbProductId);
      sessionStorage.setItem('productDisplayNameValue', productName);
    } 
    openModal(apimApiName:string){ 
      this.modal.toggleModal('api_def_download');
      this.selectedApiName = apimApiName;
      console.log('selectedApiName',this.selectedApiName)
    }

    openModalAndDownload(apimApiName:string){
      this.modal.toggleModal('test_download');
      this.selectedApiName = apimApiName
    }

    downloadHttpFile(apimApiName: string) {
      const queryHttpFileByApim: IQueryHttpFileByApim = {
        SubscriptionName: this.dataset.subscriptionName,
        ApiVersion: '1.0',
        ApimApiName: apimApiName,
        ProxyClientId: this.dataset.proxyClientID,
        Environment:this.environmentSelection
    
      };
      this.hipManagementService.getHttpFile(queryHttpFileByApim).subscribe(
        response => {
          let envValue= Environment.environment;
          const { fileName, fileString, errorMessage } = response;
          if (!errorMessage || errorMessage.length === 0) {
            let updatedFileName=fileName+'-'+envValue+'.http';
            this.downloadFile(fileString, updatedFileName); // Pass environment to the downloadFile method
          } else {
            console.error('Error in getHttpFile:', errorMessage);
          }
        },
        error => {
          console.error('Error in getHttpFile:', error);
        }
      );
    }
    

    downloadFile(data: BlobPart, filename: string = "") {
      const blob = new Blob([data], { type: 'application/text' });
      let trimmedFilename = filename.replace(/\s/g, ''); // Remove all spaces from the filename
      if ((window.navigator as any).msSaveBlob) {
        (window.navigator as any).msSaveBlob(blob, trimmedFilename);
      } else {
        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.setAttribute('download', trimmedFilename);
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
    
    confirmDownload(selectedOption: string) {
      if (selectedOption === 'Visual Studio Code script') {
        this.downloadHttpFile(this.selectedApiName);
      } else if (selectedOption === 'Postman project') {
      
      } else {
        console.error('Invalid file format selected.');
      }
    }
    confirmAPIDefDownload(selectedOption: string){
     console.log(selectedOption);
     this.hipManagementService.getApiSpecification(this.selectedApiName).subscribe(
      {          
        next: (response:any) => {                                    
          if(response?.length > 0) {
            var url = '';
            if(response.length == 1){
              url=response[0].Url;
              this.yamlURL = response[0].Url;               
            }              
            else if(response.length == 2){
              url=response[1].Url;
              this.yamlURL = response[0].Url;
              this.jsonURL = response[1].Url;
            }
            let filePath:string ='';
            let fileType:string='';
            if (selectedOption.toLowerCase() == 'open api') {
              filePath = this.yamlURL;
              fileType = '.yaml'      
            }
            else if (selectedOption.toLowerCase() == 'swagger') {
              filePath = this.jsonURL;
              fileType = '.json'
            }    
            let currentDate = new Date();
            let formattedDate=new DatePipe('en-US').transform(currentDate,"YYYYMMdd-HHmmss");
            this.getRawFile(filePath).subscribe(data => {
              if(fileType=='.yaml'){
                let fileName= this.selectedApiName+'_openapi_'+Environment.environment+'_' +formattedDate+fileType;
                this.downloadFile(data,fileName);
              }else{
                let fileName= this.selectedApiName+'_swagger_'+Environment.environment+'_' +formattedDate+fileType;
                this.downloadFile(data,fileName);
              } 
             });
          }                    
        },
        error: (error:any) => {   
          this.loaderActive = false;     
          this.errorHandleService.handleError(error);    
        }
      });
    }

    public getRawFile(filePath:string): Observable<any> {
      const skipheaders = new HttpHeaders().set(InterceptorSkipHeader, '');
      return this.http.get(filePath, { headers : skipheaders, responseType: 'text' });
    }
    onAllowTracingChange(flag: boolean): void {
      const tracingModel = {
        subscriptionName: this.breadCrumbSubscriptionName,
        apimProductId: this.breadCrumbProductId,
        allowTracing: flag
      };
     this.originalToggleState = this.currentToggleState;
    this.hipManagementService.apiApimTracing(tracingModel).subscribe(
      (response: any) => {
       this.originalToggleState=flag;
        this.toggleDisabled = false; 
        this.currentToggleState = flag; 
        if (flag) {
          this.successMsg = 'Tracing enabled';
        } else {
          this.successMsg = 'Tracing disabled';
        }
        this.showSuccess = true;
        setTimeout(() => {
          this.showSuccess = false;
        }, 5000);
      },
      (error: any) => {
        
        console.error('Error updating tracing:', error);
        this.toggleDisabled = false; 
         this.currentToggleState = this.originalToggleState; 
         this.dataset.allowTracing = this.originalToggleState;
      let   temp = this.dataset
         this.dataset = {
          subscriptionName: temp.subscriberName,
          subscriptionDisplayName:temp.subscriptionDisplayName,
          productName: temp.productName,
          subscriberName: temp.subscriberName,
          proxyClientID:temp.proxyClientID,
          subscriptionKey:temp.subscriptionKey,
          state:temp.state,
          apiData:temp.apiData,
          userNotes:temp.userNotes,
          remarks:temp.remarks,
          productAppregistrationID:temp.productAppregistrationID,
          allowTracing:!flag,
        
          subscriptionAttributeList:[]
          };
        if (flag) {
          this.failureMsg = 'Failed to enable tracing';
        } else {
          this.failureMsg = 'Failed to disable tracing';
        }
        this.showFailure = true;
        setTimeout(() => {
          this.showFailure = false;
        }, 5000);
      }
    );
  }
}