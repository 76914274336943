
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-auto-search-tag',
  templateUrl: './auto-search-tag.component.html',
  styleUrls: ['./auto-search-tag.component.scss']
})
export class AutoSearchTagComponent  implements OnInit {
  @Input() props: any = [];
  @Input() dataSets: any = [];
  @Input() checkError: boolean = false;
  @Input() control= new FormControl('');
  title: string = "";
  placeHolder: string = "Enter";
  options: any = [];
  valOptions: any = [];
  filteredOptions: any=[];
  isActive: boolean = false;
  inputField: any ;
  errorMsg: string = "";
  isError: boolean = false;
  id: string='';
  loading : Boolean = true;
  // objID: string = '';
  showDropdown: boolean = false;
  tags: any =[];
  @Output() search = new EventEmitter();
  constructor(private http: HttpClient, private elementRef: ElementRef) {
    this.isActive = false;
    this.showDropdown = false;
    this.inputField = '';
      this.id = Math.random().toString(36).substring(2);
  }

  ngOnInit(): void {
    this.title = this.props.heading;
    this.errorMsg = this.props.errMsg;
    if(this.props.placeholder){
      this.placeHolder = this.props.placeholder;
    }
    this.options = this.dataSets;
    this.options.map((e:any)=>{
     this.valOptions.push(e.value);
     this.checkError = this.checkError;
    });
    console.log("val options",this.valOptions);
   if(this.control.value){
     this.setData();
   }
   }
   addTag(event: any){    
    let tag = event.target.value.replace(/\s+/g, ' ');
    //checks whether the entered tag is an exiting tag or new tag
    let isExistingTag:boolean = false;
       if(tag.displayName == undefined){
        this.dataSets.forEach((tagData: { displayName: string; apimTagId: string }) => {
          if(tagData.displayName.toLowerCase() == tag.toLowerCase()){
            tag = tagData;
            isExistingTag = true;
          }
        });
        if(!isExistingTag){
          let newTag = {
            "displayName": tag,
            "apimTagId": tag
          }
          tag = newTag;
        }
      }
        if(tag.displayName.length > 1){
          this.tags.push(tag);
          var setOfTags = new Set(this.tags);
          this.tags = Array.from(setOfTags);
          this.control.setValue(this.tags);
        }
    event.target.value='';
    this.showDropdown = false;
    this.checkError=true;
  }
  removeTag(option: string){
    this.tags = this.tags.filter((item: any) => item!== option)   
    this.control.setValue(this.tags); 
    this.checkError=true;   
  }
  removeText(event: any){      
    event.target.value=''; 
    this.checkError=true;      
  }

  sendData(event: any){
    this.showDropdown = true;
    this.loading = true;
    let searchValue:String=event.target.value.toLowerCase()??"";
    let extraSpaceTrimmedSearchValue=(searchValue.trimStart()).trimEnd();      
    this.search.emit(extraSpaceTrimmedSearchValue);
    this.checkError=true;         
  }
  setData(){
    let rawValue: any = this.control.value;
    rawValue.map((val: any) => {
      this.tags.push(val);
    })
  
  }
  getData(selectedOption: any){  
    let tag = selectedOption;
    this.tags.push(tag);
    var setOfTags = new Set(this.tags);
    this.tags = Array.from(setOfTags);
    this.control.setValue(this.tags);
    this.filteredOptions = [];
    this.isError = false;
    this.showDropdown = false;
  }

  ngOnChanges(): void {
    this.filteredOptions = this.dataSets;
    if(this.filteredOptions.length){
      this.loading = false;   
    }else {
      this.loading = false;
    }   
    this.isActive = true;   
  }
 
  @HostListener('document:click', ['$event'])
  handleClickOutside(event: any) {
   if(this.tags.length == 0){
    this.control.setValue('');       
   }
    if (!this.elementRef.nativeElement.contains(event.target) && event.target.id !== this.id) {
         this.showDropdown = false;
      this.isActive = false;      
    }
  }
} 

