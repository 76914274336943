import { Component, ElementRef, OnInit, ViewChildren } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Subject, Subscription, debounceTime } from 'rxjs';
import { CustomCapitalizePipe } from 'src/app/pipes/capitalize-pipe/custom-capitalize-pipe.pipe';
import { DateFormatPipe } from 'src/app/pipes/date-format-pipe.pipe';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
@Component({
  selector: 'app-developer-product-subscription',
  templateUrl: './developer-product-subscription.component.html',
  styleUrls: ['./developer-product-subscription.component.scss'],
  providers: [DateFormatPipe,CustomCapitalizePipe]
})
export class DeveloperProductSubscriptionComponent implements OnInit {
  searchProductvalue:any='';
  isSearchStateFilterEnabled:boolean = false;
  stateFilter: FormControl = new FormControl('');
  filterForm: FormGroup = new FormGroup({});
  itemsPerPage = 10;
  pageNo = 1;
  totalRecordCount =1;
  loaderEnabled:boolean = true;
  dataKeys : string[]=[];
  tableInfo:string="subscriptions";
  breadCrumbappId:string='';
  breadCrumbAppName:string='';
  searchProductSubscription!: Subscription;
  searchProductValueChanged:Subject<string> = new Subject<string>();
  @ViewChildren('contentSection') contentSections!: ElementRef[];
  @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];
  gridArray: any[]=[]; // type of gridArray is any as we need to pass that to generic table component
  stateList =[
    {
  'id': '1',
  'label': 'Active',
  'value': 'active'
  },
  {
  'id': '2',
  'label': 'Pending Approval',
  'value': 'submitted'
  },
  {
  'id': '3',
  'label': 'Rejected',
  'value': 'rejected'
  }
  ];
  productTableHeaderList: string [] =[
    "Product Name",
    "Subscription Name",
    "Subscriber Name",
    "State",
    "Date Requested",
    "Date Activated",
    "Actions"
  ];
  selectedStateValue: string[] = []; // for maintaining state of business Area filters by labels

  sortingHeaderList=[{head:'Product Name',key:'productName'},{head:'Subscription Name',key:'subscriptionName'},
  {head:'Subscriber Name',key:'subscriber'},
  {head:'State',key:'State'},{head:'Date Requested',key:'requestedDate'}
]
constructor(protected route: ActivatedRoute,private hipService:Hip2ManagementService, private dateFormatPipe:DateFormatPipe, private capitalizePipe: CustomCapitalizePipe){
  let queryParams = this.route.snapshot.queryParams;
  this.breadCrumbAppName = queryParams['appName']??'';
}

ngOnInit(){
  this.breadCrumbappId = this.route.snapshot.paramMap.get('apimProductId') ?? "";
  this.getSubscriptions();
  this.controlDataChange();
}
controlDataChange(){
  //fetching the selected business value and calling the API to get the data based on selected business value
  this.stateFilter.valueChanges.pipe((debounceTime(2000))).subscribe((x) => {
   let businessValue = x.map((obj:any)=>{return obj.value});
   this.selectedStateValue = businessValue;
   this.pageNo=1;
   this.getSubscriptions();
  });    

}
getSubscriptions(){
//get the selected business code
let stateCode = '';
this.gridArray=[];
//get the selected business code
this.selectedStateValue.forEach(element => {
  stateCode=stateCode.length==0?element.toLowerCase():(stateCode+','+element.toLowerCase())
});
let filter = (this.searchProductvalue.trimStart()).trimEnd();
// we are currently doing client side table sorting
let sortOrder = 'asc';
let pageNumber =  this.pageNo;
let pageSize = this.itemsPerPage;
let apiVersion = '1.0';
let cmdbId=this.breadCrumbappId;
this.loaderEnabled=true;
this.hipService.getSubscriptionsByCmdbId(cmdbId, filter, sortOrder, pageNumber,
pageSize, apiVersion,stateCode).subscribe((response)=>{
  console.log('getSubscriptionsByApplicationOwner',response);
  this.totalRecordCount=response.totalCount;
  response.subscriptionDetailsList.forEach((element:any,index:number) => {
    let subscription={
      "uniqueId":index,
       productName:element.productName,
       subscriptionName:element.subscriptionDisplayName,
       subscriber: this.capitalizePipe.transform(element.subscriberName),
       State:element.status=='submitted'?'Pending Approval':this.capitalizePipe.transform(element.status),
       requestedDate:this.dateFormatPipe.transform(element.createdOn),
       activatedDate:element.activatedDate!=undefined?this.dateFormatPipe.transform(element.activatedDate):"  - | -",
       "Action": 
       {  
         "info": "/developer/manage-subscriptions/view-subscription/"+element.subscriptionName+'/'+element.apimProductId,
         "isQueryParamsReqForInfoLink":true,
         "queryparamsforInfoLink": {'appName' : this.breadCrumbAppName, 'appId': this.breadCrumbappId, 'productName':element.productName, 'subDisplayName':element.subscriptionDisplayName}
     }
     };
     this.gridArray.push(subscription);
  });
  if(response.totalCount >0){
    this.dataKeys = Object.keys(this.gridArray[0]);
  }
  this.loaderEnabled=false;
  this.setContentSectionHeight();
})
}
ngAfterContentInit(): void {
  this.searchProductSubscription = this.searchProductValueChanged.pipe(debounceTime(1500)).subscribe(value=>{
   this.searchProductvalue=value;
   if(this.searchProductvalue.length>2 || this.searchProductvalue.length==0){
     this.pageNo=1;
     this.getSubscriptions();    
     this.setContentSectionHeight();  
       }   
  })
  this.setContentSectionHeight();
}
  productSearch(event:any){
    this.searchProductValueChanged.next(event)
  }
  public renderPageValue(value: number) {
    this.itemsPerPage = value;
    this.pageNo = 1;
    this.getSubscriptions();
   }
   onTablePage(event: any){
    this.pageNo= event;
    this.getSubscriptions();
  }
  childListData(event:any){

  }
  setContentSectionHeight() {
    const windowHeight = window.innerHeight;
    let bodyWrapperHeight: any;
    if(this.bodyWrapper!=undefined){
      this.bodyWrapper.forEach(section =>{
        bodyWrapperHeight = windowHeight - section.nativeElement.getBoundingClientRect().top;
        // console.log('bodyWrapperHeight is', bodyWrapperHeight, windowHeight);
      });
      
    }
   if(this.contentSections!=undefined){
    this.contentSections.forEach(section => {
      const offsetTop = section.nativeElement.getBoundingClientRect().top;      
      const sectionHeight = windowHeight - Math.floor(offsetTop) - (window.innerWidth * 0.0052 * 4.8); //(2.5 * 48);
      // console.log('bodyWrapperHeight is', bodyWrapperHeight," section offset is", offsetTop," and section Height is ", sectionHeight," footer height ", window.innerWidth * 0.0052 * 4.8);
      
      section.nativeElement.style.minHeight = `${Math.floor(sectionHeight)}px`;
    });
   }
  }
}
