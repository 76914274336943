import { Component, ContentChild, ElementRef, Input } from '@angular/core';

@Component({
  selector: 'app-copy-clipboard',
  templateUrl: './copy-clipboard.component.html',
  styleUrls: ['./copy-clipboard.component.scss']
})
export class CopyClipboardComponent {
  @Input() copyTextValue: any = ''
  isCopied:boolean=false;
 
  copyTextToClipboard(): void {
      const copyText = function(copyElem: any){
        const el = document.createElement('textarea');
        el.value = copyElem;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
      
      }
      copyText(this.copyTextValue);
      
      if (this.copyTextValue.length > 1){
        this.isCopied = true;
        setTimeout(()=>{
          this.isCopied=false;
         },1000);
      }
  }
  private findInputElement(element: HTMLElement): HTMLInputElement | null {
    const inputElements = element.getElementsByTagName('input');
    if (inputElements.length > 0) {
      return inputElements[0];
    }
    return null;
  }
}
