import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-health-timeline',
  templateUrl: './health-timeline.component.html',
  styleUrls: ['./health-timeline.component.scss']
})
export class HealthTimelineComponent implements OnInit{
  @Input() history : any[] = [];
  endPoints: any = [];

  ngOnInit(): void {
    this.endPoints = this.history.map((obj)=>{
      const date = new Date(obj.on);
      const options = { year: 'numeric', month: 'long', day: 'numeric'} as Intl.DateTimeFormatOptions;
      const formattedDate = date.toLocaleString('en-US', options);
      const timeOptions = {hour: 'numeric', minute: 'numeric'} as Intl.DateTimeFormatOptions;
      const formattedTime = date.toLocaleString('en-US', timeOptions);
      obj.formattedDate = formattedDate;
      obj.formattedTime = formattedTime;
      delete obj.on;
      return obj
    })
  }

}
