export  const InternalApiCatalogUrlConstant ={
    developmentPortalLink: "https://ase-d-app-10001236-hip-ui-portal.azurewebsites.net",
    testPortalLink: "https://ase-t-app-10001236-hip-ui-portal.azurewebsites.net"
}

export  const ProductionApiCatalogUrlConstant ={
    developmentPortalSecondaryLink: "https://ase-p-app-10001236-hip-ui-portal-dev.azurewebsites.net",
    testPortalSecondaryLink: "https://ase-p-app-10001236-hip-ui-portal-tst.azurewebsites.net",
    prodPortalSecondaryLink: "https://ase-p-app-10001236-hip-ui-portal-prd.azurewebsites.net",
    developmentPortalLink: 'https://apis-catalog-dev.atlascopco.com',
    testPortalLink: 'https://apis-catalog-tst.atlascopco.com',
    prodPortalLink: 'https://apis-catalog.atlascopco.com'
}