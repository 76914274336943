<div class="tags-wrapper" *ngIf="this.renderTag; else elseBlock">
    <ng-container *ngIf="this.isRenderLabelOnlyEnabled;else renderValue">
        <span class="ds_c-tags review" *ngFor="let tag of this.value">{{tag.label}}</span>
    </ng-container>
    <ng-template #renderValue>
        <span class="ds_c-tags review" [class.ds_c-tags-clickable]="this.isClickableLabel" [class.ds_c-tags-card]="this.isTagLabel" (click)="onLabelClick(tag)" *ngFor="let tag of this.value">{{tag}}</span>
    </ng-template>
</div>
<ng-template #elseBlock>
    <h3 class="title bold">{{this.value.number+"/"+this.value.value}}</h3>
</ng-template>
