import { Component } from '@angular/core';

@Component({
  selector: 'app-health-monitor',
  templateUrl: './health-monitor.component.html',
  styleUrls: ['./health-monitor.component.scss']
})
export class HealthMonitorComponent {
  HealthCards = [
    {
      apiName: "ACDC Material Returnability",
      Status:"healthy",
      TotalHits:14,
      Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non dapibus orci.",
      BusinessArea:"CO",
      LastExecuted:"2023-06-12T08:42:53.81",
      Statistics:{
      Healthy:1,
      Degraded:1,
      Unhealthy:1,
      Advisory:1
      }   
    },
    {
      apiName: "Notification API",
      Status:"healthy",
      TotalHits:14,
      Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non dapibus orci.",
      BusinessArea:"IT",
      LastExecuted:"2023-06-12T08:42:53.81",
      Statistics:{
      Healthy:5,
      Degraded:2,
      Unhealthy:0,
      Advisory:1
      }   
    },
    {
      apiName: "Atlas Copco Core API",
      Status:"advisory",
      TotalHits:14,
      Description: "Lorem ipsum dolor sit amet, orci.",
      BusinessArea:"VT",
      LastExecuted:"2023-06-12T08:42:53.81",
      Statistics:{
      Healthy:2,
      Degraded:0,
      Unhealthy:0,
      Advisory:6
      }   
    },
    {
      apiName: "Api Catalog",
      Status:"degraded",
      TotalHits:14,
      Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non dapibus orci.",
      BusinessArea:"CT",
      LastExecuted:"2023-06-12T08:42:53.81",
      Statistics:{
      Healthy:3,
      Degraded:7,
      Unhealthy:1,
      Advisory:1
      }   
    },
    {
      apiName: "Lorem ipsum dolor amet elit",
      Status:"unhealthy",
      TotalHits:14,
      Description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum non dapibus orci.  Vestibulum non dapibus orci.  Vestibulum non dapibus orci.",
      BusinessArea:"PT",
      LastExecuted:"2023-06-12T08:42:53.81",
      Statistics:{
      Healthy:0,
      Degraded:0,
      Unhealthy:4,
      Advisory:0
      }   
    }

  ]
  history = [
    {
        "id": 5732,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2023-06-20T10:52:53.4583882+00:00"
    },
    {
        "id": 5731,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"5959aa66-9f55-491e-9a08-9b56bf2e3a11\" }'",
        "status": "Unhealthy",
        "on": "2023-06-20T10:51:46.9293517+00:00"
    },
    {
        "id": 2322,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2023-02-18T07:03:57.424846+00:00"
    },
    {
        "id": 2321,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"b5e86a85-0b0f-4ce9-af77-4a3322a1399e\" }'",
        "status": "Unhealthy",
        "on": "2023-02-18T07:02:54.1487134+00:00"
    },
    {
        "id": 2318,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2023-02-17T02:44:59.3627248+00:00"
    },
    {
        "id": 2317,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"e49d5918-1b6d-4122-99a3-b4eaad35b063\" }'",
        "status": "Unhealthy",
        "on": "2023-02-17T02:43:54.7512573+00:00"
    },
    {
        "id": 1280,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"c364947e-ca7a-422e-be59-de71c1783877\" }'",
        "status": "Unhealthy",
        "on": "2022-10-11T20:10:29.5078624+00:00"
    },
    {
        "id": 1275,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-10-11T06:46:21.5225041+00:00"
    },
    {
        "id": 1274,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"033798b6-b189-403b-a4b1-f8b4f1732e87\" }'",
        "status": "Unhealthy",
        "on": "2022-10-11T06:45:20.9023352+00:00"
    },
    {
        "id": 1273,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-10-10T13:17:48.738709+00:00"
    },
    {
        "id": 1272,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"f816c16e-f6c1-4c93-a55b-1f4edb1baebf\" }'",
        "status": "Unhealthy",
        "on": "2022-10-10T13:16:47.6188487+00:00"
    },
    {
        "id": 1271,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-10-04T02:20:04.6244024+00:00"
    },
    {
        "id": 1270,
        "name": "endpoint",
        "description": "Unexpected response type 'text/html' retrieved form health endpoint 'https://api.atlascopco.com/shared/communications/v1/sys/health'. Expecting 'application/json'",
        "status": "Unhealthy",
        "on": "2022-10-04T02:19:02.7254016+00:00"
    },
    {
        "id": 1255,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-09-17T02:07:24.1077534+00:00"
    },
    {
        "id": 1254,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"47a6ced5-478b-4d97-8c7c-37d012c3b794\" }'",
        "status": "Unhealthy",
        "on": "2022-09-17T02:06:22.0407943+00:00"
    },
    {
        "id": 1233,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-08-30T15:51:16.6205735+00:00"
    },
    {
        "id": 1230,
        "name": "endpoint",
        "description": "Unexpected response type 'text/html' retrieved form health endpoint 'https://api.atlascopco.com/shared/communications/v1/sys/health'. Expecting 'application/json'",
        "status": "Unhealthy",
        "on": "2022-08-30T15:48:10.3689402+00:00"
    },
    {
        "id": 1219,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-08-12T12:08:20.7966944+00:00"
    },
    {
        "id": 1218,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"faefa592-8fcd-495c-8d7c-39cbd91867a5\" }'",
        "status": "Unhealthy",
        "on": "2022-08-12T12:07:19.1493042+00:00"
    },
    {
        "id": 1209,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-08-10T11:48:56.8903107+00:00"
    },
    {
        "id": 1208,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"33783bdb-fc12-4f63-a877-1d66f45e0dd0\" }'",
        "status": "Unhealthy",
        "on": "2022-08-10T11:46:32.8588612+00:00"
    },
    {
        "id": 1187,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-07-26T17:04:40.7525285+00:00"
    },
    {
        "id": 1186,
        "name": "endpoint",
        "description": "The API is not authorized to read health endpoint '/sys/health'",
        "status": "Unhealthy",
        "on": "2022-07-26T16:53:24.5303632+00:00"
    },
    {
        "id": 1173,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-07-22T06:00:14.7869599+00:00"
    },
    {
        "id": 1172,
        "name": "endpoint",
        "description": "Unexpected response type 'text/html' retrieved form health endpoint 'https://api.atlascopco.com/shared/communications/v1/sys/health'. Expecting 'application/json'",
        "status": "Unhealthy",
        "on": "2022-07-22T05:59:13.7987532+00:00"
    },
    {
        "id": 1171,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-07-22T02:00:37.2165359+00:00"
    },
    {
        "id": 1170,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"d6d99db1-a44e-406e-ae07-5a3c6f89e283\" }'",
        "status": "Unhealthy",
        "on": "2022-07-22T01:59:36.5067187+00:00"
    },
    {
        "id": 1169,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-07-20T15:11:09.8576964+00:00"
    },
    {
        "id": 1168,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"c9ad4365-0f74-4f0c-9af4-bcc766c0a5e8\" }'",
        "status": "Unhealthy",
        "on": "2022-07-20T15:10:08.0166361+00:00"
    },
    {
        "id": 1157,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-07-15T12:22:29.3577328+00:00"
    },
    {
        "id": 1156,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"a8cafb15-e395-4101-a7bc-f9ea7b30b80e\" }'",
        "status": "Unhealthy",
        "on": "2022-07-15T12:21:28.6696402+00:00"
    },
    {
        "id": 1039,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-06-07T20:02:29.3731438+00:00"
    },
    {
        "id": 1036,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: 'Service Unavailable'",
        "status": "Unhealthy",
        "on": "2022-06-07T20:00:25.7669856+00:00"
    },
    {
        "id": 455,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-05-15T09:06:53.8782739+00:00"
    },
    {
        "id": 454,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"d8b0c2f7-57b4-485f-b09c-d75a89f33d10\" }'",
        "status": "Unhealthy",
        "on": "2022-05-15T09:05:52.1730926+00:00"
    },
    {
        "id": 453,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-05-12T18:01:41.7358683+00:00"
    },
    {
        "id": 452,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"7355f337-50a8-4ffd-b0d1-e57c943c0acc\" }'",
        "status": "Unhealthy",
        "on": "2022-05-12T18:00:36.1046639+00:00"
    },
    {
        "id": 451,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-05-12T17:58:01.6665597+00:00"
    },
    {
        "id": 450,
        "name": "endpoint",
        "description": "Unexpected response type 'text/html' retrieved form health endpoint 'https://api.atlascopco.com/shared/communications/v1/sys/health'. Expecting 'application/json'",
        "status": "Unhealthy",
        "on": "2022-05-12T17:56:58.7531355+00:00"
    },
    {
        "id": 305,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-04-10T10:53:01.4065283+00:00"
    },
    {
        "id": 304,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"f53f6413-779c-4645-ae69-cd866a37385c\" }'",
        "status": "Unhealthy",
        "on": "2022-04-10T10:51:59.6480369+00:00"
    },
    {
        "id": 285,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-04-08T03:02:52.67074+00:00"
    },
    {
        "id": 284,
        "name": "endpoint",
        "description": "Unexpected response type 'text/html' retrieved form health endpoint 'https://api.atlascopco.com/shared/communications/v1/sys/health'. Expecting 'application/json'",
        "status": "Unhealthy",
        "on": "2022-04-08T03:01:52.1106263+00:00"
    },
    {
        "id": 143,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-03-04T21:56:50.2958671+00:00"
    },
    {
        "id": 142,
        "name": "endpoint",
        "description": "Unexpected response type 'text/html' retrieved form health endpoint 'https://api.atlascopco.com/shared/communications/v1/sys/health'. Expecting 'application/json'",
        "status": "Unhealthy",
        "on": "2022-03-04T21:55:49.8535475+00:00"
    },
    {
        "id": 141,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-03-04T18:56:45.6738127+00:00"
    },
    {
        "id": 140,
        "name": "endpoint",
        "description": "Unexpected response type 'text/html' retrieved form health endpoint 'https://api.atlascopco.com/shared/communications/v1/sys/health'. Expecting 'application/json'",
        "status": "Unhealthy",
        "on": "2022-03-04T18:55:44.0021715+00:00"
    },
    {
        "id": 139,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-03-04T12:57:03.4223947+00:00"
    },
    {
        "id": 138,
        "name": "endpoint",
        "description": "Unexpected response type 'text/html' retrieved form health endpoint 'https://api.atlascopco.com/shared/communications/v1/sys/health'. Expecting 'application/json'",
        "status": "Unhealthy",
        "on": "2022-03-04T12:56:02.2172901+00:00"
    },
    {
        "id": 127,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-03-01T06:53:20.0769782+00:00"
    },
    {
        "id": 126,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"65259c37-a51a-4cfa-a799-f4cf2c40eebd\" }'",
        "status": "Unhealthy",
        "on": "2022-03-01T06:52:19.3821843+00:00"
    },
    {
        "id": 97,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2022-01-22T13:41:37.3179419+00:00"
    },
    {
        "id": 96,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"19e2ebb9-0a0e-464d-b262-93ba40abdb47\" }'",
        "status": "Unhealthy",
        "on": "2022-01-22T13:40:35.9962018+00:00"
    },
    {
        "id": 71,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-24T00:01:35.5452778+00:00"
    },
    {
        "id": 70,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: 'Service Unavailable'",
        "status": "Unhealthy",
        "on": "2021-12-24T00:00:33.3672647+00:00"
    },
    {
        "id": 69,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-23T20:48:24.0805887+00:00"
    },
    {
        "id": 64,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: 'Service Unavailable'",
        "status": "Unhealthy",
        "on": "2021-12-23T20:41:34.4107839+00:00"
    },
    {
        "id": 63,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-17T08:34:15.6928303+00:00"
    },
    {
        "id": 62,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 500, \"message\": \"Internal server error\", \"activityId\": \"835ae2f6-832d-43b5-ba99-4d483005fd2e\" }'",
        "status": "Unhealthy",
        "on": "2021-12-17T08:33:15.1571677+00:00"
    },
    {
        "id": 59,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-16T08:49:32.4162196+00:00"
    },
    {
        "id": 58,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 401, \"message\": \"Unauthorized. Access token is missing or invalid.\" }'",
        "status": "Unhealthy",
        "on": "2021-12-16T08:49:28.1394563+00:00"
    },
    {
        "id": 55,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-16T08:48:18.668276+00:00"
    },
    {
        "id": 54,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 401, \"message\": \"Unauthorized. Access token is missing or invalid.\" }'",
        "status": "Unhealthy",
        "on": "2021-12-16T08:48:12.6650481+00:00"
    },
    {
        "id": 51,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T16:50:03.7579707+00:00"
    },
    {
        "id": 50,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 401, \"message\": \"Unauthorized. Access token is missing or invalid.\" }'",
        "status": "Unhealthy",
        "on": "2021-12-15T16:49:53.1432639+00:00"
    },
    {
        "id": 47,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T16:46:06.4102062+00:00"
    },
    {
        "id": 46,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 401, \"message\": \"Unauthorized. Access token is missing or invalid.\" }'",
        "status": "Unhealthy",
        "on": "2021-12-15T16:46:01.9302015+00:00"
    },
    {
        "id": 43,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T16:45:14.6486458+00:00"
    },
    {
        "id": 42,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 401, \"message\": \"Unauthorized. Access token is missing or invalid.\" }'",
        "status": "Unhealthy",
        "on": "2021-12-15T16:45:09.0225837+00:00"
    },
    {
        "id": 39,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T16:44:22.2284304+00:00"
    },
    {
        "id": 38,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 401, \"message\": \"Unauthorized. Access token is missing or invalid.\" }'",
        "status": "Unhealthy",
        "on": "2021-12-15T16:44:14.2637829+00:00"
    },
    {
        "id": 35,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T16:43:30.6970729+00:00"
    },
    {
        "id": 34,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 401, \"message\": \"Unauthorized. Access token is missing or invalid.\" }'",
        "status": "Unhealthy",
        "on": "2021-12-15T16:43:17.3068555+00:00"
    },
    {
        "id": 31,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T16:42:27.9438378+00:00"
    },
    {
        "id": 30,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 401, \"message\": \"Unauthorized. Access token is missing or invalid.\" }'",
        "status": "Unhealthy",
        "on": "2021-12-15T16:42:15.6930683+00:00"
    },
    {
        "id": 27,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T16:41:26.2699959+00:00"
    },
    {
        "id": 26,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 401, \"message\": \"Unauthorized. Access token is missing or invalid.\" }'",
        "status": "Unhealthy",
        "on": "2021-12-15T16:41:16.1271988+00:00"
    },
    {
        "id": 23,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T16:40:24.6760684+00:00"
    },
    {
        "id": 22,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 401, \"message\": \"Unauthorized. Access token is missing or invalid.\" }'",
        "status": "Unhealthy",
        "on": "2021-12-15T16:40:20.1409288+00:00"
    },
    {
        "id": 19,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T16:28:28.8148869+00:00"
    },
    {
        "id": 18,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 401, \"message\": \"Unauthorized. Access token is missing or invalid.\" }'",
        "status": "Unhealthy",
        "on": "2021-12-15T16:28:23.035021+00:00"
    },
    {
        "id": 15,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T16:27:37.4459675+00:00"
    },
    {
        "id": 14,
        "name": "endpoint",
        "description": "Unexpected content from health endpoint: '{ \"statusCode\": 401, \"message\": \"Unauthorized. Access token is missing or invalid.\" }'",
        "status": "Unhealthy",
        "on": "2021-12-15T16:27:32.4459145+00:00"
    },
    {
        "id": 13,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T13:09:04.9809205+00:00"
    },
    {
        "id": 12,
        "name": "endpoint",
        "description": "Unauthorized access to health endpoint 'https://api.atlascopco.com/shared/communications/v1/sys/health'",
        "status": "Unhealthy",
        "on": "2021-12-15T13:08:59.1570847+00:00"
    },
    {
        "id": 9,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T13:08:23.9105657+00:00"
    },
    {
        "id": 8,
        "name": "endpoint",
        "description": "Unauthorized access to health endpoint 'https://api.atlascopco.com/shared/communications/v1/sys/health'",
        "status": "Unhealthy",
        "on": "2021-12-15T13:08:18.4074436+00:00"
    },
    {
        "id": 7,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T11:02:58.1706854+00:00"
    },
    {
        "id": 6,
        "name": "endpoint",
        "description": "Unauthorized access to health endpoint 'https://api.atlascopco.com/shared/communications/v1/sys/health'",
        "status": "Unhealthy",
        "on": "2021-12-15T11:02:50.5149621+00:00"
    },
    {
        "id": 3,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-15T11:00:54.4682638+00:00"
    },
    {
        "id": 2,
        "name": "endpoint",
        "description": "Unauthorized access to health endpoint 'https://api.atlascopco.com/shared/communications/v1/sys/health'",
        "status": "Unhealthy",
        "on": "2021-12-15T11:00:49.0292937+00:00"
    },
    {
        "id": 1,
        "name": "endpoint",
        "description": null,
        "status": "Healthy",
        "on": "2021-12-14T17:40:22.6666384+00:00"
    }
]
}
