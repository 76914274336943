import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit, ViewChildren, ElementRef, HostListener } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { InterceptorSkipHeader } from 'src/app/shared/interceptors/hip2-management-interceptor';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';

@Component({
  selector: 'app-developer-api-definition',
  templateUrl: './developer-api-definition.component.html',
  styleUrls: ['./developer-api-definition.component.scss']
})

export class DeveloperApiDefinitionComponent implements OnInit{
  breadCrumbApiId:string='';
  breadCrumbApiName:string='';
  loaderActive: boolean = false;
  noDataFound:boolean = false; 
  breadCrumbProductId:string='';
  breadCrumbProductName:string='';
  breadCrumbSubscriptionName:string='';
  yamlURL:string='';
  jsonURL:string='';
  showSubscriptionBreadcrumb:boolean=false;
  btnProps:any=[];
  internalApi:string = 'Internal';
  externalApi:string = 'External'
  copyExternalMessage:string="";
  copyInternalMessage:string="";
  externalAPIValue:string="";
  internalAPIvalue:string="";
  isExternal:boolean=false;
  @ViewChildren('contentSection') contentSections!: ElementRef[];
  @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];
  sectionHeight = 0;

  @HostListener('window:resize')
  onResize() {
    this.setContentSectionHeight();
  }

  setContentSectionHeight() {
    const windowHeight = window.innerHeight;
    let bodyWrapperHeight: any;
    if(this.bodyWrapper!=undefined){
      this.bodyWrapper.forEach(section =>{
        bodyWrapperHeight = windowHeight - section.nativeElement.getBoundingClientRect().top;
        
      });
      
    }
  if(this.contentSections!=undefined){
    this.contentSections.forEach(section => {
      const offsetTop = section.nativeElement.getBoundingClientRect().top;      
      const sectionHeight = windowHeight - Math.floor(offsetTop) - (window.innerWidth * 0.0052 * 4.8); //(2.5 * 48);           
      section.nativeElement.style.minHeight = `${Math.floor(sectionHeight)}px`;
    });
  }
  }

  constructor(private hipManagementService: Hip2ManagementService,private http: HttpClient,
    private route: ActivatedRoute, private router: Router, private errorHandleService: ErrorHandleService){
      let passedData:any={}; // Type is any as it will map input data with different structure from many pages i.e ds table , View Product 
      passedData=this.router.getCurrentNavigation()?.extras.state??{showSubscriptionLink:"",subscriptionName:""};
      let queryParams=this.route.snapshot.queryParams;
      this.breadCrumbApiName = queryParams['apiName']??"";
      this.breadCrumbProductId = queryParams['productId']??"";
      this.breadCrumbProductName = queryParams['productName']??""; 
      sessionStorage.setItem('productNameValue',this.breadCrumbProductId);
      sessionStorage.setItem('productDisplayNameValue', this.breadCrumbProductName);
      if(passedData.parentData != undefined){
        this.showSubscriptionBreadcrumb=passedData.parentData.showSubscriptionLink;
        this.breadCrumbSubscriptionName=passedData.parentData.subscriptionName
      }
  }

  
  ngOnInit(): void {    
    this.breadCrumbApiId = this.route.snapshot.paramMap.get('apiId') ?? "";
    this.setContentSectionHeight();
    this.generateSwaggerUI();    
  }

  generateSwaggerUI(){
    this.loaderActive = true;
    this.hipManagementService.getApiSpecification(this.breadCrumbApiId).subscribe(
        {          
          next: (response:any) => {                                    
            if(response?.length > 0) {
              var url = '';
              if(response.length == 1){
                this.btnProps = [{content:'Open API', classType:'primary', icon:'./assets/buttons/download-white.svg', isBreadCrumbClickEvent: true}]
                url=response[0].Url;
                this.yamlURL = response[0].Url;
                this.isExternal=response[0].IsExternal;
                this.internalAPIvalue=response[0].BaseURL;
                this.copyInternalMessage='Copy Base URL: ' +response[0].BaseURL+' to Clipboard';
                if(this.isExternal){
                       let externalURL=response[0]['BaseURL'].replace('.group', '.com')??"";
                       this.externalAPIValue=externalURL;
                       this.copyExternalMessage='Copy Base URL: ' +externalURL+' to Clipboard';
                }                 
              }              
              else if(response.length == 2){
                this.btnProps = [{content:'Open API', classType:'primary', icon:'./assets/buttons/download-white.svg', isBreadCrumbClickEvent: true}, {content:'Swagger', classType:'primary', icon:'./assets/buttons/download-white.svg', isBreadCrumbClickEvent: true }]                
                url=response[1].Url;
                this.yamlURL = response[0].Url;
                this.jsonURL = response[1].Url;
                this.isExternal=response[0].IsExternal;                
                this.internalAPIvalue=response[0].BaseURL;
                this.copyInternalMessage='Copy Base URL: ' +response[0].BaseURL+' to Clipboard';
                if(this.isExternal){
                  let externalURL=response[0]['BaseURL'].replace('.group', '.com')??"";
                  this.externalAPIValue=externalURL;
                  this.copyExternalMessage='Copy Base URL: ' +externalURL+' to Clipboard';
           }
              }              

              if(url!=''){
                var SwaggerUIBundle = require('swagger-ui-dist').SwaggerUIBundle
                const ui = SwaggerUIBundle({
                url: url,
                dom_id: '#api-definition-ui',
                layout: 'BaseLayout',
                deepLinking: true,
                presets: [
                  SwaggerUIBundle.presets.apis,
                  SwaggerUIBundle.SwaggerUIStandalonePreset
                ],
                docExpansion: 'list',
                operationsSorter: 'alpha',
                supportedSubmitMethods: []
               });
             }
             else {
              this.noDataFound = true;
             }
            }
            else{                            
              this.noDataFound = true;
            }            
            this.loaderActive = false;
            this.setContentSectionHeight();
          },
          error: (error: any) => {   
            this.loaderActive = false;     
            this.setContentSectionHeight();
            this.errorHandleService.handleError(error);    
          }
        });
  }

  downloadAPISpec(event:string){
    let filePath:string ='';
    let fileType:string='';
    if (event.toLowerCase() == 'open api') {
      filePath = this.yamlURL;
      fileType = '.yaml'      
    }
    else if (event.toLowerCase() == 'swagger') {
      filePath = this.jsonURL;
      fileType = '.json'
    }    

    this.getRawFile(filePath).subscribe(data => { 
      this.downloadFile(data,fileType);
     });
  }

  public getRawFile(filePath:string): Observable<any> {
    const skipheaders = new HttpHeaders().set(InterceptorSkipHeader, '');
    return this.http.get(filePath, { headers : skipheaders, responseType: 'text' });
  }

  downloadFile(data: BlobPart, fileType:string) {
    const blob = new Blob([data], { type: 'application/text', });    
    var exportFilename: string =  this.breadCrumbApiName + fileType;
    const win = window.navigator as any;
    if (win.msSaveBlob) {
      win.msSaveBlob(blob, exportFilename);
    } else {
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.setAttribute('download', exportFilename);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }


}
