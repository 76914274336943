import { FormControl, FormGroup } from '@angular/forms';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-text-field',
  templateUrl: './text-field.component.html',
  styleUrls: ['./text-field.component.scss']
})
export class TextFieldComponent implements OnInit {
  @Input() setValue: any = '';
  @Input() props: any = [];
  @Input() AutoFillOptions: any = [];
  @Input() appID: string = '';
  @Input() error: any = '';
  @Input() checkError: boolean = false;
  @Input() name: string ='';
  @Input() control: FormControl = new FormControl('');
  title: string = "";
  placeHolder: string = "";
  errorMsg: string = "";
  isError: boolean = false;
  content: string = '';
  value: string = '';

  ngOnInit(): void {
    this.title = this.props.heading;
    this.placeHolder = this.props.placeholder;
    this.errorMsg = this.props.errMsg; 
   this.checkError = this.checkError; 
   this.control.setValue(this.setValue.setContent);      
  }
  ngOnChanges() {
    
  }
}
