<div #bodyWrapper>
        <app-bread-crumb [links]="[
        {
          'href': '/publisher/manage-subscriptions',
          'title': 'Manage Subscriptions'
        },
        {
          'href': '/publisher/manage-subscriptions/'+this.breadCrumbProductId,
          'title': ''+this.breadCrumbProductName,
          'isQueryParamsReq': true,
          'queryparams': {'productName' : this.breadCrumbProductName}          
        },
        {
            'href': '/publisher/manage-subscriptions/'+this.breadCrumbSubscriptionName+'/'+this.breadCrumbProductId,
            'title': ''+this.breadCrumbSubscriptionDisplayName
        },
        {
          'href':  '/publisher/manage-subscriptions/view-subscription/'+this.breadCrumbSubscriptionName,   
            'title': 'Subscription Details'               
        }
      ]" [buttonProps]="buttonProps" 
        (breadCrumbClickEvent)="onEditClick($event)"></app-bread-crumb>      
      <section class="content-section"  #contentSection  [class.diable-edit]="isSubmitted">
    <app-general-loader *ngIf="loaderActive || pendingAPICallCount>0"></app-general-loader>
    <div class="form-section" [class.loading]="loaderActive">
        <div class="container form-head" [class.loading]="loaderActive">
            <h2 class="title">Subscription Details</h2>
            <p>Please find a detailed overview of all product subscription related information below.</p>
        </div>
        <div class="container" id="view" [class.loading]="loaderActive">
            <div class="row">
                <div class="col-12 col-sm">
                    <h3 class="title">Subscriber Name</h3>
                    <h3 class="bold title">{{this.dataset.SubscriberName}}</h3>
                </div>
            </div>
            <ng-container *ngIf="this.dataset.SubscriptionDisplayName">
            <div class="row">
                <div class="col-12 col-sm">
                    <h3 class="title">Subscription Name</h3>
                    <h3 class="title bold" style="display: flex;">{{this.dataset.SubscriptionDisplayName}}<app-copy-clipboard class="inherit-text" [copyTextValue]=this.dataset.SubscriptionDisplayName></app-copy-clipboard></h3>
                </div>
            </div>
          </ng-container>
            <ng-container *ngIf="this.dataset.SubscriptionKey">
            <div class="row">
                <div class="col-12 col-sm">
                    <h3 class="title" style="display: flex;">API Key</h3>
                    <h3 class="title bold" style="display: flex;"><app-copy-clipboard class="inherit-text" [copyTextValue]=this.dataset.SubscriptionKey> <span class="inherit-text" style="display: flex;"><app-text-mask [text]="this.dataset.SubscriptionKey" class="inherit-text"></app-text-mask></span></app-copy-clipboard></h3>
                </div>
            </div>
          </ng-container>

          <ng-container *ngIf="this.dataset.ProxyClientID && isStateActive">
            <div class="row">
                <div class="col-12 col-sm">
                    <h3 class="title">Client App Registration Id</h3>
                    <h3 class="title bold" style="display: flex;">{{this.dataset.ProxyClientID}} <a href="https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/{{this.dataset.ProxyClientID}}/isMSAApp~/false" target="_blank" style="display: flex;padding-left: 2rem;"><img src="../../../../assets/_target.svg" alt="" class="img-svg-wrapper"></a>
                      <app-copy-clipboard class="inherit-text" style="padding-left: .7rem;" [copyTextValue]=this.dataset.ProxyClientID></app-copy-clipboard></h3>
                </div>
            </div>
          </ng-container>
        
            <div class="row">
                <div class="col-12 col-sm-12 col-md-5">
                    <h3 class="title">State</h3>
                    <h3 class="title bold">{{this.dataset.State}}</h3>
                </div>  

                <div class="col-12 col-sm-12 col-md-5">
                    <h3 class="title">Usage Notes</h3>
                    <h3 class="title bold">{{this.dataset.UageNotes}}</h3>
                </div>  
                <div class="col-12 col-sm-12 col-md-5">
                    <h3 class="title">Remarks</h3>
                    <h3 class="title bold">{{this.dataset.Remarks}}</h3>
                </div>  
            </div>
     
          </div>

                <div class="container subscriptionTable" [class.loading]="loaderActive">
                    <div class="col-12 col-sm-12 col-md-5" [class.loading]="loaderActive">
                        <h3 class="title">Product Name</h3>
                        <h2 class="title">{{this.dataset.ProductName}}</h2>
                    </div> 
                            <div class="table-wrapper">
                              <table>
                                <tr>
                                    <th></th>
                                    <th>API Name</th>
                                    <th>API Owner</th>
                                </tr>             
                            <tbody>
                                <ng-container *ngFor="let api of this.dataset.apiData; let i = index">
                                    <tr>
                                        <td>
                                            <span *ngIf="api.subscriptionAttributesData.length>0" class="caret" [class.isExpanded]="api.isExpanded" (click)="api.isExpanded = !api.isExpanded">                      
                                            <svg class="bs-caret-right" width="5" height="10" viewBox="0 0 5 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M-5.27643e-08 1.20711C-3.3293e-08 0.761654 0.538571 0.538571 0.853553 0.853553L4.64645 4.64645C4.84171 4.84171 4.84171 5.15829 4.64645 5.35355L0.853552 9.14645C0.53857 9.46143 -4.03821e-07 9.23835 -3.8435e-07 8.79289L-5.27643e-08 1.20711Z" fill="#0078A1"/>
                                            </svg>
                                            </span>
                                        </td>
                                        <td><a [routerLink]="'/publisher/view-api/'+api.apimApiName" class="blue-content" (click)="onViewApiClick(api.apimApiName, api.name)">{{api.name}}</a></td>
                                        <td class="bold-content">{{api.apiOwner | customCapitalizePipe}}</td>
                                    </tr>
                                    <tr
                                    class="toggle-row">
                                    <td colspan="10" *ngIf="api.isExpanded">
                                    <div>
                                        <table>
                                            <ng-container *ngIf="api.subscriptionAttributesData.length>0;else noDataFound">
                                                <tr>
                                                    <th class="rel-comp-wrapper">Name
                                                            <span class="info ds_tooltip">
                                                                <svg
                                                                  width="16"
                                                                  height="16"
                                                                  viewBox="0 0 16 16"
                                                                  fill="#7D8083"
                                                                  xmlns="http://www.w3.org/2000/svg"
                                                                >
                                                                  <path
                                                                    fill-rule="evenodd"
                                                                    clip-rule="evenodd"
                                                                    d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.9819 7.08343C7.09348 6.79662 6.94991 6.6532 6.82224 6.6532C6.23256 6.6532 5.4667 8.04042 5.18031 8.04042C5.06826 8.04042 4.97264 7.92847 4.97264 7.83288C4.97264 7.54583 5.67417 6.87628 5.88186 6.66892C6.51937 6.06302 7.34882 5.60059 8.27374 5.60059C8.95907 5.60059 9.69287 6.01501 9.11883 7.56216L7.9705 10.6712C7.87524 10.9104 7.6998 11.3094 7.6998 11.5644C7.6998 11.6759 7.76319 11.7878 7.89078 11.7878C8.36892 11.7878 9.24635 10.4322 9.46969 10.4322C9.54925 10.4322 9.66075 10.5277 9.66075 10.6712C9.66075 11.1336 7.79532 13.1111 6.1846 13.1111C5.61051 13.1111 5.21194 12.8403 5.21194 12.2342C5.21194 11.469 5.75398 10.1613 5.86611 9.88999L6.9819 7.08343ZM7.84286 3.94212C7.84286 3.24057 8.44868 2.66667 9.15041 2.66667C9.78865 2.66667 10.2508 3.09692 10.2508 3.75091C10.2508 4.48471 9.6451 5.02691 8.92746 5.02691C8.27376 5.02691 7.84286 4.59609 7.84286 3.94212Z"
                                                                  />
                                                                </svg>
                                                                <span class="tooltiptext"
                                                                  >{{subscriptionAttributeToolTip}}</span>
                                                                  </span>
                                                    </th>
                                                    <th>Value</th>
                                                    <th>Description</th>
                                                    <th>Status</th>
                                                </tr>             
                                            <tbody>
                                                <ng-container *ngFor="let attribute of api.subscriptionAttributesData let i = index">
                                                    <tr class="row-wrapper">
                                                        <td class="bold-content">{{attribute.displayName}}</td>
                                                        <td class="bold-content" *ngIf="!enableEdit">{{attribute.value}}</td>
                                                        <td class="bold-content" *ngIf="enableEdit">
                                                            <input class="text-field form-control" [class.errorActiveAlign]="checkValidation && attribute.value.length<=0"  [class.errorActive]="checkValidation && attribute.value.length<=0" type="text" placeholder="Enter" value="{{attribute.value}}" (change)="onSubscriptionAttributeValueChange(attribute.displayName,attribute.id,attribute.apimApiId,$event)"/>
                                                            <p class="error-text" [class.errorActive]="checkValidation && attribute.value.length<=0" *ngIf="checkValidation && attribute.value.length<=0">
                                                                <img src="./assets/textbox/Error-icon.svg" width="16" height="16" />
                                                                <span>Please enter a value</span>
                                                              </p>
                                                        </td>
                                                        <td >{{attribute.description}}</td>
                                                        <td >{{attribute.status  }}</td>
                                                    </tr>
                                                </ng-container>
                                            </tbody>
                                            </ng-container>
                                        <ng-template #noDataFound>
                                          <tr class="not-found"><td>No Subscription Attributes Found</td></tr>
                                        </ng-template>
                                        </table>
                                        </div>
                                        </td>
                                        </tr>
                                </ng-container>
                            </tbody>
                        </table>
                            </div>                
                </div>
                    <div class="col-sm buttons-align-end button-wrapper" style="padding-top: 3rem;">
                      <div class="feedbackMsg" [class.feedbackActive]="this.feedbackActive">
                        <ng-container *ngIf="successFull" >
                          <span class="successColor">{{successMsg}}</span>
                          <img src="./assets/successIcon.svg"/>
                        </ng-container>
                        <ng-container *ngIf="failure" >
                          <span class="failureColor">{{failureMsg}}</span>
                          <img class="error" src="../../../../assets/Health-monitor/Unhealthy.svg"/>
                        </ng-container>
                        
                      </div>
                    <app-buttons
                    [props]="{
                      icon: '',
                      content: 'Cancel',
                      href: '#',
                      classType: 'tertiary',
                      type:'button'
                    }"  (click)="onEditCancel()"
                    *ngIf="!isSubmitted && enableEdit"                   
                  ></app-buttons>
                    <app-buttons
                      [props]="{
                        icon: '',
                        content: 'Save',
                        href: '#',
                        classType: 'primary',
                        type:'submit',
                        isBreadCrumbClickEvent:true
                      }" 
                      (btnEmitedClickEvent)="onSubmit()"
                      *ngIf="!isSubmitted && enableEdit"                  
                    ></app-buttons></div>
    </div>
        <div class="info-section container">
            <app-information-section [information]="pageInfo" [detailsInfo]="this.detailsInfo"></app-information-section>
          </div>
   
      </section>
</div>
<app-alert *ngIf="showSuccess" [success]="true">
    <div class="success_Icon"><img src="../../../../assets/successIcon.svg"/><p>{{successMsg}}</p></div>   
  </app-alert>
  <app-alert *ngIf="showFailure" [failure]="true">
    <div class="unSuccess_Icon"><img src="../../../../assets/Health-monitor/Unhealthy.svg"/><p>{{failureMsg}}</p></div>   
  </app-alert>