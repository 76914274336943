import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-information-section',
  templateUrl: './information-section.component.html',
  styleUrls: ['./information-section.component.scss']
})
export class InformationSectionComponent implements OnInit  {
  details: any = [];
  @Input() detailsInfo : any = []
  @Input() information: string = 'No information panel text available at the moment';
  constructor(){}
ngOnInit(): void {
this.details = this.detailsInfo;
}

ngOnChanges(): void {
  this.details = this.detailsInfo;
}

}
