<div #bodyWrapper>
  <app-bread-crumb  [links]="[
  {
    'href': '/publisher/manage-products',
    'title': 'Manage Products',
    'description': 'An overview of your Products as a publisher. '
  }
]" [buttonProps]="[{content:'Add Product', classType:'primary', icon:'./assets/buttons/addIcon.svg', routePath: '/publisher/manage-products/add-product' }]"></app-bread-crumb>
<section class="content-section container"  #contentSection>
    <!-- <app-table></app-table>
    <app-accordion-table></app-accordion-table> -->
    <div class="ds_c-listing-table">
      <div class="ds_c-listing-table-filter col-sm-12">
        <div class="navbar col-12 col-sm-12 col-md-12 col-lg-7 col-xl-7">
          <span class="col-12 col-sm-12 col-md-4" style="position: relative">
            <input
              #searchProductField
              type="text"
              class="text-field-responsive form-control"
              name="searchProductValue"
              [ngModel]="searchProductValue"
              (ngModelChange)="SearchProduct($event)"
              placeholder="Search with at least 3 characters"
              (keydown.enter)="searchProductField.blur()"
            />
            
            <span class="search-icon-placement" *ngIf="searchProductField.value.length==0"></span>
            <span *ngIf="searchProductField.value.length > 0" class="removeBtn-search" (click)="clearSearch()" ></span>
          </span>
          <span [formGroup]="filterForm" class="col-12 col-sm-12 col-md-7 filter-placement">
              <app-multi-select-dropdown class="multi-select" [props]="{placeholder: 'Filter on business area'}" [control]="businessFilter" [dataSets]="businessAreaList" [isSearchEnabled]="isSearchBusinessFilterEnabled"></app-multi-select-dropdown>
          </span>
          
        
        </div>
        <span class="col-12 col-sm-12 col-md-12 col-lg-5 col-xl-5 pageControls">
          <span class="ds_c-renderCount">
            <span>{{totalRecordCount === 0? '00':(((this.pageNo-1) * (itemsPerPage) + 1) === 1) ? '01' : ((this.pageNo-1) * (itemsPerPage) + 1)}} - {{((itemsPerPage) * (this.pageNo) > (this.totalRecordCount)) ? this.totalRecordCount : (itemsPerPage) * (this.pageNo)}} of {{this.totalRecordCount}}</span>
            <app-select-drpdwn [Options]="[10, 20, 30]" [perPageRender]="this.itemsPerPage" (perPageValue)="renderPageValue($event)"></app-select-drpdwn>
          </span>
          <pagination-controls class=" ds_c-pagination" (pageChange)="onTablePageChange($event)"  [maxSize]="5"  (responsive)="true"  previousLabel=""
          nextLabel=""></pagination-controls>
        </span>
      </div>
      <div style="overflow-x: auto;">
        <app-shimmer-table *ngIf="this.loaderEnabled == true"></app-shimmer-table>
      </div>       
       <app-ds-table *ngIf="this.loaderEnabled == false" [HeadArray]="productTableHeaderList" [GridArray]="gridArray" [DataKeys]="dataKeys" [itemsPerPage]="itemsPerPage" [pageNo]="pageNo" [sorting]="sortingHeaderList" [tableDetailLink]="true" [totalRecords]="totalRecordCount" [tableInfo]="tableInfo" [childDataInfo]="childTableInfo" (tableExpand)="apiListData($event)"></app-ds-table>
    </div>
    <span class="col-12 footer-pageControls">
      <span class="ds_c-renderCount">
        <span>{{totalRecordCount === 0? '00':(((this.pageNo-1) * (itemsPerPage) + 1) === 1) ? '01' : ((this.pageNo-1) * (itemsPerPage) + 1)}} - {{((itemsPerPage) * (this.pageNo) > (this.totalRecordCount)) ? this.totalRecordCount : (itemsPerPage) * (this.pageNo)}} of {{this.totalRecordCount}}</span>
        <app-select-drpdwn [Options]="[10, 20, 30]" [perPageRender]="this.itemsPerPage" (perPageValue)="renderPageValue($event)"></app-select-drpdwn>
      </span>
      <pagination-controls class=" ds_c-pagination" (pageChange)="onTablePageChange($event)"  [maxSize]="5"  (responsive)="true"  previousLabel=""
      nextLabel=""></pagination-controls>
    </span>
  </section>
  <!-- <footer>
    <app-footer></app-footer>
  </footer> -->
  <!-- <app-click-to-top></app-click-to-top> -->
</div>

<!-- Need to use the modal
  <app-buttons
[props]="{
  icon: '',
  content: 'open Modal',
  href: '#',
  classType: 'primary',
  type:'submit',
  isBreadCrumbClickEvent:true
}" 
(btnEmitedClickEvent)="openModal()"
></app-buttons>

<app-pop-ups [props]="{
  'content': 'Are you sure you want to delete the item from the list.',
  'heading': 'Delete',
  'icon': '../assets/buttons/deleteIcon.svg'
}"></app-pop-ups> -->