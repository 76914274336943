<div #bodyWrapper>
    <app-bread-crumb [links]="[
    {
        'href': '/developer/manage-subscriptions',
        'title': 'Manage Subscriptions'
    },
    {'href': '/developer/manage-subscriptions/'+this.breadcrumbAppId,
    'title': this.breadCrumbAppName,
    isQueryParamsReq: true,
    'queryparams': {'appName' : this.breadCrumbAppName}  
    },
    {
        'href': '/developer/view-product/'+this.breadCrumbProductId,
        'title': this.breadCrumbProductName               
    },
    {
        'title': this.breadCrumbSubscriptionDisplayName               
    },
    {
        'title': 'Subscription Details'               
    }
  ]" [buttonProps]="[]" ></app-bread-crumb>
  <section class="content-section"  #contentSection>
<app-general-loader *ngIf="loaderActive"></app-general-loader>
<div class="form-section" [class.loading]="loaderActive">
    <div class="container form-head" [class.loading]="loaderActive">
        <h2 class="title">Subscription Details</h2>
        <p>Please find a detailed overview of all product subscription related information below.</p>
    </div>
    <div class="container" id="view" [class.loading]="loaderActive">
        <div class="row">
            <div class="col-12 col-sm">
                <h3 class="title">Subscriber Name</h3>
                <h3 class="bold title">{{this.dataset.subscriberName}}</h3>
            </div>
        </div>
        <ng-container *ngIf="this.dataset.subscriptionDisplayName">
            <div class="row">
                <div class="col-12 col-sm">
                    <h3 class="title">Subscription Name</h3>
                    <h3 class="title bold" style="display: flex;">{{this.dataset.subscriptionDisplayName}}<app-copy-clipboard class="inherit-text" [copyTextValue]=this.dataset.subscriptionDisplayName></app-copy-clipboard></h3>
                </div>
            </div>
          </ng-container>
            <ng-container *ngIf="isStateActive">
            <div class="row">
                <div class="col-12 col-sm">
                    <h3 class="title">API Key</h3>
                    <h3 class="title bold"><app-copy-clipboard class="inherit-text" [copyTextValue]=this.dataset.subscriptionKey> <span class="inherit-text"><app-text-mask [text]="this.dataset.subscriptionKey" class="inherit-text"></app-text-mask></span></app-copy-clipboard></h3>
                </div>
                <div class="col-12 col-sm">
                    <h3 class="title">Product App Registration Id</h3>
                    <h3 class="title bold" style="display: flex;">{{this.dataset.productAppregistrationID}}<a href="https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/{{this.dataset.productAppregistrationID}}/isMSAApp~/false" target="_blank" style="display: flex;padding-left: 2rem;"><img src="../../../../assets/_target.svg" alt="" class="img-svg-wrapper"></a>
                        <app-copy-clipboard class="inherit-text icon-warpper col-12 col-sm" [copyTextValue]=this.dataset.productAppregistrationID></app-copy-clipboard></h3>
                </div>
            </div>
          </ng-container>

          <ng-container *ngIf="this.dataset.proxyClientID && isStateActive">
            <div class="row">
                <div class="col-12 col-sm">
                    <h3 class="title">Client App Registration Id</h3>
                    <h3 class="title bold" style="display: flex;">{{this.dataset.proxyClientID}}<a href="https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationMenuBlade/~/Overview/appId/{{this.dataset.proxyClientID}}/isMSAApp~/false" target="_blank" style="display: flex;padding-left: 2rem;"><img src="../../../../assets/_target.svg" alt="" class="img-svg-wrapper"></a>
                        <app-copy-clipboard class="inherit-text" style="padding-left: .7rem;" [copyTextValue]=this.dataset.proxyClientID></app-copy-clipboard></h3>
                </div>
            </div>
          </ng-container>
        <div class="row">  
            <div class="col-12 col-sm-12 col-md-5">
                <h3 class="title">State</h3>
                <h3 class="title bold">{{this.dataset.state}}</h3>
            </div>  
            <div class="col-12 col-sm-12 col-md-5">
                <h3 class="title">Usage Notes</h3>
                <h3 class="title bold">{{this.dataset.userNotes}}</h3>
            </div>  
            <div class="col-12 col-sm-12 col-md-5">
                <h3 class="title">Remarks</h3>
                <h2 class="title">{{this.dataset.remarks}}</h2>
            </div>
            <div class="col-12 col-sm-12 col-md-12">
                <h3 class="title">Approvers</h3>
                <app-render-control-values [value]="this.approverList" [type]="'tag'"></app-render-control-values>
            </div>
        </div>
</div>
            <div class="container subscriptionTable" [class.loading]="loaderActive">
                <div class="col-12 col-sm-12 col-md-5" [class.loading]="loaderActive">
                    <h3 class="title">Product Name</h3>
                    <h2 class="title">{{this.dataset.productName}}</h2>
                </div>
                        <div class="table-wrapper">
                            <table>
                                    <tr>
                                        <th>API Name</th>
                                        <th>API Owner</th>
                                    </tr>             
                                <tbody>
                                    <ng-container *ngFor="let api of this.dataset.apiData; let i = index">
                                        <tr>
                                            <td><a [routerLink]="'/developer/api-definition/'+ api.apimApiName" class="blue-content" [queryParams]="{'apiName': api.name, 'productId':this.breadCrumbProductId, 'productName':this.breadCrumbProductName}" [state]="{parentData: {showSubscriptionLink: true, subscriptionName:this.breadCrumbSubscriptionName}}">{{api.name}}</a></td>
                                            <td class="bold-content">{{api.apiOwner}}</td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>                
            </div> 
 
</div>
    <div class="info-section container">
        <app-information-section [information]="pageInfo" [detailsInfo]="this.detailsInfo"></app-information-section>
      </div>

  </section>
</div>