<div #bodyWrapper>
    <app-bread-crumb *ngIf="showSubscriptionBreadcrumb==false" [links]="[
    {
      'href': '/developer/all-products',
      'title': 'Products & APIs'
    },
    {
      'href': '/developer/view-product/'+this.breadCrumbProductId,
      'title': ' '+this.breadCrumbProductName
    },
    {
      'title': ' '+this.breadCrumbApiName
    },
    {
      'title': 'API Details'           
    }
    ]" [buttonProps]="btnProps"      
    (breadCrumbClickEvent)="downloadAPISpec($event)"></app-bread-crumb>
    <app-bread-crumb *ngIf="showSubscriptionBreadcrumb==true" [links]="[
    {
      'href': '/developer/all-products',
      'title': 'Products'
    },
    {
      'href': '/developer/view-product/'+this.breadCrumbProductId,
      'title': ' '+this.breadCrumbProductName
    },
    {
      'href':  '/developer/view-my-subscription/'+this.breadCrumbSubscriptionName+'/'+this.breadCrumbProductId,   
      'title': ' '+this.breadCrumbSubscriptionName
    },
    {
      'title': ' '+this.breadCrumbApiName
    },
    {
      'title': 'API Details'           
    }
    ]" [buttonProps]="btnProps"      
    (breadCrumbClickEvent)="downloadAPISpec($event)">
    </app-bread-crumb>       
    <app-general-loader *ngIf="loaderActive"></app-general-loader>
    <section class="content-section" #contentSection>
  <div class="api-definition-wrapper">
      <div class="head-section">
          <div class="head-content">            
              <h2 class="title">API Details</h2>
              <div class="row">
              <p class="col-12 col-sm">Please find the Open API specification below with all available endpoints and operations.</p>
              <span class="button-wrapper col-12 col-sm" *ngIf="!loaderActive">
                <app-label [label]="internalApi" [copyMessage]="copyInternalMessage" [copyTextValue]="internalAPIvalue" ></app-label>
                <app-label [label]="externalApi" [copyMessage]="copyExternalMessage" [copyTextValue]="externalAPIValue" *ngIf="isExternal"></app-label>
                </span>  
                </div>          
          </div>
      </div>    
      <br/>
      <br/>  
      <div id="api-definition-ui" style="margin-top: -2.0rem;"></div>
      <div *ngIf="!loaderActive && noDataFound"><h2 class="title"> No Open API specification available </h2></div>
  </div>
  </section>

</div>