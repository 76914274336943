<div #bodyWrapper class="section">
    <div class="not-found">
        <h1>Sorry, the page you are looking for</h1>
        <p class="not-found-subtitle">could not be found.</p>
        <h2>404</h2>
        <p class="red-quotes">" <span class="page-not-found">Page Not Found </span> "</p>
        <svg class="not-found-svg" xmlns="http://www.w3.org/2000/svg" width="94" height="100" viewBox="0 0 94 100" fill="none">
          <path d="M3.97549 3.98678V39.9695V39.6758V89.3243V3.98678ZM18.1043 53.2399H39.3935C39.9469 52.5058 40.5116 51.8056 41.0989 51.1393C41.6862 50.4729 42.3412 49.8517 43.064 49.2532H18.093V53.2399H18.1043ZM18.1043 74.5743H34.7404C34.6839 73.9418 34.6161 73.2755 34.5597 72.5753C34.4919 71.8751 34.4693 71.2087 34.4919 70.5762H18.1156V74.563L18.1043 74.5743ZM7.17169 93.3224C5.18395 93.3224 3.48985 92.6221 2.08939 91.233C0.688934 89.8325 0 88.1384 0 86.1507V7.18299C0 5.19524 0.700228 3.50114 2.08939 2.10068C3.47855 0.700228 5.17265 0 7.17169 0H50.4729L72.5753 22.1024V40.9634C71.9654 40.7601 71.3103 40.5681 70.6101 40.4099C69.9099 40.2518 69.2323 40.0937 68.5885 39.9695V23.9094H48.4739V3.98678H7.17169C6.36982 3.98678 5.647 4.31431 4.98066 4.98066C4.31431 5.647 3.98678 6.38111 3.98678 7.17169V86.1394C3.98678 86.9412 4.31431 87.6641 4.98066 88.3304C5.647 88.9968 6.38111 89.3243 7.17169 89.3243H41.5168C42.3864 90.2052 43.2899 90.9619 44.2386 91.5944C45.1873 92.2268 46.2264 92.8028 47.3332 93.3111H7.1604L7.17169 93.3224ZM64.805 85.1455C69.0628 85.1455 72.6543 83.6886 75.5795 80.7634C78.5046 77.8383 79.9615 74.2468 79.9615 69.989C79.9615 65.7311 78.5046 62.1396 75.5795 59.2145C72.6543 56.2893 69.0628 54.8324 64.805 54.8324C60.5472 54.8324 56.9557 56.2893 54.0305 59.2145C51.1054 62.1396 49.6484 65.7311 49.6484 69.989C49.6484 74.2468 51.1054 77.8383 54.0305 80.7634C56.9557 83.6886 60.5472 85.1455 64.805 85.1455ZM91.2443 99.184L76.867 84.818C75.1842 86.162 73.332 87.2123 71.3103 87.9803C69.2774 88.7483 67.109 89.1323 64.805 89.1323C59.4855 89.1323 54.9679 87.2688 51.2409 83.5418C47.5139 79.8147 45.6617 75.2858 45.6617 69.9664C45.6617 64.6469 47.5252 60.1293 51.2522 56.4136C54.9792 52.6978 59.5081 50.8456 64.8276 50.8456C70.1471 50.8456 74.6647 52.7091 78.3804 56.4248C82.0961 60.1519 83.9483 64.6695 83.9483 69.989C83.9483 72.3042 83.5643 74.4727 82.7963 76.4943C82.0284 78.5272 80.978 80.3794 79.634 82.0509L94 96.4282L91.2443 99.184Z" fill="#5A5D60"/>
        </svg>
        <p class="homePage">Please, click the "Home" button to return to the home page.</p>
        <app-buttons 
                        [props]="{
                          icon: '',
                          content: 'Home',
                          href: '#',
                          classType: 'primary',
                          type:'button'
                        }" (click)="homePage('/developer/all-products')"
                      ></app-buttons>
    </div>
    </div>
    