import { FormGroup, FormControl,  } from '@angular/forms';
import { Component, ViewChildren, ElementRef, HostListener, OnDestroy } from '@angular/core';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { MySubscriptionDetails } from '../models/mySubscription';
import { Subject, Subscription, debounceTime, timer } from 'rxjs';
import { DateFormatPipe } from 'src/app/pipes/date-format-pipe.pipe';
import { MySubscriptionList } from '../models/mySubscription-list';
import { ModalService } from 'src/app/shared/services/modal.service';
import { SubscriptionCancelRequest} from '../models/subscription-cancel';
import { SubscriptionReactivateRequest } from '../models/subscription-reactivate';
import { DeleteSubscriptionRequest } from '../models/subscription-delete';
import { DatePipe } from '@angular/common';
import { Environment } from 'src/environments/environment';
import * as XLSX from 'xlsx';


@Component({
  selector: 'app-my-subscription',
  templateUrl: './my-subscriptions.component.html',
  styleUrls: ['./my-subscriptions.component.scss'],
  providers: [DateFormatPipe]
})
export class MySubscriptionsComponent implements OnDestroy{
 loaderEnabled: boolean = true;
 searchSubscriptionvalue: string = ''; 
 gridArray: any[]=[]; // type of gridArray is any as we need to pass that to generic table component
 selectedBusinessvalue: string[] = []; // for maintaining state of business Area filters
 selectedStateValue: string[]=[];
 dataKeys : string[]=[];
 pageNo: number = 1;
 itemsPerPage : number = 10;
 filterForm: FormGroup = new FormGroup({});
 stateFilterForm: FormGroup = new FormGroup({});
 businessFilter: FormControl = new FormControl('');
 stateFilter: FormControl = new FormControl('');
 totalRecordCount:number =0;
 isSearchBusinessFilterEnabled:boolean = false;
 tableInfo:string="subscriptions"
 enableColonDateFormat:boolean=false;
 selectedSubscriptionName:string='';
 selectedSubscriptionDisplayName:string='';
 cancelSubscriptionMessage:string='';
 reactivateSubscriptionMessage:string='';
 deleteSubscriptionMessage:string='';
 selectedProductDetail:any={};
 showSuccess:boolean = false;
 showFailure:boolean = false;
 successMsg:string ='';
 failureMsg:string ='';
 subscriptionTableHeaderList: string [] =[
  "Subscription Name",
  "Application Number",
  "Application Name",
  "Business Area",
  "Application Owner",
  "State",
  "Date Requested",
  "Date Activated",
  "Actions"
];

productDetailHeaderList: string[]=[
  "Product Name",
  "Product Owner",
  "Product Description"
]

businessAreaList =[
  {
'id': '1',
'label': 'CT'
},
{
'id': '2',
'label': 'CO'
},
{
'id': '3',
'label': 'IT'
},
{
'id': '4',
'label': 'PT'
},
{
'id': '5',
'label': 'VT'
}
];
stateList =[
  {
'id': '1',
'label': 'Active',
'value': 'active'
},
{
'id': '2',
'label': 'Pending Approval',
'value': 'submitted'
},
{
'id': '3',
'label': 'Rejected',
'value': 'rejected'
},
{
  'id': '4',
  'label': 'Cancelled',
  'value': 'cancelled'
}
];

sortingHeaderList=[{head: 'Subscription Name', key: 'subscriptionName'}
,{head: 'Application Number', key: 'applicationId'},{head: 'Application Name', key: 'applicationName'},{head: 'Business Area', key: 'businessAreaCode'},{head: 'Application Owner', key: 'applicationOwner'},
{head: 'State', key: 'state'},{head: 'Date Requested', key: 'requestedDate'},{head: 'Date Activated', key: 'activatedDate'}]

searchAPISubscription!: Subscription;
searchSubscriptionValueChanged:Subject<string> = new Subject<string>();
btnProps = [{content:'Export', classType:'primary', icon:'./assets/buttons/download-white.svg', isBreadCrumbClickEvent: true}];
showDownloadEmpty:boolean=false;
 @ViewChildren('contentSection') contentSections!: ElementRef[];
 @ViewChildren('bodyWrapper') bodyWrapper!: ElementRef[];
sectionHeight = 0;
// listen for window resize events
@HostListener('window:resize')
onResize() {
 this.setContentSectionHeight();
}
setContentSectionHeight() {
 const windowHeight = window.innerHeight;
if(this.contentSections!=undefined){
  this.contentSections.forEach(section => {
    const offsetTop = section.nativeElement.getBoundingClientRect().top;      
    const sectionHeight = windowHeight - Math.floor(offsetTop) - (window.innerWidth * 0.0052 * 4.8);
    //0.0052 is the constant value which will be used as dynamic value changing for font-size and 4.8 is the height of footer in rem  
    section.nativeElement.style.minHeight = `${Math.floor(sectionHeight)}px`;
  });
  }
}

 constructor(private hipManagementService:Hip2ManagementService, private dateFormatPipe: DateFormatPipe, private modal: ModalService) { }
  ngOnDestroy(): void {
    this.modal.unRegister('cancel_subscription');
    this.modal.unRegister('reactivate_subscription');
    this.modal.unRegister('delete_subscription');
  }

 public renderPageValue(value: number) {
   this.itemsPerPage = value;
   this.pageNo = 1;
   this.loadSubscriptionList()
  }

  ngOnInit(): void {
   this.loadSubscriptionList();
   this.filterForm = new FormGroup({
     filter: this.businessFilter
   });
   this.stateFilterForm = new FormGroup({
    filter: this.stateFilter
  });
  this.modal.register('cancel_subscription');
  this.modal.register('reactivate_subscription');
  this.modal.register('delete_subscription');
   this.controlDataChange()   
 }
 ngAfterContentInit(): void {
   this.searchAPISubscription = this.searchSubscriptionValueChanged.pipe(debounceTime(1500)).subscribe(value=>{
    this.searchSubscriptionvalue=value;
    if(this.searchSubscriptionvalue.length>2 || this.searchSubscriptionvalue.length==0){
      this.pageNo=1;
      this.loadSubscriptionList();    
      this.setContentSectionHeight();  
        }   
   })
   this.setContentSectionHeight();
 }
 controlDataChange(){
   this.businessFilter.valueChanges.pipe((debounceTime(2000))).subscribe((businessFilterObject) => {
     let businessValue = businessFilterObject.map((obj:any)=>{return obj.label});     
    this.selectedBusinessvalue = businessValue;
    this.pageNo=1;
    this.loadSubscriptionList();    
   });   
   this.stateFilter.valueChanges.pipe((debounceTime(2000))).subscribe((stateFilterObject)=>{
    let stateValue = stateFilterObject.map((obj:any)=>{return obj.value});
    this.selectedStateValue = stateValue;
    this.pageNo=1;
    this.loadSubscriptionList();
  })
 }

 onTablePage(event: any){
   this.pageNo = event;
   this.loadSubscriptionList()
 }
 loadSubscriptionList(){
  let filter= (this.searchSubscriptionvalue.trimStart()).trimEnd();
  let sortDirection = 'asc';
  let pageIndex = this.pageNo;
  let pageSize = this.itemsPerPage;
  let businessAreaCode = '';
  let stateCode = '';
  //get the selected business code
  this.selectedBusinessvalue.forEach(element => {
    businessAreaCode=businessAreaCode.length==0?element:(businessAreaCode+','+element)
  });  
  
  this.selectedStateValue.forEach(element => {
    stateCode=stateCode.length==0?element:(stateCode+','+element)
  });

  this.loaderEnabled=true;
  this.hipManagementService.getSubscriptionsbyUserEmail(filter, sortDirection, pageIndex, pageSize,businessAreaCode,stateCode).subscribe({
    next:(subscriptionData: MySubscriptionList) =>{
      this.gridArray=[];
      subscriptionData.subscriptionDetailsList?.forEach(subscriptionDetails => {
        let productInformation ={
        "productId": subscriptionDetails.apimProductId,
        "productName": subscriptionDetails.productName,
        "owner":subscriptionDetails.productOwner,
        "description": subscriptionDetails.description
        }
        let childTableData=[productInformation];
        let subscription={
          "uniqueId":subscriptionDetails.subscriptionName,
          "subscriptionName":subscriptionDetails.subscriptionDisplayName, 
          "applicationId":subscriptionDetails.applicationId,
          "applicationName":subscriptionDetails.applicationName,
          "businessAreaCode":subscriptionDetails.businessAreaCode,
          "applicationOwner":subscriptionDetails.applicationOwner,
          "state":subscriptionDetails.status=="submitted"?"Pending Approval":((subscriptionDetails.status.charAt(0)).toUpperCase())+(subscriptionDetails.status).slice(1),
          "requestedDate":subscriptionDetails.createdOn!=undefined?this.dateFormatPipe.transform(subscriptionDetails.createdOn,this.enableColonDateFormat):"  - | -",
          "activatedDate":subscriptionDetails.activatedDate!=undefined?this.dateFormatPipe.transform(subscriptionDetails.activatedDate,this.enableColonDateFormat):"  - | -",
          "Action":  (subscriptionDetails.status.toLowerCase())=="active"?
                  { 
                    "info": "/developer/view-my-subscription/"+subscriptionDetails.subscriptionName+"/"+subscriptionDetails.apimProductId,
                    "cancel": "/developer/my-subscriptions"
                }:(subscriptionDetails.status.toLowerCase())=="cancelled"?
                {"info": "/developer/view-my-subscription/"+subscriptionDetails.subscriptionName+"/"+subscriptionDetails.apimProductId,
                  "reactivate":'/developer/my-subscriptions',
                  "delete":'/developer/my-subscriptions'
                }:
                {"info": "/developer/view-my-subscription/"+subscriptionDetails.subscriptionName+"/"+subscriptionDetails.apimProductId},
           "detail":{
            "headings":this.productDetailHeaderList,
            "tableData":childTableData
           }     
        }
        this.gridArray.push(subscription);
      });
      if(this.gridArray.length>0){
        this.dataKeys = Object.keys(this.gridArray[0]);
        this.dataKeys.splice(this.dataKeys.indexOf("detail",1))
      }
      this.loaderEnabled=false;
      this.totalRecordCount=subscriptionData.totalCount;
      this.resetScroll();
      this.setContentSectionHeight();
    },
    error: (err: string) => {
      this.gridArray=[];
      console.log("Error response " + err);this.loaderEnabled=false;
      this.setContentSectionHeight();
  }
  })
 }

 subscriptionSearch(subscriptionValue: string){
  this.searchSubscriptionValueChanged.next(subscriptionValue)
 }

 onCancelSubscriptionModal(data:any){
    this.selectedSubscriptionName=data?.uniqueId??'';
    this.selectedSubscriptionDisplayName=data?.subscriptionName;
    let productDetail=data?.detail?.tableData[0]??'';
    this.selectedProductDetail=productDetail;
    this.cancelSubscriptionMessage=`You are about to cancel the following subscription:<br><em>`+this.selectedSubscriptionDisplayName+`</em><br>Do you want to proceed?&nbsp`
    this.modal.toggleModal('cancel_subscription')
 }
 onCancelSubscription(event:any){
   if(event==true){
    this.loaderEnabled=true;
    let cancelSubscriptionRequest:SubscriptionCancelRequest ={
      subscriptionName: this.selectedSubscriptionName,
      productId: this.selectedProductDetail.productId
    }
    this.hipManagementService.cancelSubscription(cancelSubscriptionRequest).subscribe(
      {
        next:(response:any)=> {
          this.loaderEnabled=false;
          this.showFailure=false;
          this.successMsg=this.selectedSubscriptionDisplayName+' subscription cancelled successfully';
          this.showSuccess=true;
          this.loadSubscriptionList();
          timer(5000).subscribe(x => this.showSuccess = false);
        },
        error:(err:any)=> {
          console.log(err);
          this.loaderEnabled=false;
          this.showSuccess=false;
          this.failureMsg = this.selectedSubscriptionDisplayName+ ' subscription cancel failed with exception: '+err;
          this.showFailure=true;
          timer(5000).subscribe(x => this.showFailure = false);        
        },
      }
    )
   }
 }

 onReactivateSubscriptionModal(data:any){
  this.selectedSubscriptionName=data?.uniqueId??'';
  this.selectedSubscriptionDisplayName=data?.subscriptionName;
  let productDetail=data?.detail?.tableData[0]??'';
  this.selectedProductDetail=productDetail;
  this.reactivateSubscriptionMessage=`You are about to reactivate the following subscription:<br><em>`+this.selectedSubscriptionDisplayName+`</em><br>Do you want to proceed?&nbsp`
  this.modal.toggleModal('reactivate_subscription')
}
onReactivateSubscription(event:any){
  if(event==true){
   this.loaderEnabled=true;
   let reactivateSubscriptionRequest:SubscriptionReactivateRequest ={
     subscriptionName: this.selectedSubscriptionName,
     productId: this.selectedProductDetail.productId
   }
   this.hipManagementService.reactivateSubscription(reactivateSubscriptionRequest).subscribe(
     {
       next:(response:any)=> {
         this.loaderEnabled=false;
         this.showFailure=false;
         this.successMsg=this.selectedSubscriptionDisplayName+' subscription reactivated successfully';
         this.showSuccess=true;
         this.loadSubscriptionList();
         timer(5000).subscribe(x => this.showSuccess = false);
       },
       error:(err:any)=> {
         console.log(err);
         this.loaderEnabled=false;
         this.showSuccess=false;
         this.failureMsg = this.selectedSubscriptionDisplayName+ ' subscription reactivation failed with exception: '+err;
         this.showFailure=true;
         timer(5000).subscribe(x => this.showFailure = false);        
       },
     }
   )
  }
}
ondeleteSubscriptionModal(data:any){
  this.selectedSubscriptionName=data?.uniqueId??'';
  this.selectedSubscriptionDisplayName=data?.subscriptionName;
  let productDetail=data?.detail?.tableData[0]??'';
  this.selectedProductDetail=productDetail;
  this.deleteSubscriptionMessage=`You are about to delete the following subscription:<br><em>`+this.selectedSubscriptionDisplayName+`</em><br>Do you want to proceed?&nbsp`
  this.modal.toggleModal('delete_subscription');
}
onDeleteSubscription(event:any){
  if(event==true){
   this.loaderEnabled=true;
   let deleteSubscriptionRequest:DeleteSubscriptionRequest ={
     subscriptionName: this.selectedSubscriptionName,
     productId: this.selectedProductDetail.productId
   }
   this.hipManagementService.deleteSubscription(deleteSubscriptionRequest).subscribe(
     {
       next:(response:any)=> {
         this.loaderEnabled=false;
         this.showFailure=false;
         this.successMsg=this.selectedSubscriptionDisplayName+' subscription deleted successfully';
         this.showSuccess=true;
         this.loadSubscriptionList();
         timer(5000).subscribe(x => this.showSuccess = false);
       },
       error:(err:any)=> {
         console.log(err);
         this.loaderEnabled=false;
         this.showSuccess=false;
         this.failureMsg = this.selectedSubscriptionDisplayName+ ' subscription deletion failed with exception: '+err;
         this.showFailure=true;
         timer(5000).subscribe(x => this.showFailure = false);        
       },
     }
   )
  }
}
 resetScroll(){    
  const BodyElement: HTMLElement | null = document.getElementById('bodyContent');
  if(BodyElement != null ){
    BodyElement.scrollTop = 0;
  }
 }
 exportSubscriptions(event:any){
  let envValue= Environment.environment;
  this.hipManagementService.getMySubscriptionsToBeExported().subscribe(
    {
      next:(productDetails: any[])=>{
        if(productDetails.length==0){
          this.showDownloadEmpty=true;
          this.failureMsg='No subscriptions found';
          timer(5000).subscribe(x => this.showDownloadEmpty = false);                   
        }else{
          let currentDate = new Date();
          let formattedDate=new DatePipe('en-US').transform(currentDate,"YYYYMMdd-HHmmss");
          const fileNameWithEnv = `my_subscriptions_${envValue}_${formattedDate}.xlsx`;
          const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(productDetails.map(subscription =>({
            'Product Name'        :  subscription.productName,
            'Environment'         :  subscription.environment, 
            'Subscription Name'   :  subscription.subscriptionName,
            'Subscriber Name'     :  subscription.subscriberName,
            'Application Number'  :  subscription.applicationNumber,
            'Application Name'    :  subscription.applicationName,
            'Business Area'       :  subscription.businessArea,
            'Application Owner'   :  subscription.applicationOwner,
            'State'               :  (subscription.state).toUpperCase()=="SUBMITTED"?"Pending Approval":((subscription.state.charAt(0)).toUpperCase())+(subscription.state).slice(1),
            'Date Requested'      :  subscription.dateRequested,
            'Date Activated'      :  subscription.dateActivated,
            'Usage Notes'         :  subscription.usageNotes,
            'Remarks'             :  subscription.remarks   
          })));
          const workbook: XLSX.WorkBook = { Sheets: { 'My Subscriptions': worksheet }, SheetNames: ['My Subscriptions'] };
          const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
          this.saveExcelFile(excelBuffer, fileNameWithEnv);
        }
      },
      error:(error:any)=>{
        console.error('Error fetching subscription list:', error);

      }
    }
  )
 }
 saveExcelFile(buffer: any, fileName: string) {
  const data:Blob = new Blob([buffer],{ type: 'application/octet-stream' });
  const url: string = window.URL.createObjectURL(data);
  const a: HTMLAnchorElement = document.createElement('a');
  document.body.appendChild(a);
  a.href = url;
  a.download = fileName;
  a.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(a);
 }
 clearSearch(){
  if(this.searchSubscriptionvalue.length<3){
    this.searchSubscriptionvalue="";
  }else{
    this.searchSubscriptionvalue="";
    this.searchSubscriptionValueChanged.next("");
  }
 }
}
