<h2 class="title">Information</h2>
<div class="content">
  <p style="white-space: pre-line">
 {{information}}
  </p>
</div>
<div class="details-wrapper">
  <div class="details" *ngFor="let data of details">
    <h3 class="title">{{ data.title }}</h3>
    <h3 class="title bold">{{ data.value }}</h3>
  </div>
</div>

