import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-label',
  templateUrl: './label.component.html',
  styleUrls: ['./label.component.scss']
})
export class LabelComponent {
  @Input() value : any = ['internal']
  @Input() type : string = ''
  @Input() isRenderLabelOnlyEnabled:boolean = false;
  @Input() label:string ='';
  @Input() copyMessage:string="";
  @Input() showSecondaryStyle:boolean=false;
  renderTag: boolean = false
  showCopyIcon:boolean=false;
  isCopied:boolean=false;
  @Input() copyTextValue:string="test"
  ngOnInit(): void {
    if(this.type == 'tag'){
      this.renderTag = true
    }else{
      this.value
    }
  }
  copyTextToClipboard(){
    const copyText = function(copyElem: any){
      const el = document.createElement('textarea');
      el.value = copyElem;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    
    }
    copyText(this.copyTextValue);
    
    if (this.copyTextValue.length > 1){
      this.isCopied = true;
    }
  }
  reset(){
    this.isCopied=false;
  }
}
