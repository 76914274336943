<div class="timeline">
    <ul>
        <ng-container *ngFor="let endpoint of this.endPoints" >
            <li [class.healthy]="endpoint.status === 'Healthy'" [class.unhealthy]="endpoint.status === 'Unhealthy'">
                <div class="detail_content">
                    <h3 *ngIf="endpoint.status === 'Healthy'"><img src="/assets/Health-monitor/Healthy.svg"> Healthy</h3>
                    <h3 *ngIf="endpoint.status === 'Unhealthy'"><img src="/assets/Health-monitor/Unhealthy.svg"> Unhealthy</h3>
                    <p *ngIf="endpoint.status === 'Unhealthy'">{{endpoint.description}}</p>
                </div>
                <div class="date_content">
                    <span class="date">{{endpoint.formattedDate}}</span>
                    <span class="time">{{endpoint.formattedTime}}</span>
                  </div>
            </li>
        </ng-container>        
     <div style="clear:both;"></div>
    </ul>
  </div>