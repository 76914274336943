import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-product-card',
  templateUrl: './product-card.component.html',
  styleUrls: ['./product-card.component.scss']
})
export class ProductCardComponent implements OnInit{
 
  @Input() dataset: any = {}
  @Input() enableAPIQueryParams:boolean = false;
  @Input() showSingleCardStyle:boolean = false;
  @Input()  isClickableLabelReq:boolean = true
  @Output() tagSearchEvent: EventEmitter<String> = new EventEmitter ();
  ngOnInit(): void {
    this.dataset;
  }

  onApiClick(apiId:string, apiName:string){
sessionStorage.setItem(apiId,apiName);
sessionStorage.setItem('apiDisplayNameValue', apiName);
sessionStorage.setItem('productDisplayNameValue', this.dataset.productName);
  }

  onTagSearch(event:any){
    this.tagSearchEvent.emit(event);
  }
}
