import { FormControl } from '@angular/forms';
import { Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-auto-suggest',
  templateUrl: './auto-suggest.component.html',
  styleUrls: ['./auto-suggest.component.scss']
})
export class AutoSuggestComponent implements OnInit {
  @Input() props: any = [];
  @Input() dataSets: any = [];
  @Input() checkError: boolean = false;
  @Input() control= new FormControl('');
  @Input() displayControl = new FormControl('');
  @Input() displayMultiple:boolean = false;  
  @Output() newItemEvent = new EventEmitter<any>();  
  @Output() search = new EventEmitter();
  @Input() enableCustomLabel:boolean = false;

  title: string = "";
  placeHolder: string = "Enter";
  options: any = [];
  val_options: any = [];
  filteredOptions: any=[];
  isActive: boolean = false;
  inputField: any ;
  errorMsg: string = "";
  isError: boolean = false;
  id: string='';
  objID: string = '';
  loading : boolean = true; 
  showDropdown: boolean = false;

  constructor(private http: HttpClient, private elementRef: ElementRef) {
      this.isActive = false;
      this.showDropdown = false;
      this.inputField = '';
      this.id = Math.random().toString(36).substring(2);
  }

  ngOnInit(): void {  
    this.title = this.props.heading;
    this.errorMsg = this.props.errMsg;
    if(this.props.placeholder){
      this.placeHolder = this.props.placeholder;
    }
    this.options = this.dataSets;
    this.options.map((e:any)=>{
     this.val_options.push(e.value);
     this.checkError = this.checkError;
    });
   
   if(this.control.value){    
     this.setData()
   }
   }
  
  sendData(event: any){
    this.showDropdown = true;
    this.isActive = true; 
    this.loading = true;
    let searchValue:String=event.target.value.toLowerCase()??"";
    let extraSpaceTrimmedSearchValue=(searchValue.trimStart()).trimEnd();  
    this.search.emit(extraSpaceTrimmedSearchValue);
    if(!this.val_options.includes(event.target.value) ){
     this.isError = true;
     this.newItemEvent.emit('');
   }
   this.checkError=true;
 }

  getData(event: any){ 
    if (this.displayMultiple) {
      this.inputField = event.value + " / " + event.id;
    }
    else if(this.enableCustomLabel){
      this.inputField = event.label
    }
    else {
      this.inputField = event.value;
    }    
    this.objID = event.id;
    const matchedObj = this.filteredOptions.find((obj: { id: string; }) => obj.id === this.objID);
    this.control.setValue(matchedObj);
    this.displayControl.setValue(this.inputField);
    this.filteredOptions = [];
    this.newItemEvent.emit(matchedObj);
    this.isError = false;
    this.showDropdown = false;
  }
  
  setData(){
    let rawValue: any = this.control.value;
    let printVal = rawValue?.value + " / "+ rawValue?.id;
    this.inputField = printVal;
    this.objID = rawValue?.id;
    const matchedObj = this.filteredOptions.find((obj: { id: string; }) => obj.id === this.objID);
    this.control.setValue(matchedObj);         
    this.filteredOptions = [];
    this.newItemEvent.emit(matchedObj);
    
  }
 
  ngOnChanges(): void {
    this.filteredOptions = this.dataSets;  
    if(this.filteredOptions.length){
      this.loading = false;   
    }else{
      this.loading = false; 
    }  
    this.isActive = true;        
  }


  @HostListener('document:click', ['$event'])
  handleClickOutside(event: any) {
   if(this.inputField.length == 0 && !this.enableCustomLabel){
    this.control.setValue('');    
   }
    if (!this.elementRef.nativeElement.contains(event.target) && event.target.id !== this.id) {
      if(this.showDropdown && this.isActive){
        this.control.setValue('');
      }
      this.showDropdown = false;
      this.isActive = false;
      
    }
  }
}
