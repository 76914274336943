<div #bodyWrapper>
    <app-bread-crumb [links]="[
    {
      'href': '/publisher/manage-apis',
      'title': 'Manage APIs'
    },
    {
      'href': '/publisher/manage-apis/view-api/'+this.breadCrumbApiId,
      'title': ' '+this.breadCrumbApiName
    },
    {
      'href': '/publisher/manage-apis/edit-api/'+this.breadCrumbApiId, 
      'title': ' Edit API'
    }
  ]" ></app-bread-crumb>
 
  <section class="content-section"  #contentSection>
    <div class="form-section">
        <div class="container form-head" style="display: flex; justify-content: space-between; flex-wrap: wrap;">
            <div>
                <h2 class="title">Edit API</h2>
                <p>Please change the information below to update this API.</p>
            </div>
        </div>
        <div class="container" id="edit">                                   
            <div class="row">
                <div class="col-12 col-sm-12 col-md-8">
                    <h3 class="title">API URL Suffix</h3>
                    <h3 class="title bold"><a class="blue-content">{{this.dataset.urlSuffix}}</a></h3>
                </div>
            </div>
            
        </div>
        <div class="container" id="edit-api">
            <form [formGroup]="EditApiForm" (ngSubmit)="onSubmit()" [class.loading]="loaderActive" [class.disableClick]="successFull">
              <div class="row">
                <app-text-field class="col-12 col-md-6" [props]="{
                  'heading': 'API Name',
                  'placeholder': 'Enter',
                  'required': true,
                  'disable': false,
                  'info': 'Enter the name of the API',
                  'errMsg': 'Please enter a valid API name '
                }"                     
                [control]="ApiName"
                (keydown.enter)="preventSubmit($event)"
                [checkError]="checkError"
                ></app-text-field>                
                <app-text-field 
                  class="col-sm"
                  [props]="{
                    heading: 'API Id',
                    placeholder: '',
                    required: false,
                    disable: true,
                    info: '',
                    errMsg: ''
                  }"                                  
                  [control]="getControl('ApiId')"
                  (keydown.enter)="preventSubmit($event)"
                  [checkError]="checkError"
                ></app-text-field>
              </div>              
              <div class="row">            
                <app-text-field 
                  class="col-sm"
                  [props]="{
                    heading: 'API Version',
                    placeholder: '',
                    required: false,
                    disable: true,
                    info: '',
                    errMsg: ''
                  }"                                  
                  [control]="getControl('ApiVersion')"
                  (keydown.enter)="preventSubmit($event)"
                  [checkError]="checkError"
                ></app-text-field>
                <app-auto-suggest
            [props]="{
              heading: 'API Owner',
              required: true,
              disable: false,
              info: 'Select an owner or contact person for the API',
              placeholder: 'Type at least 3 characters to search in Entra ID',
              'errMsg': 'Please select an API owner'
            }"
           (search)="userSearch($event)"
           [dataSets]="apiOwnerList"
           [displayMultiple]=false
           [control]="ApiOwner"
           [displayControl]="displayApiOwner"
           (keydown.enter)="preventSubmit($event)"
            class="col-sm"
            [checkError]="checkError"
            [enableCustomLabel]="true"
            (newItemEvent)="apiOwnerSelected($event)"
          ></app-auto-suggest>
              </div>
              <div class="row">            
                <app-text-field 
                  class="col-sm"
                  [props]="{
                    heading: 'Application Name / Number',
                    placeholder: '',
                    required: false,
                    disable: true,
                    info: '',
                    errMsg: ''
                  }"                                  
                  [control]="AppNameAndNumber"
                  (keydown.enter)="preventSubmit($event)"
                  [checkError]="checkError"
                ></app-text-field>
                <app-text-field 
                  class="col-sm"
                  [props]="{
                    heading: 'Application Owner',
                    placeholder: '',
                    required: false,
                    disable: true,
                    info: '',
                    errMsg: ''
                  }"                         
                  [control]="getControl('AppOwner')"
                  (keydown.enter)="preventSubmit($event)"
                  [checkError]="checkError"
                ></app-text-field>
              </div>
              <div class="row">
            <app-text-area 
            class="col-12"
            [props]="{
              heading: 'Description',
              placeholder: 'Enter',
              required: true,
              disable: false,
              info: 'Enter a detailed description about the API',
              errMsg: 'Please enter an API description'
            }"
            (keydown.enter)="preventSubmit($event)"
            [control]="Description"
            [checkError]="checkError"
          ></app-text-area>         

          <app-auto-search-tag *ngIf="showApiDetails" [props]="{
            heading: 'Tags',
            sideText: '',
            required: true,
            info: 'Add one or more tags to make the API easy to find for developers',
            disable: false,
            placeholder: 'Add tags by pressing enter or type at least 3 characters to search in APIM',
            errMsg: (this.tagValueErrorMsg != '') ? this.tagValueErrorMsg : 'Please add atleast one tag'
          }"
          (search)="tagSearch($event)"
           [dataSets]="tagsDataset"
           [control]="Tags"
           (keydown.enter)="preventSubmit($event)"
            class="col-sm"
            [checkError]="checkError"></app-auto-search-tag>
                </div>
                <div class="row">                    
                    <div class="col-sm buttons-align-end button-wrapper">
                      <div class="feedbackMsg" [class.feedbackActive]="this.feedbackActive">
                        <ng-container *ngIf="successFull" >
                          <span class="successColor">{{successMsg}}</span>
                          <img src="./assets/successIcon.svg"/>
                        </ng-container>
                        <ng-container *ngIf="failure" >
                          <span class="failureColor">{{failureMsg}}</span>
                          <img class="error" src="../../../../assets/Health-monitor/Unhealthy.svg"/>
                        </ng-container>
                        
                      </div>
                      <app-buttons
                        [props]="{
                          icon: '',
                          content: 'Cancel',
                          href: '#',
                          classType: 'tertiary',
                          type:'button'
                        }"  (click)="onClick('/publisher/manage-apis')"
                      ></app-buttons>
                      <app-buttons *ngIf="editAccess"
                        [props]="{
                          icon: '',
                          content: 'Save',
                          href: '#',
                          classType: 'primary',
                          type:'submit'
                        }"                        
                      ></app-buttons>
                    </div>
                  </div>
                  <app-general-loader *ngIf="loaderActive"></app-general-loader>
            </form>
        </div>
    </div>
    <div class="info-section container">
        <app-information-section [information]="pageInfo" [detailsInfo]="informationData"></app-information-section>
      </div>
    </section>
    <app-alert *ngIf="showSuccess" [success]="true">
      <div class="success_Icon"><img src="./assets/successIcon.svg"/><p>{{successMsg}}</p></div>   
    </app-alert>
    <app-alert *ngIf="showFailure" [failure]="true">
      <div class="unSuccess_Icon"><img src="../../../../assets/Health-monitor/Unhealthy.svg"/><p>{{failureMsg}}</p></div>   
    </app-alert>
</div>
