
import { Component, HostListener, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';
import { StateService } from 'src/app/shared/services/state.service';
import { ActivatedRoute } from '@angular/router';
import { Router} from'@angular/router';
import {DateFormatPipe} from "../../../pipes/date-format-pipe.pipe"
import { ViewSubscriptionModel } from '../models/viewSubscription-model';
import { CustomCapitalizePipe } from 'src/app/pipes/capitalize-pipe/custom-capitalize-pipe.pipe';
import { Subscription, timer } from 'rxjs';
import { SubscriptionAttribute, SubscriptionAttributeListModel } from '../../models/subscription-attribute-model';
import { ErrorHandleService } from 'src/app/shared/services/error-handle.service';

@Component({
  selector: 'app-view-subscription',
  templateUrl: './view-subscription.component.html',
  styleUrls: ['./view-subscription.component.scss'],
  providers:[DateFormatPipe,CustomCapitalizePipe]
})

export class ViewSubscriptionComponent implements OnInit{
dataset: ViewSubscriptionModel={
SubscriptionName: '',
SubscriptionDisplayName: '',
ProductName: '',
SubscriberName: '',
CmdbId:'',
BusinessAreaCode:'',
ApplicationName:'',
AppOwner:'',
ActivatedDate:'',
DateRequested:'',
ProxyClientID:'',
SubscriptionKey:'',
State:'',
UageNotes:'',
UnitCode:'',
CostCenter:'',
Remarks:'',
apiData:[],
SubscriptionAttributeList: []
};
detailsInfo:any[]=[];
subscriptionState:boolean=false;
productInfo:{parentData:{productName:string}}={parentData:{productName:""}}
loaderActive: boolean = true;
approveButton: boolean = false;
rejectButton: boolean = false;
breadCrumbSubscriptionName:string = "";
breadCrumbSubscriptionDisplayName:string = "";
breadCrumbProductId:string="";
breadCrumbProductName:string="";
apimProductId: string ="";
ProductName = new  FormControl('', [Validators.required, Validators.minLength(3)]);
ProductId = new FormControl('');
appRegistrationId: string ="";
appRegistrationName: string ="";
subscriberFullName:string="";
showSubscriptionAttribute:boolean=false;
showMask = true;
keyValue:string="";
pageInfo:string = "A subscription is created against a Product, containing one or more APIs. Once subscribed, an application gets access to all APIs inside a Product in a secure way. \n \n You are viewing a Product subscription that has been created in Azure API Management."
pendingAPICallCount:number=0;
successMsg:string = '';
showSuccess:boolean = false;
successFull:boolean = false;
feedbackActive:boolean = false;
failureMsg:string ="";
showFailure:boolean = false;
failure:boolean = false;
approverList: string[] = [];
enableColonDateFormat:boolean = true;
enableEdit:boolean = false;
isSubmitted:boolean = false;
checkValidation:boolean = false;
currentSavedSubscriptionAttributes:any=[]
buttonProps:any[] = [];
isStateActive:boolean = false;
subscriptionAttributeToolTip:string = "Subscription attributes are a way to embed configurable parameters into a subscription. Those are defined on API level during onboarding and can steer the backend execution of individual API calls through that subscription. Subscription attributes are invisible to subscribers and not intended to store secret values."
constructor(private hipManagementService: Hip2ManagementService,
  private stateservice: StateService,protected route: ActivatedRoute, private router:Router, private dataPipe: DateFormatPipe,private capitalizePipe :CustomCapitalizePipe, private errorHandleService: ErrorHandleService){
  }

ngOnInit(): void {
  this.breadCrumbProductId = this.route.snapshot.paramMap.get('apimProductId') ?? "";
  this.breadCrumbSubscriptionName = this.route.snapshot.paramMap.get('subscriptionName') ?? "";
  let queryParams = this.route.snapshot.queryParams;
  this.breadCrumbSubscriptionDisplayName = queryParams['subscriptionDisplayName']??"";
  this.breadCrumbProductName = queryParams['productDisplayName']??"";
 
  this.getSubscriptionBySubscriptionName(this.breadCrumbSubscriptionName, this.breadCrumbProductId);

}

getSubscriptionBySubscriptionName(subscriptionName: string,apimProductId: string) {
  this.hipManagementService.getSubscriptionBySubscriptionName(subscriptionName,apimProductId).subscribe(r => {
    let subscription = r;
    if(!(subscription.hasAccess)){
      this.router.navigate(['/unauthorized']);
    }else{
      
   
     if(subscription.state=="active")
     {
     this.isStateActive = true;
     this.rejectButton=true;
     }
     else if(subscription.state=="reject")
     {
     this.approveButton=true;
     }
     else{ this.rejectButton=false,
      this.approveButton=false;
    }
    //this.keyValue=this.maskPipe.transform(subscription.subscriptionKey,true);
    this.subscriberFullName=subscription.subscriberName;
    let subscriberName=(this.subscriberFullName).split(" ");
    let formattedSubscriberName = "";
    subscriberName.forEach(name => {
      let nameValue=this.capitalizePipe.transform(name);
      formattedSubscriberName=formattedSubscriberName.length==0?nameValue:(formattedSubscriberName+" "+nameValue);
    });

    this.dataset = {
      SubscriptionName: subscription.subscriptionName,
      SubscriptionDisplayName: subscription.subscriptionDisplayName,
      ProductName: subscription.productName,
      SubscriberName: formattedSubscriberName,
      CmdbId:subscription.cmdbId,
      BusinessAreaCode: subscription.businessAreaCode,
      ApplicationName: subscription.applicationName,
      AppOwner:subscription.appOwner,
      ActivatedDate:this.dataPipe.transform(subscription.activatedDate, this.enableColonDateFormat) ,
      DateRequested: this.dataPipe.transform(subscription.dateRequested, this.enableColonDateFormat),
      ProxyClientID: subscription.proxyClientID,
      SubscriptionKey:subscription.subscriptionKey,
      UageNotes:subscription.usageNotes,
      Remarks:subscription.state=="rejected"?(subscription.statusChangedReason.length>0?subscription.statusChangedReason:"-"): 
      (subscription.remarks.length>0?subscription.remarks:"-"),
      UnitCode:subscription.unitCode,
      CostCenter:subscription.costCenter,
      State: subscription.state=="submitted"?"Pending Approval":((subscription.state.charAt(0)).toUpperCase())+(subscription.state).slice(1),
      apiData:[],
      SubscriptionAttributeList:subscription.subscriptionAttributeList
    }
    if(subscription.subscriptionAttributeList != null && subscription.subscriptionAttributeList != undefined){
      subscription.subscriptionAttributeList.forEach((v: any, i: any) => {
        const val = (typeof v === 'object') ? Object.assign({}, v) : v;
        this.currentSavedSubscriptionAttributes.push(val);
    });
    }
    this.detailsInfo = [
      {'title':'Application Name', 'value':subscription.applicationName},
      {'title':'Application Number', 'value':subscription.cmdbId},
      {'title':'Application Owner', 'value':subscription.appOwner},    
      {'title':'Business Area', 'value':subscription.businessAreaCode},
      {'title':'Unit Code', 'value':subscription.unitCode},    
      {'title':'Cost Center', 'value':subscription.costCenter},
      {'title':'Date Requested', 'value':this.dataPipe.transform(subscription.dateRequested, this.enableColonDateFormat)},
      {'title':'Date Activated', 'value':subscription.activatedDate!=undefined?this.dataPipe.transform(subscription.activatedDate, this.enableColonDateFormat):"  - | -"}
      
    ];
    this.pendingAPICallCount=this.pendingAPICallCount+1;
    this.hipManagementService.getSubscriptionApisByApimProductIdSubscriptionName(apimProductId,subscription.subscriptionName).subscribe(a => {
      this.pendingAPICallCount=this.pendingAPICallCount-1;
      let productAPIs=[];
      for (let index = 0; index < a.length; index++) {
        let element = a[index];
        let api={
          apimApiName: element.apimApiName,
          name:element.name,
          apiOwner:element.apiOwner,
          apiLink:'/publisher/manage-subscriptions/view-api/'+element.apimApiName,
          subscriptionAttributesData:element.subscriptionAttributesData,
          isExpanded:false
        }
        if(element.subscriptionAttributesData!=null)
        {
          if(element.subscriptionAttributesData.length!=0)
          {
            this.showSubscriptionAttribute=true;
            this.buttonProps=[{content:'Edit', classType:'primary', icon:'./assets/buttons/editIcon.svg', isBreadCrumbClickEvent:'true'}];
          }
       }
      else{ this.showSubscriptionAttribute=false;}
        productAPIs.push(api);
      }
      this.dataset.apiData = productAPIs;

    },error=>{
      console.log("error",error)
      this.pendingAPICallCount=this.pendingAPICallCount-1;
    });
    this.loaderActive = false;  
  }},
  error=>{
this.errorHandleService.handleError(error);
  });
}

onViewApiClick(apiId:string, apiName:string){
sessionStorage.setItem(apiId,apiName);
}
onEditClick(event:any){
  this.enableEdit = true;
  this.buttonProps = [{content:'Edit', classType:'disable', icon:'./assets/buttons/editIcon.svg', isBreadCrumbClickEvent:'true'}];
  this.successFull=false;
  this.failure=false;
  this.isSubmitted=false;
  const BodyElement: HTMLElement | null = document.getElementById('bodyContent');
  if(BodyElement != null ){
    BodyElement.scrollTop = 1600;
  }
}

onSubscriptionAttributeValueChange(displayName:any,id:any,ApimApiId:any,event:any){
  this.dataset.apiData?.forEach(api => {
    api.subscriptionAttributesData?.forEach(item =>{
    if(item.displayName == displayName && item.id==id && item.apimApiId==ApimApiId){
       item.value=event.target.value;
     }
    });
   });
}

onSubmit(){
  this.checkValidation = true;
  let isFormValid:boolean = true;
  this.dataset.apiData?.forEach(api => {
    api.subscriptionAttributesData?.forEach(item => {
      if(item.value.length==0){
        isFormValid = false;
        api.isExpanded=true;
      }
    });
  });
  if(isFormValid){
    this.updateSubscriptionAttribute()
  }
}

updateSubscriptionAttribute(){
  this.loaderActive=true;
  let subscriptionAttributelist:SubscriptionAttribute[] =[];
  this.dataset.apiData?.forEach(api => {
    api.subscriptionAttributesData?.forEach(element => {
      let data ={
        description: element.description,
        displayName: element.displayName,
        id: element.id,
        status: element.status,
        isVisible: element.isVisible,
        value: element.value,
        ApimApiId: element.apimApiId
      }
      subscriptionAttributelist.push(data);
    });
   });
  let subscriptionAttributeData:SubscriptionAttributeListModel={
    subscriptionName: this.dataset.SubscriptionName,
    ApimProductId: this.breadCrumbProductId,
    remarks: this.dataset.Remarks,
    subscriptionAttributes: subscriptionAttributelist
  }
  this.hipManagementService.updateSubscriptionAttribute(subscriptionAttributeData).subscribe({
    next:(response:any) => {
      this.isSubmitted=true;
      this.loaderActive=false;
      this.successMsg= this.dataset.SubscriptionName+ " updated successfully";
      this.showSuccess=true;             
      this.feedbackActive =true; 
      this.successFull = true; 
      this.failure = false; 
      this.enableEdit = false;
      this.currentSavedSubscriptionAttributes=[];
      this.dataset.SubscriptionAttributeList.forEach((v: any, i: any) => {
        const val = (typeof v === 'object') ? Object.assign({}, v) : v;
        this.currentSavedSubscriptionAttributes.push(val);
    });
      this.buttonProps = [{content:'Edit', classType:'primary', icon:'./assets/buttons/editIcon.svg', isBreadCrumbClickEvent:'true'}];
      timer(5000).subscribe(x => this.showSuccess = false);       
    },
    error:(error:any) => {    
      this.loaderActive=false; 
      this.isSubmitted=false; 
      let errMsg='';                 
      if(error.error.message){
        errMsg=error.error.message;
      }
      else if(error.error){
        errMsg=error.error;
      }
      this.failureMsg = this.dataset.SubscriptionName + ' update failed with exception: ' + errMsg;
      this.showFailure = true;  
      this.feedbackActive =true;
      this.successFull = false;
      this.failure = true;  
      timer(5000).subscribe(x => this.showFailure = false);       
    }
  }); 
}

onEditCancel(){
  this.buttonProps = [{content:'Edit', classType:'primary', icon:'./assets/buttons/editIcon.svg', isBreadCrumbClickEvent:'true'}];
  this.dataset.SubscriptionAttributeList=[...this.currentSavedSubscriptionAttributes];
  this.enableEdit=false;
}
}

