import { Component, EventEmitter, Input, OnInit, Output,  HostListener, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-search-dropdown',
  templateUrl: './search-dropdown.component.html',
  styleUrls: ['./search-dropdown.component.scss']
})
export class SearchDropdownComponent {
     @Input() setValue: any = [];
     @Input() props: any = [];
     @Input() dataSets: any = [];
     @Input() control= new FormControl([]);
     @Input() checkError: boolean = false;
    @Input()  isTooltipHasLink:boolean =false;
     filteredOptions:any[]=[];
     @Input() selectedValue:string="";
     title: string = "";
     placeHolder: string ="Enter to search";
     isActive: boolean = false;
     errorMsg: string = "";
    isError: boolean = false;
    loading : boolean = true; 
    @Output() changed = new EventEmitter();
    @Output() search = new EventEmitter();
  
    showDropdown: boolean;
    isSelectAll: boolean;
    id: string='';
    constructor(private elementRef: ElementRef) {
        this.showDropdown = false;
        this.isSelectAll = false;
        this.id = Math.random().toString(36).substring(2);
    }
      ngOnInit(): void {
          this.title = this.props.heading;
          this.errorMsg = this.props.errMsg;
          if(this.props.inputPlaceholder){
            this.placeHolder = this.props.inputPlaceholder;
          }   
      }
      getData(selectedValue: any){
       this.showDropdown = !this.showDropdown;
       this.selectedValue=selectedValue.value;
       this.control.setValue(selectedValue);
       this.isActive = false;
       this.changed.emit(this.selectedValue);
       }
  
      ngOnChanges(): void {
        this.loading = true;
        this.filteredOptions = this.dataSets;
        let selectedrecordExist:boolean = false;
        this.dataSets.forEach((record: { value: string; }) => {
          if(record.value == this.selectedValue){
            selectedrecordExist = true;
          }
        });
        if((!selectedrecordExist)){
          this.selectedValue=""
        }
        this.loading = false
        this.isActive = true;            
      }
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      this.filteredOptions = this.dataSets;
      this.isActive = true;     
    }  
    sendData(event: any){
      this.checkError=true;
      let searchValue:String=event.target.value.toLowerCase()??"";
      let extraSpaceTrimmedSearchValue=(searchValue.trimStart()).trimEnd();
      if(extraSpaceTrimmedSearchValue.length>0){
        this.loading = true;
        this.filteredOptions= this.dataSets.filter((data: any) => data.value.toLowerCase().includes(extraSpaceTrimmedSearchValue));   
        this.loading = false;
      } else{
        this.filteredOptions = this.dataSets;
      }
    }
  

    @HostListener('document:click', ['$event'])
    handleClickOutside(event: any) {  
      
      if (!this.elementRef.nativeElement.contains(event.target) && event.target.id !== this.id) {
        this.showDropdown = false;
        this.isActive = false;
      }
    }
  }
  
  
  
  

