import { Component, Input, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-text-mask',
  templateUrl: './text-mask.component.html',
  styleUrls: ['./text-mask.component.scss']
})
export class TextMaskComponent{
  @Input() text: any = '';
  inputText : string = ''
  masked: boolean = true;
  inputType = 'password'

  ngOnChanges(changes: SimpleChanges): void {
    if(this.text.length){
      for(let i = 0; i<this.text.length; i++){
        this.inputText+='*';
      }
    }
  }
  toggleMask(e:any): void{
    if(this.inputType === 'password'){
      this.inputType = 'text'
      this.masked = false
    }else{
      this.inputType = 'password'
      this.masked = true
    }
    e.stopPropagation();
  }

}
