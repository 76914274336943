import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent {
@Input() links: any = []
@Input() buttonProps: any = []
@Output() breadCrumbClickEvent = new EventEmitter<string>();

emitClickEvent($event:string){    
    this.breadCrumbClickEvent.emit($event);     
}

}
