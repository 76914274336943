import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'dateFormatPipe'
})
export class DateFormatPipe extends DatePipe implements PipeTransform {

  override transform(value: any,...args: any[]): any {
    let enableColonFormat:boolean=args[0];
    let formattedDateValue = "";
    if(value !=undefined){
      let splittedDateValue = value.split('T');
      formattedDateValue= splittedDateValue.join(' ');
      if(formattedDateValue.endsWith('Z')){
        formattedDateValue=formattedDateValue.slice(0,formattedDateValue.indexOf('z'))
      }
    }
    if(enableColonFormat){
      return super.transform(formattedDateValue,"D: YYYY-MM-dd | T: HH:mm:ss.SSS");
    } else{
      return super.transform(formattedDateValue,"D YYYY-MM-dd | T HH:mm:ss.SSS");
    }
  }
}
