import { NgModule } from "@angular/core";
import { CustomCapitalizePipe } from "./capitalize-pipe/custom-capitalize-pipe.pipe";


@NgModule({
    declarations:[CustomCapitalizePipe],
    exports:[CustomCapitalizePipe]
})
export class CustomPipeModule {
  
}