import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-text-area',
  templateUrl: './text-area.component.html',
  styleUrls: ['./text-area.component.scss']
})
export class TextAreaComponent implements OnInit {
  @Input() props: any = [];
  @Input() appID: string = '';
  @Input() checkError: boolean = false;
  @Input() control: FormControl = new FormControl('');
  title: string = "";
  placeHolder: string = "";
  content: string = '';
  errorMsg: string = "";

  ngOnInit(): void {
    this.title = this.props.heading;
    this.placeHolder = this.props.placeholder;
    this.errorMsg = this.props.errMsg;  
    this.checkError = this.checkError;  
  }
}
