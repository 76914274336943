<div class="rel-comp-wrapper" [class.disable]="props.disable" [class.disable-interaction]="disableUserInteraction">
<h3 class="title">
  {{ title }}<span class="required-star" *ngIf="props.required && !props.disable">*</span>
  <span class="info ds_tooltip" *ngIf="props.info && !props.disable">
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="#7D8083"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M8 16C12.4183 16 16 12.4183 16 8C16 3.58172 12.4183 0 8 0C3.58172 0 0 3.58172 0 8C0 12.4183 3.58172 16 8 16ZM6.9819 7.08343C7.09348 6.79662 6.94991 6.6532 6.82224 6.6532C6.23256 6.6532 5.4667 8.04042 5.18031 8.04042C5.06826 8.04042 4.97264 7.92847 4.97264 7.83288C4.97264 7.54583 5.67417 6.87628 5.88186 6.66892C6.51937 6.06302 7.34882 5.60059 8.27374 5.60059C8.95907 5.60059 9.69287 6.01501 9.11883 7.56216L7.9705 10.6712C7.87524 10.9104 7.6998 11.3094 7.6998 11.5644C7.6998 11.6759 7.76319 11.7878 7.89078 11.7878C8.36892 11.7878 9.24635 10.4322 9.46969 10.4322C9.54925 10.4322 9.66075 10.5277 9.66075 10.6712C9.66075 11.1336 7.79532 13.1111 6.1846 13.1111C5.61051 13.1111 5.21194 12.8403 5.21194 12.2342C5.21194 11.469 5.75398 10.1613 5.86611 9.88999L6.9819 7.08343ZM7.84286 3.94212C7.84286 3.24057 8.44868 2.66667 9.15041 2.66667C9.78865 2.66667 10.2508 3.09692 10.2508 3.75091C10.2508 4.48471 9.6451 5.02691 8.92746 5.02691C8.27376 5.02691 7.84286 4.59609 7.84286 3.94212Z"
      />
    </svg>
    <span class="tooltiptext"
      >{{props.info}}</span>
  </span>
</h3>
<div class="ds_c-multiSeltDrpdwn" >
  
  <div class="ds_c-multiSeltDrpdwn-header" [class.errorActive]="(control.touched && control.dirty && control.errors) || (checkError && control.errors)" id="{{id}}" [class.flatBorder]="showDropdown" (click)="toggleDropdown()">
      <span *ngIf="selectedOptions.length == 0" class="text-placeholder">{{props.placeholder}}</span>
      <span *ngIf="selectedOptions.length > 0" class="ds_c-tagsWrapper" ><span class="ds_c-tags" *ngFor="let o of  selectedOptions">{{o.label}} <img (click)="removeTag(o)" src="../../../../assets/textbox/Close_but.svg" width="16" height="16"></span></span>
      <span *ngIf="selectedOptions.length == 0" class="up-dwn-arrow"></span>
    </div>
  <span *ngIf="selectedOptions.length > 0" class="removeBtn" (click)="removeAllTag()"></span>
  <ul *ngIf="showDropdown" class="ds_c-dropdown-list">
    <ng-container *ngIf="isSearchEnabled">
      <input
          class="autoSuggest"
          placeholder="{{ placeHolder }}"
          (keyup)="sendData($event)"
          #AutoSuggest
        />
        {{AutoSuggest.focus()}}
      </ng-container>
      <span *ngIf="!isActive" [ngClass]="{'dropdown-item-wrapper':isSearchEnabled, 'dropdown-item-wrapper-without-scroll-bar':!isSearchEnabled}">
        <ng-container *ngIf="this.loading; else default">
          <li class="dropdown-item loadingOption"><p class="loading-option">Loading...</p></li>
        </ng-container>
        <ng-template #default>
          <li *ngFor="let option of options" class="dropdown-item">              
              <app-checkbox [selectedOptions]="selectedOptions" [label]="option.label" [id]="option.id" (changeEvent)="onChange(option, $event)" [isCustomLabelEnabled]="isCustomLabelForOptionsRequired" [customOptionLabel]="this.isCustomLabelForOptionsRequired?option.customLabel:option.label"></app-checkbox>
          </li>
        </ng-template>
      </span>
      <span *ngIf="isActive" class="dropdown-item-wrapper">
        <ng-container *ngIf="this.loading || filteredOptions.length > 0; else noDataFound">
          <li class="dropdown-item"><p class="loading-option" *ngIf="this.loading">Loading...</p></li>
          <ng-container *ngFor="let option of filteredOptions; let j = index">
            <li class="dropdown-item">
              <app-checkbox [selectedOptions]="selectedOptions" [label]="option.label" [id]="option.id" (changeEvent)="onChange(option, $event)" [isCustomLabelEnabled]="isCustomLabelForOptionsRequired" [customOptionLabel]="this.isCustomLabelForOptionsRequired?option.customLabel:option.label"></app-checkbox>
          </li>
          </ng-container>
        </ng-container>
        <ng-template #noDataFound>
          <li class="dropdown-item noDataFound-option"><p class="loading-option">No results found</p></li>
        </ng-template>      
      </span>
  </ul>
</div>
<p class="error-text" [class.errorActive]="(control.touched && control.dirty && control.errors) || (checkError && control.errors)">
  <img src="./assets/textbox/Error-icon.svg" width="16" height="16" />
  <span>{{errorMsg}}</span>
</p>
</div>

