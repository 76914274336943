<span class="rel-comp-wrapper">
    <span class="input-wrapper selectable" (click)="toggleDrp()" >
      <input class="text-field select" type="text" placeholder="Enter" value="{{this.perPageRender}}" />
      <span class="up-dwn-arrow" ></span>
    </span>
    <div class="result" [class.active]="isActive">
      <ng-container *ngFor="let op of Options">
        <p (mouseup)="getData($event)">{{ op }}</p>
      </ng-container>
    </div>
  </span>
  