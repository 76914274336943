import { NgModule } from "@angular/core";
import {  RouterModule, Routes } from "@angular/router";
import { MsalGuard } from "@azure/msal-angular";
import { HealthMonitorComponent } from "./health-monitor/health-monitor.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule, NoopAnimationsModule } from "@angular/platform-browser/animations";
import { AppRoutingModule } from "src/app/app-routing.module";
import { SharedModule } from "src/app/shared/shared.module";
import { HealthCardComponent } from "src/app/shared/components/health-card/health-card.component";
import { DonutChartComponent } from "src/app/shared/components/donut-chart/donut-chart.component";

const routes:Routes=[
    {
        path:'publisher',
        children: [{
            path:'health-monitor',
            component: HealthMonitorComponent,
            data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/health-monitor"},
            canActivate:[MsalGuard]
        }]
    }
]

@NgModule({
    declarations: [HealthMonitorComponent, HealthCardComponent, DonutChartComponent],
    imports:[RouterModule.forRoot(routes),
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule, NoopAnimationsModule,
        FormsModule,
        ReactiveFormsModule,SharedModule],
        exports: [RouterModule]  
})

export class HealthMonitorModule{

}