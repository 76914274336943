<div [style.width.rem]="chartSize" [style.height.rem]="chartSize" class="donut-chart">
    <svg [attr.width]="svgWidth" [attr.height]="svgWidth">
      <g [attr.transform]="transform" class="chart-group">
        <path *ngFor="let arc of arcs; let i = index"
          [attr.d]="describeArc(0, 0, radius, arc.startAngle, arc.endAngle)"
          [attr.fill]="arc.color">
        </path>
      </g>
    </svg>
    <span class="totalValue">{{this.totalValue}}</span>
  </div>
