<table  cellspacing="0" class="table_64 shimmer">
    <thead>
        <tr class="header-col">
            <th><span></span></th>
            <th><span></span></th>
            <th><span></span></th>
            <th><span></span></th>
            <th><span></span></th>
            <th><span></span></th>
            <th><span></span></th>
            <th><span></span></th>
            <th><span></span></th>
        </tr>
    </thead>
    <tbody>
        <tr *ngFor="let _ of [].constructor(10)" >
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
            <td><span></span></td>
        </tr>
        
    </tbody>
</table>