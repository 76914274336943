import { HttpClient } from '@angular/common/http';
import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Environment } from 'src/environments/environment';
import { ActivatedRoute, ActivationStart, Route, Router} from '@angular/router'

@Component({
  selector: 'app-secondary-nav',
  templateUrl: './secondary-nav.component.html',
  styleUrls: ['./secondary-nav.component.scss']
})
export class SecondaryNavComponent implements OnInit, OnChanges {
  @Input() SideBar_Data: any[] = [];
  @Input() isClose: any;
  @Input() linkTo: any = {};
  activeParentRoutePath:string = "";
  activeMenu:string ="";
  isInitalLoad:boolean = true;
  hideEsbUrl:boolean = false;
  
  constructor(private http: HttpClient, private router: Router) { }
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['SideBar_Data'] !=undefined){
      this.SideBar_Data.forEach(menu => {
        if(menu.title == this.activeMenu){
          menu.isActive=true;
          menu.isUserCollapsedMenu=false;
        }else{
          menu.isActive=false;
        }
      });
    }
  }

  public ngOnInit() {
    let esbUrl = Environment.esbUri;
    if(esbUrl.length==0){
      this.hideEsbUrl=true;
    }
    this.router.events.subscribe(data => {
      if (data instanceof ActivationStart) {
        let routerData = data.snapshot.data
        this.activeMenu = routerData["menu"];
        this.activeParentRoutePath = routerData["defaultModuleRoute"];
        this.SideBar_Data.forEach(menu => {
          if(menu.title == this.activeMenu){
            menu.isActive=true;
            if(this.isInitalLoad){
                menu.isUserCollapsedMenu=false;
                this.isInitalLoad=false;
            }
          }else{
            menu.isActive=false;
          }
        });
      }
    });
}

onMenuClick(url:any){
  this.router.routeReuseStrategy.shouldReuseRoute = () => { return false; };
  this.router.onSameUrlNavigation='reload'
  this.router.navigateByUrl(url);
  this.router.onSameUrlNavigation='ignore'
}
  getTopicUrl(): string {
    return Environment.esbUri;
  }

}
