
<app-primary-nav [isActive]="isActive"
                 (toggleActive)="changeClass($event)"
                 [internalAppEnvironmentList]="appInternalEnvironmentList"
                 [prodAppEnvironmentList]="appProductionEnvironmentList"
                 [userName]="userName"
                 class="primary-nav"
                 [userRole]="assignedUserRole"
                 (logout)="logout()"
                 >
</app-primary-nav>
<div class="ds_c-wrapper">
  <app-secondary-nav [SideBar_Data]="sideMenu"
                     [isClose]="isActive"
                     [linkTo]="{ type: 'Topic'}"
                     class="secondary-nav">

  </app-secondary-nav>
  <div class="body-content" id="bodyContent">
    <router-outlet class="router-flex"></router-outlet>
    <footer>
      <app-footer></app-footer>
    </footer>
  </div>
</div>
