import { Injectable } from '@angular/core';
import { MsalService, MsalBroadcastService } from '@azure/msal-angular';
import { AccountInfo } from '@azure/msal-browser';
import { Router } from '@angular/router';
import { Subject,Subscription, BehaviorSubject, Observable } from 'rxjs';
import { Environments } from 'src/app/types/Environments';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private _userAssignedRole:string = '';  
  subscriptions: Subscription[] = [];
  environmentSelection$: Subject<Environments> = new Subject<Environments>();


  constructor(private broadcastService: MsalBroadcastService,
    private authService: MsalService, private router: Router ) {
    this.subscriptions.push(this.broadcastService.msalSubject$.subscribe(payload => {

    }));
  }
  get Account(): AccountInfo | null {
    var activeAccount = this.authService.instance.getActiveAccount();
    if (activeAccount == null) {
      this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0]);
      activeAccount = this.authService.instance.getActiveAccount();
    }
    return activeAccount;
  }

  routeToProductList() {    
    this.router.navigate(['/publisher/manage-products']);
  }

  setUserRole(role: string) {
    this._userAssignedRole = role;
  }

  getUserRole(): string {
    return this._userAssignedRole;
  } 
  updateEnvironmentSelection(newSelection: Environments): void {
    this.environmentSelection$.next(newSelection);
  } 
  
}
