import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss']
})
export class AlertComponent implements OnInit {

  @Input() success: boolean = false;
  @Input() failure: boolean = false;
  @Input() warning:boolean = false;
  
  
  ngOnInit(): void {
    this.success;
    this.failure;
  }
}
