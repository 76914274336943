import { Component, HostListener } from '@angular/core';

@Component({
  selector: 'app-click-to-top',
  templateUrl: './click-to-top.component.html',
  styleUrls: ['./click-to-top.component.scss']
})
export class ClickToTopComponent {
 show: boolean = true;

 @HostListener('window:scroll', ['$event'])
 onWindowScroll(){
  this.show = (window.pageYOffset > window.innerHeight);
 }
 scrollToTop(){
  console.log("this.show",this.show);
  window.scrollTo({top: 0, behavior: 'smooth'});
 }
}
