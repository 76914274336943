import { Component, EventEmitter, Input, OnInit, Output,  HostListener, ElementRef } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-multi-select-dropdown',
  templateUrl: './multi-select-dropdown.component.html',
  styleUrls: ['./multi-select-dropdown.component.scss']
})
export class MultiSelectDropdownComponent implements OnInit{
   @Input() setValue: any = [];
   @Input() props: any = [];
   @Input() dataSets: any = [];
   @Input() control= new FormControl([]);
   @Input() checkError: boolean = false;
   @Input() disable: any = '';
   @Input() requiredField:string = '';
   @Input() isSearchEnabled: boolean = true;
   @Input() isCustomLabelForOptionsRequired: boolean = false;
   @Input() defaultDataSet: any = [];
   @Input() disableUserInteraction:boolean=false;

   title: string = "";
   options: any = [];
   placeHolder: string ="Type at least 3 characters to fetch data";
   selectedOptions: any = [];
   filteredOptions: any=[];
   isActive: boolean = false;
   errorMsg: string = "";
  isError: boolean = false;
  loading : boolean = true; 
  @Output() changed = new EventEmitter();
  @Output() search = new EventEmitter();

  showDropdown: boolean;
  isSelectAll: boolean;
  id: string='';
  constructor(private elementRef: ElementRef) {
      this.showDropdown = false;
      this.isSelectAll = false;
      this.id = Math.random().toString(36).substring(2);
  }
    ngOnInit(): void {
        this.title = this.props.heading;
        this.errorMsg = this.props.errMsg;
        if(this.props.inputPlaceholder){
          this.placeHolder = this.props.inputPlaceholder;
        }
        this.checkError = this.checkError;
        this.setRequiredField();
        this.control.valueChanges.subscribe(value => {
          if(value === null){
           this.selectedOptions = [];
            
          }
          
        });
        this.options = this.dataSets;
        if(this.setValue.setContent){
        this.setValue.setContent.map( (v:any)  => {this.options.filter((obj :{label : string} )=> obj.label === v); this.selectedOptions.push(v)}) ;
          this.control.setValue(this.selectedOptions);
        }
       
        if(this.control.value){
         this.setMultiValue();
          
        }
    }
    setMultiValue(){
      const multiselectValues : any = this.control.value;
      this.selectedOptions = multiselectValues;
    }

    setRequiredField(){
      if (this.requiredField == 'yes') {
        this.props.required = true;
      }
      else if(this.requiredField == 'no'){
        this.props.required = false;
      }
    }

    ngOnChanges(): void {
      if(this.disableUserInteraction){
        this.showDropdown = false;
      }
      this.filteredOptions = this.dataSets;
      if(this.filteredOptions.length){
        this.loading = false;  
      }else{
        this.loading = false;  
      }
      this.isActive = true;            
      this.props.disable = this.disable; 
      this.setRequiredField();
      if(this.props.disable) {
        this.removeAllTag();
        this.checkError=false;
      }
    }
  toggleDropdown() {
    this.showDropdown = !this.showDropdown;
    if(this.defaultDataSet.length){
      this.filteredOptions = this.defaultDataSet;
      this.isActive = true;      
    }
    else {
      this.isActive = false;
    }
    if(this.title == "APIs") 
    this.isActive = true;     
   
  }
  removeTag(option: any){
    this.selectedOptions = this.selectedOptions.filter((item: any) => item.id.toLowerCase() !== option.id.toLowerCase());
    this.control.setValue(this.selectedOptions);    
    this.checkError=true;
  }
  removeAllTag(){
    this.selectedOptions = [];
    this.control.setValue(this.selectedOptions);
    this.checkError=true;
  }

  sendData(event: any){
    this.checkError=true;
    let searchValue:String=event.target.value.toLowerCase()??"";
    let extraSpaceTrimmedSearchValue=(searchValue.trimStart()).trimEnd();
    if(extraSpaceTrimmedSearchValue.length>0){
      this.loading = true;   
      this.search.emit(extraSpaceTrimmedSearchValue);
    } 
  }

  onChange(option: any, event: any) {
      if (event.target.checked) {
          this.selectedOptions.push(option);
          this.control.setValue(this.selectedOptions);
      } else {
          let index = this.selectedOptions.indexOf(option);
          if (index > -1) {
              this.selectedOptions.splice(index, 1);
          }else{
            this.selectedOptions = this.selectedOptions.filter((obj:any) => obj.id.toLowerCase() !== option.id.toLowerCase());
          }
          this.control.setValue(this.selectedOptions); 
      }
      this.changed.emit(this.selectedOptions);
  }
  @HostListener('document:click', ['$event'])
  handleClickOutside(event: any) {  
    
    if (!this.elementRef.nativeElement.contains(event.target) && event.target.id !== this.id) {
      this.showDropdown = false;
      this.isActive = false;
    }
  }
}



