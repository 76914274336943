import { Component, HostBinding, Inject, OnDestroy, OnInit } from '@angular/core';
import { Router, Event, NavigationEnd } from '@angular/router';
import { MsalBroadcastService, MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionType, PopupRequest, RedirectRequest } from '@azure/msal-browser';
import { Observable, Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { StateService } from 'src/app/shared/services/state.service';
import {AppEnvironmentLink} from "../../shared/models/app-environment-link"
import {UserRoleEnum,ApiCatalogRoleEnum} from '../enum/UserRoleEnum'
import {InternalApiCatalogUrlConstant,ProductionApiCatalogUrlConstant} from "../constant/ApiCatalogUrlConstant"
import { Hip2ManagementService } from 'src/app/shared/services/hip2-management.service';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  sideMenu: any = []; 

  developerMenu : any = [
  // {
  //   "icon": "./assets/dashboard.svg",
  //   "title": "Dashboard",
  //   "url": "/dashboard",
  //   "isActive":false,
  //   "isUserCollapsedMenu":true,
  //   "subMenu": []
  // },
  {
    "icon": "./assets/sideNav/developer.svg",
    "title": "Developer",
    "isActive":false,
    "isUserCollapsedMenu":true,
    "subMenu": [
      {
        "icon": "./assets/sideNav/products.svg",
        "title": "Products & APIs",
        "url": "/developer/all-products"
      },
      {
        "icon": "./assets/sideNav/my-subscription.svg",
        "title": "My Subscriptions",
        "url": "/developer/my-subscriptions"
      }
    ]
  }];
  developerWithCMDBOwnerMenu : any = [
    {
      "icon": "./assets/sideNav/developer.svg",
      "title": "Developer",
      "isActive":false,
      "isUserCollapsedMenu":true,
      "subMenu": [
        {
          "icon": "./assets/sideNav/products.svg",
          "title": "Products & APIs",
          "url": "/developer/all-products"
        },
        {
          "icon": "./assets/sideNav/my-subscription.svg",
          "title": "My Subscriptions",
          "url": "/developer/my-subscriptions"
        },
        {
          "icon":"./assets/sideNav/manage-subs.svg",
          "title": "Manage Subscriptions",
          "url": "/developer/manage-subscriptions"
        }
      ]
    }];
  publisherMenu :any = [
    {
      "icon": "./assets/sideNav/publisher.svg",
      "title": "Publisher",
      "isActive":false,
      "isUserCollapsedMenu":true,
      "subMenu": [
        {
          "icon": "./assets/sideNav/manage-prod.svg",
          "title": "Manage Products",
          "url": "/publisher/manage-products"
        },
        {
          "icon": "./assets/sideNav/manage-subs.svg",
          "title": "Manage Subscriptions",
          "url": "/publisher/manage-subscriptions"
        },
        {
          "icon": "./assets/sideNav/manage-api.svg",
          "title": "Manage APIs",
          "url": "/publisher/manage-apis"
        },
        {
          "icon": "./assets/sideNav/my-approval.svg",
          "title": "My Approvals",
          "url": "/publisher/my-approvals"
        },
        //Need Health monitor only in future sprint
        // {
        //   "icon": "./assets/sideNav/health-monitor.svg",
        //   "title": "Health Monitor",
        //   "url": "/publisher/health-monitor"
        // }
      ]
    }];  

  approverMenu :any = [
    {
      "icon": "./assets/sideNav/publisher.svg",
      "title": "Publisher",
      "isActive":false,
      "isUserCollapsedMenu":true,
      "subMenu": [        
        {
          "icon": "./assets/sideNav/manage-subs.svg",
          "title": "Manage Subscriptions",
          "url": "/publisher/manage-subscriptions"
        },        
        {
          "icon": "./assets/sideNav/my-approval.svg",
          "title": "My Approvals",
          "url": "/publisher/my-approvals"
        }        
      ]
    }];  

  isActive: boolean = false;  
  loginDisplay = false;  
  private readonly _destroying$ = new Subject<void>();  
  userName: string;
  userRoleList:Array<String>=[];
  assignedUserRole!: string;
  isUserCmdbOwner:boolean=false;
 appInternalEnvironmentList: Array<AppEnvironmentLink> =[{
  envName: 'INTERNAL DEV',
  envLink: InternalApiCatalogUrlConstant.developmentPortalLink,
  secondaryEnvironmentLink: ''
  },
 {
 envName: 'INTERNAL TST',
 envLink: InternalApiCatalogUrlConstant.testPortalLink,
 secondaryEnvironmentLink: ''
 }
 ];

 appProductionEnvironmentList: Array<AppEnvironmentLink> =[
 {
  envName: 'DEVELOPMENT',
  envLink: ProductionApiCatalogUrlConstant.developmentPortalLink,
  secondaryEnvironmentLink: ProductionApiCatalogUrlConstant.developmentPortalSecondaryLink
 },
 {
  envName: 'TEST',
  envLink: ProductionApiCatalogUrlConstant.testPortalLink,
  secondaryEnvironmentLink: ProductionApiCatalogUrlConstant.testPortalSecondaryLink
 },
 {
  envName: 'PRODUCTION',
  envLink: ProductionApiCatalogUrlConstant.prodPortalLink,
  secondaryEnvironmentLink: ProductionApiCatalogUrlConstant.prodPortalSecondaryLink
 },
 ];

  constructor(@Inject(MSAL_GUARD_CONFIG) private msalGuardConfig: MsalGuardConfiguration,
  private msalBroadcastService: MsalBroadcastService,
  private authService: MsalService,    
  private stateService: StateService,    
  private router: Router,
  private hipService:Hip2ManagementService,
  @Inject(Window) private win: Window) {
  this.userName = this.stateService.Account?.name ?? "";  
  this.userRoleList=this.stateService.Account?.idTokenClaims?.roles??[];
  this.getUserRole(this.userRoleList);
  router.events
  .pipe(filter((routerEvent: Event) => routerEvent instanceof NavigationEnd))
  .subscribe(() => this.resetScroll());
}
ngOnDestroy(): void {
   this._destroying$.next();
   this._destroying$.complete();
}

  ngOnInit(): void {
    this.msalBroadcastService.msalSubject$
    .pipe(
      // Optional filtering of events.
      filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS), 
      takeUntil(this._destroying$)
    )
    .subscribe((result: EventMessage) => {
      this.checkAccount();
      this.userName = this.stateService.Account?.name ?? "";
      this.userRoleList=this.stateService.Account?.idTokenClaims?.roles??[];
      this.getUserRole(this.userRoleList);
    });
  }

  resetScroll(){    
    const BodyElement: HTMLElement | null = document.getElementById('bodyContent');
    if(BodyElement != null ){
      BodyElement.scrollTop = 0;
    }
  }

  checkAccount() {
    this.loginDisplay = this.authService.instance.getAllAccounts().length > 0;
  }

  login() {
    if (this.msalGuardConfig.interactionType === InteractionType.Popup) {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginPopup({ ...this.msalGuardConfig.authRequest } as PopupRequest)
          .subscribe(() => this.checkAccount());
      } else {
        this.authService.loginPopup()
          .subscribe(() => this.checkAccount());
      }
    } else {
      if (this.msalGuardConfig.authRequest) {
        this.authService.loginRedirect({ ...this.msalGuardConfig.authRequest } as RedirectRequest);
      } else {
        this.authService.loginRedirect();
      }
    }
  }

  logout() {
    this.authService.logout();
  }     

  public changeClass(value: boolean) {
    this.isActive = value
  }

  getUserRole(roleList:Array<String>){
    this.hipService.getIsValidCmdbOwner().subscribe((response)=>{
    this.isUserCmdbOwner=response;
    let roleListUpdated= roleList.map((role)=>role.toLocaleLowerCase());
    this.sideMenu = this.isUserCmdbOwner?this.developerWithCMDBOwnerMenu :this.developerMenu;
    if(roleListUpdated.includes((ApiCatalogRoleEnum.ADMIN).toLocaleLowerCase())){
      this.assignedUserRole=UserRoleEnum.ApiCatalogAdmin;
      this.sideMenu = this.sideMenu.concat(this.publisherMenu);
    } 
    else if (roleListUpdated.includes((ApiCatalogRoleEnum.PUBLISHER).toLocaleLowerCase()))
    {
      this.assignedUserRole=UserRoleEnum.ApiCatalogPublisher;
      this.sideMenu = this.sideMenu.concat(this.publisherMenu);
    } 
    else if (roleListUpdated.includes((ApiCatalogRoleEnum.APPROVER).toLocaleLowerCase()))
    {
      this.assignedUserRole=UserRoleEnum.ApiCatalogApprover;
      this.sideMenu = this.sideMenu.concat(this.approverMenu);
    } 
    else if(roleListUpdated.includes((ApiCatalogRoleEnum.DEVELOPER).toLocaleLowerCase()))
    {
      this.assignedUserRole=UserRoleEnum.ApiCatalogDeveloper      
    }
    this.stateService.setUserRole(this.assignedUserRole);
    })
    
  }
}
