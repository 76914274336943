import { FormControl } from '@angular/forms';
import { Component, EventEmitter, Input, Output, OnInit, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-slider-switch',
  templateUrl: './slider-switch.component.html',
  styleUrls: ['./slider-switch.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SliderSwitchComponent implements OnInit {
   @Input() allowTracing: any = {allowTracing:false};
  @Output() allowTracingChange = new EventEmitter<boolean>();

  @Input() props: any = {};
  @Input() option: any = {}; 
  @Input() disable: boolean = true; 
 
  @Output() checkedChange = new EventEmitter<boolean>(); 
  @Input() toggleDisabled:boolean = false;
  

  control = new FormControl('');

  isChecked: boolean = false;
  isreview: boolean = false;

  ngOnInit(): void {
    this.control.valueChanges.pipe(debounceTime(500)).subscribe((value: string | null) => {
     
      if (value !== null) {
        this.checkedChange.emit(value === 'true' || value === '1'); 
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
  }

  toggleAllowTracing(): void {
    if(!this.toggleDisabled){
    this.allowTracing.allowTracing = !this.allowTracing.allowTracing; // Toggle the state
    this.allowTracingChange.emit(this.allowTracing.allowTracing); 
  }
  }
}
