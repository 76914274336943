import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { debounceTime } from 'rxjs';

@Component({
  selector: 'app-radio-buttons',
  templateUrl: './radio-buttons.component.html',
  styleUrls: ['./radio-buttons.component.scss']
})
export class RadioButtonsComponent implements OnInit {
  @Input() props : any = [];
  // @Input() FormGroup: FormGroup = new FormGroup({});
  @Input() control= new FormControl('');
  @Input() hori_align: boolean = false;
  @Input() checked: any = '';
  @Input() type: string = '';
  @Output() EnableEvent = new EventEmitter<any>();
  isreview : boolean = false;
  @Input() isOverrideLabelStyle:boolean = false;
  @Input() isDisableRadioButtonEnabled:boolean = false;
  
  ngOnInit(): void {
    this.hori_align
    if(this.type == 'review'){
      this.isreview = true;
    }
    // We want to run a function on control value change, We can do so by using valueChanges observables.
    this.control.valueChanges.pipe(debounceTime(500)).subscribe(value => {
      this.EnableEvent.emit(value);
    });
  }

  ngOnChanges() {
    if(this.checked.length){
     this.control.setValue(this.checked);

    }
  }

}
