export enum UserRoleEnum{
    ApiCatalogDeveloper = "DEVELOPER",
    ApiCatalogAdmin = "ADMIN",
    ApiCatalogPublisher = "PUBLISHER",
    ApiCatalogApprover = "APPROVER",
}
export enum ApiCatalogRoleEnum{
    DEVELOPER = "APICatalogDevelopers",
    ADMIN = "APICatalogAdmins",
    PUBLISHER = "APICatalogPublishers",
    APPROVER = "APICatalogApprovers"
}

