import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';

@Component({
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss']
})
export class CheckboxComponent implements OnInit {
  @Input() selectedOptions: any[] = [];
  @Input() label: string = '';
  @Input() id: string = '';
  checkedOptions: any[] = [];
  @Output() changeEvent = new EventEmitter<any>();
  @Input() isCustomLabelEnabled = false;
  @Input() customOptionLabel:string = '';
  @Input() isCheckBoxRenderedInsideList:boolean=true;
  @Input() infoDetail:string=''

ngOnInit(): void {
  this.id ;
  this.label;
  this.checkedOptions = this.selectedOptions.map(obj => {return obj.label});
 
}
}
