<header>
  <nav class="navbar navbar-expand-xs">
      <div class="container-fluid">
          <span class="logo-wrapper">
              <button class="mobile-sidetoggle-btn" (click)="toggleClose()" >
                  <img [class.open]="isActive" src="./assets/nav/slide_open_mobile.svg" alt="" />
              </button>
              <div class="logo-image-text-wrapper">
                  <img class="logo-image" src="./assets/nav/hip-logo.svg" />
                  <div class="text">
                      Hybrid Integration&nbsp;Platform<br /><span class="sub-text"
                         
                          >Version | 2.0</span
                      >
                  </div>
              </div>
              <button id="button" class="desktop-sidetoggle-btn" (click)="toggleClose()" >
                  <img [class.open]="isActive" src="./assets/nav/slide_open.svg" alt="" />
              </button>
              
              <span class="vertical-line"></span>
          </span>
          <span class="mobile-notify-wrapper">
              <button id="button" class="mobile-sidetoggle-btn" >
                  <img src="./assets/nav/notification.svg" alt="" />
              </button>
              <button id="button" class="mobile-sidetoggle-btn" >
                  <img src="./assets/nav/more_icon.svg" alt="" />
              </button>
          </span>
          <span class="bread-crumb">
              <span class="vertical-line-left"></span>
              <div class="text">
                  API
                  <br />
                  <span class="sub-text">Catalog</span>
              </div>
              <span class="vertical-line-right"></span>
          </span>

          <span class="nav-content-wrapper">
          
                  <li>
                      <span class="vertical-line-left"></span>
                          <a href="https://ac-hip-cookbook.azurewebsites.net" target="_blank" class="flex-box">
                            <div class="text">
                                User Guide
                                <br />
                          <span class="sub-text dropdown-text">COOKBOOK</span>
                        </div>
                          </a>
                      <span class="vertical-line-right"></span>
                  </li>
                  <li  (click)="toggleAppEnvironemntDropDown()">
                        <div class="text">
                            App Environment
                            <br/>
                            <div class="listDropdown">
                            <span class="sub-text">{{currentEnvironment}}</span>
                            &nbsp;
                            <img  src="../../../../assets/down-arrow.svg" class="imagePadding">
                            <div class="result" [class.active]="showEnvironmentDropDown" *ngIf="!isProdEnv">
                            <ng-container *ngFor="let environment of internalAppEnvironmentList">
                                <p [class.select]="currentEnvironment==environment.envName">
                                <ng-container *ngIf="currentEnvironment==environment.envName; else unSelectedEnvironmentData">
                                    {{environment.envName}}
                                </ng-container>
                                <ng-template #unSelectedEnvironmentData>
                                    <a href="{{environment.envLink}}" class="flex-box">
                                        {{environment.envName}}</a>
                                </ng-template>
                                </p>
                            </ng-container>
                            </div>
                            <div class="result" [class.active]="showEnvironmentDropDown" *ngIf="isProdEnv">
                            <ng-container *ngFor="let environment of prodAppEnvironmentList">
                                <p [class.select]="currentEnvironment==environment.envName">
                                <ng-container *ngIf="currentEnvironment==environment.envName; else unSelectedEnvironmentData">
                                    {{environment.envName}}
                                </ng-container>
                                <ng-template #unSelectedEnvironmentData>
                                    <a href="{{environment.envLink}}" class="flex-box">
                                        {{environment.envName}}</a>
                                </ng-template>
                                </p>
                            </ng-container>
                            </div>
                        </div>
                        </div>
                  </li>
                  <!-- commenting the below lines as we need to implement notification in future
                    <li> 
                      <div class="text-notification"><img src="./assets/nav/notification.svg"><span class="text-notification-count">03</span></div>
                      <span class="vertical-line-right"></span>
                  </li> -->
                  <span class="vertical-line-right"></span>
                  <li (click)="toggleUserActivityDropDown()">
                      <img class="round-avatar" src="./assets/nav/avatar.svg">
                      <div class="profile-text">{{userName}}<br/>
                        <div class="profile-dropdown">
                        <span class="sub-text dropdown-text">{{userRole}}</span>
                        &nbsp;
                        <img  src="../../../../assets/down-arrow.svg" class="imagePadding">
                        </div>
                        <div class="result" [class.active]="showUserActivityDropDown">
                            <ng-container *ngFor="let activity of userActivityList"><p (click)="manageUserActivity(activity)">{{activity}}</p></ng-container>
                        </div>
                      </div>
                  </li>
              
          </span>
      </div>
  </nav>
</header>
