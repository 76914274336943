import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { StateService } from '../services/state.service';
import { ApiCatalogRoleEnum } from 'src/app/core/enum/UserRoleEnum';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private stateService: StateService){}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean{
    let menu=route.data?.['role']??[];
    console.log("menu",menu)
    let userHasAccess:boolean = false;
    let userRoleList=this.stateService.Account?.idTokenClaims?.roles??[];
    if(userRoleList.length<1){
      return true;
    }
    userRoleList=userRoleList.map((value)=>value.toLocaleLowerCase())
    menu.forEach((element: string) => {
      if(userRoleList.includes(element.toLocaleLowerCase())){
        userHasAccess=true;
      }
    });
      if(!userHasAccess){
      this.router.navigate(['/unauthorized'])
      return false;
    }else{
      return true;
    }
  }
  
}
