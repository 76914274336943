<div #bodyWrapper>
  <app-bread-crumb [links]="[
  {
    'href': '/publisher/manage-apis',
    'title': 'Manage APIs'
  },
  {
    'href': '/publisher/view-api/'+this.breadCrumbApiId,
    'title': ' '+this.breadCrumbApiName
  },
  {
    'href':  '/publisher/api-definition/'+this.breadCrumbApiId,   
    'title': 'API Details'           
  }
  ]" [buttonProps]="btnProps"      
  (breadCrumbClickEvent)="downloadAPISpec($event)">
  </app-bread-crumb>       
  <app-general-loader *ngIf="loaderActive"></app-general-loader>
  <section class="content-section" #contentSection>
<div class="api-definition-wrapper">
    <div class="head-section">
        <div class="head-content">            
            <h2 class="title">API Details</h2>
            <div class="row">
            <p class="col-12 col-sm">Please find the Open API specification below with all available endpoints and operations.</p>
            <span class="button-wrapper col-12 col-sm" *ngIf="!loaderActive">
            <app-label [label]="internalApi" [copyMessage]="copyInternalMessage" [copyTextValue]="internalAPIvalue" ></app-label>
            <app-label [label]="externalApi" [copyMessage]="copyExternalMessage" [copyTextValue]="externalAPIValue" *ngIf="isExternal"></app-label>
            </span>
            </div>
        </div>
    </div>    
    <br/>
    <br/>  
    <div id="api-definition-ui" style="margin-top: -2.0rem;"></div>
    <div *ngIf="!loaderActive && noDataFound"><h2 class="title"> No Open API specification available </h2></div>
</div>
</section>

</div>