import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Observable, Subscription, map, lastValueFrom } from 'rxjs';
import { StateService } from './state.service';
import { Environment } from '../../../environments/environment';
import { ApiConstants } from './service-constants';
import { ApisByProductId } from '../models/apis-by-productid';
import { ProductsByApiId } from '../models/products-by-apiid';
import { Product } from '../../pages/products/models/product';
import { ProductApis } from '../../pages/products/models/product-apis';
import { CreateDraftProductModel, CreateProductModel, ProductModel } from '../../pages/models/product-model';
import { DeleteProduct } from '../../pages/products/models/product-delete';
import { Api } from '../../pages/apis/models/api';
import { ApiByName } from '../models/api-by-name';
import { CreateApi } from '../../pages/apis/models/api-create';
import { DeleteApi } from '../../pages/apis/models/api-delete';
import { ApimOperations } from '../../pages/publisher/models/apim-operations';
import { ApimOperationsByApiName } from '../models/apimoperations-by-apiname';
import { UpsertApi } from '../../pages/publisher/models/upsert-api';
import { CmdbRequest} from '../../pages/models/cmdb-request';
import { CmdbDetails } from '../../pages/models/cmdb-details';
import { CmdbApplications } from '../../pages/models/cmdb-details';
import {SubscriptionRequest} from '../../pages/subscriptions/models/subscriptions-api';
import {SubscriptionPutRequest} from '../../pages/subscriptions/models/subscription-put-api';
import {RejectSubscriptionRequest} from '../../pages/subscriptions/models/subscription-reject-api';
import {SubscriptionApproveRequest} from '../../pages/subscriptions/models/subscription-approve';
import {SubscriptionEmailRequest} from '../../pages/subscriptions/models/subscription';
import {SubscriptionKeyRequest} from '../../pages/subscriptions/models/subscription';
import {ApisForSubscriptionRequest} from '../../pages/subscriptions/models/subscription';
import {SubscriptionsByApiRequest} from '../../pages/subscriptions/models/subscription'
import { ManageProductList } from 'src/app/pages/products/models/manage-product-list';
import { ProductDetails } from 'src/app/pages/products/models/product-details';
import { ApiList } from 'src/app/pages/apis/models/api-list';
import { ApiModel } from 'src/app/pages/apis/models/api-model';
import { ApisList } from 'src/app/pages/products/models/apis-list';
import { MySubscriptionList } from 'src/app/pages/subscriptions/models/mySubscription-list';
import { ApiWithSubscriptionsInfo, SubscriptionApis } from 'src/app/pages/subscriptions/models/viewSubscription-model';
import { ProductSubscriptionDetail, ProductSubscriptionDetailList } from 'src/app/pages/products/models/product-subscription-detail';
import { SubscriptionApproverList } from '../models/subscription-approver-list';
import { SubscriptionAttributeListModel } from 'src/app/pages/models/subscription-attribute-model';
import { HIPEligibleApplication } from 'src/app/pages/models/hip-application-model';
import { ApplicationOwnersWithValidation } from '../models/app-owner-list-validation';
import { NonHipAppRegistration } from '../models/non-hip-app-registration';
import { NotifySubscription } from 'src/app/pages/subscriptions/models/notify-subscription';
import { IHttpFileResponse, IQueryHttpFileByApim } from './query-httpfile-by-apim';
import { Environments } from 'src/app/types/Environments';
import { TracingModel } from '../models/tracing-model';
import { SubscriptionCancelRequest } from 'src/app/pages/subscriptions/models/subscription-cancel';
import { SubscriptionReactivateRequest } from 'src/app/pages/subscriptions/models/subscription-reactivate';
import { DeleteSubscriptionRequest } from 'src/app/pages/subscriptions/models/subscription-delete';



@Injectable({
  providedIn: 'root'
})
export class Hip2ManagementService {
 

  constructor(private http: HttpClient) { }

  getProductsAndApis(businessAreaCode: string, filter = '', sortOrder = 'asc', pageNumber = 1,
    pageSize = 50, apiVersion:string = '1.0'): Observable<ManageProductList> {
    return this.http.get<any>(this.getUrl(ApiConstants.API_Publisher_GetProductsAndApis), {
      params: new HttpParams()
        .set('businessAreaCode', businessAreaCode)
        .set('isDeleted', false)
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('api-version',apiVersion)
    }).pipe(
      map(res => res));
  }


  getProductSubscription(apimProductId:string,businessAreaCode: string, filter = '', sortOrder = 'asc', pageNumber = 1,
  pageSize = 200, apiVersion:string = '1.0',state = ""): Observable<ProductSubscriptionDetailList> {
  return this.http.get<ProductSubscriptionDetailList>(this.getUrl(ApiConstants.API_Publisher_ProductSubcription), {
    params: new HttpParams()
      .set('apimProductId', apimProductId)
      .set('businessAreaCode', businessAreaCode)
      .set('isDeleted', false)
      .set('filter', filter)
      .set('sortOrder', sortOrder)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('api-version',apiVersion)
      .set('State',state)
  }).pipe(
    map(res => res));
}

  getAllProducts(businessAreaCode: string, filter = '', sortOrder = 'asc', pageNumber = 1,
  pageSize = 50, apiVersion:string = '1.0'): Observable<ManageProductList> {
  return this.http.get<any>(this.getUrl(ApiConstants.API_Publisher_GetAllProducts), {
    params: new HttpParams()
      .set('businessAreaCode', businessAreaCode)
      .set('isDeleted', false)
      .set('filter', filter)
      .set('sortOrder', sortOrder)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('api-version',apiVersion)
  }).pipe(
    map(res => res));
}
 getTotalProducts() {
    return lastValueFrom(this.http.get<number>(this.getUrl(ApiConstants.API_GetTotalProducts),
    ).pipe(
      map(res => res)
    ), { defaultValue: 0 });
  }

  getApisByProductId(productId: string): Observable<ProductApis[]> {
    const paramApisByProductId: ApisByProductId = {
      productId: productId
    };

    return this.http.get<ProductApis[]>(this.getUrl(ApiConstants.API_GetApisByProductId),
      { params: paramApisByProductId });
  }
  getApisByApimProductId(apimProductId: string): Observable<ProductApis[]> {
    
    return this.http.get<ProductApis[]>(this.getUrl(ApiConstants.API_GetApisByApimProductId), {
        params: new HttpParams()
            .set('apimProductId', apimProductId)
    });
  }

  getSubscriptionApisByApimProductId(apimProductId: string): Observable<SubscriptionApis[]> {
    
    return this.http.get<SubscriptionApis[]>(this.getUrl(ApiConstants.getSubscriptionApisByApimProductId), {
        params: new HttpParams()
            .set('apimProductId', apimProductId)
    });
  }

    getSubscriptionApisByApimProductIdWithSubscriptionAttributes(apimProductId: string): Observable<ApiWithSubscriptionsInfo[]> {
    
    return this.http.get<ApiWithSubscriptionsInfo[]>(this.getUrl(ApiConstants.getSubscriptionApisByApimProductId), {
        params: new HttpParams()
            .set('apimProductId', apimProductId)
    });
  }

  getDeveloperSubscriptionApisByApimProductId(apimProductId: string): Observable<SubscriptionApis[]> {
    
    return this.http.get<SubscriptionApis[]>(this.getUrl(ApiConstants.getDeveloperSubscriptionApisByApimProductId), {
        params: new HttpParams()
            .set('apimProductId', apimProductId)
    });
  }
  getSubscriptionApisByApimProductIdSubscriptionName(apimProductId: string, subscriptionName: string): Observable<ApiWithSubscriptionsInfo[]>{
      return this.http.get<ApiWithSubscriptionsInfo[]>(this.getUrl(ApiConstants.getApisAndSubscriptionAttributeByProductId),{
        params: new HttpParams()
        .set('apimProductId', apimProductId)
        .set('subscriptionName', subscriptionName)
        .set('api-version','1.0')
      })
  }
  getSubscriptionsStatistics(apiVersion:string,apimProductId: string): Observable<ProductSubscriptionDetail> {    
    return this.http.get<ProductSubscriptionDetail>(this.getUrl(ApiConstants.API_GetSubscriptionsStatistics), {
        params: new HttpParams()
            .set('productId', apimProductId)
    });
  }
  
  getProductByProductIdPublisherSection(apimProductId: string): Observable<any> {
    
    return this.http.get<any>(this.getUrl(ApiConstants.API_ViewProductPublisherSection), {
        params: new HttpParams()
            .set('apimProductId', apimProductId)
    });
}
getDeveloperProductByProductId(apimProductId: string): Observable<any> {
    
  return this.http.get<any>(this.getUrl(ApiConstants.API_GetProductsByProductId), {
      params: new HttpParams()
          .set('apimProductId', apimProductId)
  });
}

getProductByProductIdDeveloperSection(apimProductId: string): Observable<any> {
    
  return this.http.get<any>(this.getUrl(ApiConstants.API_ViewProductDeveloperSection), {
      params: new HttpParams()
          .set('apimProductId', apimProductId)
  });
}

getSubscriptionBySubscriptionName(subscriptionName: string,apimProductId: string): Observable<any> {
  return this.http.get<any>(this.getUrl(ApiConstants.API_GetApiSubscriptionGetSubscriptionBySubscriptionName), {
      params: new HttpParams()
          .set('subscriptionName', subscriptionName)
          .set('apimProductId', apimProductId)
  });
}

getDeveloperSubscriptionBySubscriptionName(subscriptionName: string,apimProductId: string): Observable<any> {
  return this.http.get<any>(this.getUrl(ApiConstants.API_GetDeveloperSubscriptionBySubscriptionName), {
      params: new HttpParams()
          .set('subscriptionName', subscriptionName)
          .set('apimProductId', apimProductId)
  });
}

getPendingApprovalDetails(subscriptionName: string,apimProductId: string): Observable<any> {
  return this.http.get<any>(this.getUrl(ApiConstants.API_GetPendingApprovalDetails), {
      params: new HttpParams()
          .set('subscriptionName', subscriptionName)
          .set('apimProductId', apimProductId)
  });
}
getTagsByProductId(apimProductId: string): Observable<any> {
    
  return this.http.get<any>(this.getUrl(ApiConstants.API_ProductTags), {
      params: new HttpParams()
          .set('apimProductId', apimProductId)
  });
}


getAdAppRegistrationNameByAppId(appRegistrationId: string): Observable<any> {
    
  return this.http.get<any>(this.getUrl(ApiConstants.API_GetADApplicationById), {
      params: new HttpParams()
          .set('appId', appRegistrationId)
  });
}

  getProductsByApiId(apiId: string): Observable<Product[]> {
    const paramProductsByApiId: ProductsByApiId = {
      apiId: apiId
    };

    return this.http.get<Product[]>(this.getUrl(ApiConstants.API_GetProductsByApi), { params: paramProductsByApiId });
  }

  createProduct(product: ProductModel): Observable<any> {
    return this.http.post<any>(this.getUrl(ApiConstants.API_CreateProduct),
      product);
  }

  updateProduct(product: ProductModel): Observable<any> {
    return this.http.put(this.getUrl(ApiConstants.API_UpdateProduct),
      product);
  }

  deleteProduct(product: DeleteProduct): Observable<any> {
    let httpParams = new HttpParams()
      .set('productId', product.productId)
      .set('isDeletedBy', product.isDeletedBy);
    let options = { params: httpParams };
    return this.http.delete(this.getUrl(ApiConstants.API_DeleteProduct), options);
  }

  getApis(filter = '', sortOrder = 'asc', pageNumber = 1,
    pageSize = 50, businessAreaCode =''): Observable<ApiList> {

    return this.http.get<ApiList>(this.getUrl(ApiConstants.API_GetApis), {
      params: new HttpParams()
        .set('isDeleted', false)
        .set('filter', filter)
        .set('sortOrder', sortOrder)
        .set('pageNumber', pageNumber.toString())
        .set('pageSize', pageSize.toString())
        .set('BusinessAreaCode',businessAreaCode)
    }).pipe(
      map(res => res)
    );
  }


  getSubscriptionsApprovalsList(filter = '', sortOrder = 'asc', pageNumber = 1,
  pageSize = 200, businessAreaCode =''): Observable<SubscriptionApproverList> {

  return this.http.get<SubscriptionApproverList>(this.getUrl(ApiConstants.API_GetSubscriptionApprovalList), {
    params: new HttpParams()
      .set('isDeleted', false)
      .set('filter', filter)
      .set('sortOrder', sortOrder)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('BusinessAreaCode',businessAreaCode)
      .set('api-version','1.0')
      .set('State','')
  }).pipe(
    map(res => res)
  );
}


  getSubscriptionsbyUserEmail(filter = '', sortOrder = 'asc', pageNumber = 1,
  pageSize = 50, businessAreaCode ='',state=''): Observable<MySubscriptionList> {

  return this.http.get<MySubscriptionList>(this.getUrl(ApiConstants.API_GetSubscriptionsbyUser), {
    params: new HttpParams()
      .set('isDeleted', false)
      .set('filter', filter)
      .set('sortOrder', sortOrder)
      .set('pageNumber', pageNumber.toString())
      .set('pageSize', pageSize.toString())
      .set('BusinessAreaCode',businessAreaCode)
      .set('State',state)
  }).pipe(
    map(res => res)
  );
}
  getAllApimApis(cmdbId:string): Observable<any> {
    let httpParams = new HttpParams()
        .set('cmdbId', cmdbId);      
    let options = { params: httpParams };
    return this.http.get<any>(this.getUrl(ApiConstants.API_GetAllApimApis), options)      
    .pipe(
      map(res => res)
    );
  }

  getTotalApis() {
    return lastValueFrom(this.http.get<any>(this.getUrl(ApiConstants.API_GetTotalApis)).pipe(
      map(res => res.Value)
    ),{ defaultValue: 0 })
  }

  getApiByName(apiName: string): Observable<Api> {
    const paramApiName: ApiByName = {
      name:apiName
    };

    return this.http.get<Api>(this.getUrl(ApiConstants.API_GetApiByName), { params: paramApiName });
  }

  createApi(api: CreateApi): Observable<any> {
    return this.http.post<any>(this.getUrl(ApiConstants.API_CreateApi), api);
  }

  updateApi(api: CreateApi): Observable<any> {
    return this.http.put(this.getUrl(ApiConstants.API_UpdateApi),
      api);
  }

  deleteApi(api: DeleteApi): Observable<any> {
    let httpParams = new HttpParams()
      .set('apiId', api.apiId)
      .set('isDeletedBy', api.isDeletedBy);
    let options = { params: httpParams };
    return this.http.delete(this.getUrl(ApiConstants.API_DeleteApi), options);
  }

  getApimOperationsByApiName(apiName: string): Observable<ApimOperations> {
    const paramApimOperations: ApimOperationsByApiName = {
      apimApiName: apiName
    };

    return this.http.get<ApimOperations>(this.getUrl(ApiConstants.API_GetApiOperations), { params: paramApimOperations });
  }

  getApimProductsByApiId(apiId: string): Observable<string[]> {
    const paramApimProducts: ProductsByApiId = {
      apiId: apiId
    };

    return this.http.get<string[]>(this.getUrl(ApiConstants.API_GetApimProductsByApi), { params: paramApimProducts });
  }

  getProductDetailsByProductId(apimProductId: string): Observable<ProductDetails> {
    let httpParams = new HttpParams()
      .set('apimProductId', apimProductId);      
    let options = { params: httpParams };

    return this.http.get<ProductDetails>(this.getUrl(ApiConstants.API_GetProductsByProductId), options)
    .pipe(
      map(res => res)
    );
  }

  upsertApi(api: ApiModel): Observable<any> {
    return this.http.put(this.getUrl(ApiConstants.API_UpsertApi),
      api);
  }

  editApi(api: ApiModel): Observable<any> {
    return this.http.put(this.getUrl(ApiConstants.API_EditAPI),
      api);
  }
  editProduct(product: ProductModel): Observable<any> {
    return this.http.put<any>(this.getUrl(ApiConstants.API_EditProduct),
      product);
  }
  addProduct(product: CreateProductModel): Observable<any> {
    return this.http.post<any>(this.getUrl(ApiConstants.API_AddProduct),
      product);
  }
  addDraftProduct(product: CreateDraftProductModel): Observable<any> {
    return this.http.post<any>(this.getUrl(ApiConstants.API_AddDraftProduct),
      product);
  }
  saveDraftProduct(product: CreateDraftProductModel): Observable<any> {
    return this.http.put<any>(this.getUrl(ApiConstants.API_SaveDraftProduct),
      product);
  }
  updateSubscriptionAttribute(subscriptionAttributeListModel: SubscriptionAttributeListModel): Observable<any>{
    return this.http.put<any>(this.getUrl(ApiConstants.API_UpdateSubscriptionAttribute),subscriptionAttributeListModel);
  }

  approveSubscription(subscriptionAttributeListModel: SubscriptionAttributeListModel): Observable<any>{
    return this.http.post<any>(this.getUrl(ApiConstants.API_ApproveSubscription),subscriptionAttributeListModel);
  }

  deleteApimProduct(apimProductId: string): Observable<boolean> {
    let httpParams = new HttpParams()
      .set('apimProductId', apimProductId);      
    let options = { params: httpParams };
    return this.http.delete<boolean>(this.getUrl(ApiConstants.API_DeleteApimProduct), options);
  }

  deleteApimApi(apimApiId: string): Observable<boolean> {
    let httpParams = new HttpParams()
      .set('apimApiId', apimApiId);
    let options = { params: httpParams };
    return this.http.delete<boolean>(this.getUrl(ApiConstants.API_DeleteApimApi), options);
  }

  deleteApimSubscription(apimSubscriptionName: string): Observable<any> {
    let httpParams = new HttpParams()
      .set('subscriptionName', apimSubscriptionName);
    let options = { params: httpParams };
    return this.http.delete(this.getUrl(ApiConstants.API_DeleteApimSubscription), options);
  }

  getApimApisByProduct(apimProductId: string): Observable<boolean> {
    let httpParams = new HttpParams()
      .set('apimProductId', apimProductId);      
    let options = { params: httpParams };
    return this.http.get<boolean>(this.getUrl(ApiConstants.API_GetApimApisByProduct), options);
  }
   
  getApiSpecification(apimApiId: string): Observable<any> {
    let httpParams = new HttpParams()
      .set('apimApiId', apimApiId);
    let options = { params: httpParams };
    return this.http.get<any>(this.getUrl(ApiConstants.API_GetApiSpecification), options);
    }

    getAdApplications(appName: string,cmdbId: string): Observable<any> {
        // let httpParams = new HttpParams()
        //   .set('appName', appName);    
        return this.http.get<any>(this.getUrl(ApiConstants.API_Get_AdApplicationList), {
            params: new HttpParams()
                .set('appName', appName)
                .set('cmdbId', cmdbId)
        });
    }
    getAdApplicationsByAppId(appName: string): Observable<any> {
      // let httpParams = new HttpParams()
      //   .set('appName', appName);    
      return this.http.get<any>(this.getUrl(ApiConstants.API_GetADApplicationById), {
          params: new HttpParams()
              .set('appId', appName)
      });
  }

  

    getListOfADUsersOwners(searchText: string): Observable<any> {   
      return this.http.get<any>(this.getUrl(ApiConstants.API_GetListOfADUsers_Owners), {
          params: new HttpParams()
              .set('wildcardText', searchText)
              .set('resultLimit', 10)
      });
  }
  getListOfADUsers_Approvers(searchText: string): Observable<any> {   
    return this.http.get<any>(this.getUrl(ApiConstants.API_GetListOfADUsers_Approvers), {
        params: new HttpParams()
            .set('wildcardText', searchText)
            .set('resultLimit', 10)
    });
}
    getApimApiPublisherId(apimApiId: string): Observable<any> {
      let httpParams = new HttpParams()
        .set('apimApiId', apimApiId);      
      let options = { params: httpParams };
      return this.http.get<any>(this.getUrl(ApiConstants.API_GetApimApisByPublisher), options);
    }

    getApimProductsAndOwnersByApiId(apimApiId: string): Observable<any> {
      let httpParams = new HttpParams()
        .set('apimApiId', apimApiId);      
      let options = { params: httpParams };
      return this.http.get<any>(this.getUrl(ApiConstants.API_GetApimProductsAndOwnersByApiId), options);
    }

    getApimTags(searchText:string): Observable<any> {
      let httpParams;         
      if (searchText != "") {
        httpParams = new HttpParams()
        .set('wildcardText', searchText)        
      }    
      let options = { params: httpParams };
      return this.http.get<any>(this.getUrl(ApiConstants.API_GetAllApimTags), options)      
      .pipe(
        map(res => res)
      );
    }

    getCmdbApplications(searchText: string): Observable<any> {
        return this.http.get<any>(this.getUrl(ApiConstants.API_Get_CmdbApplicationList), {
            params: new HttpParams()
                .set('wildcardText', searchText)
        });
    }

    getCmdbDetails(cmdbId: string): Observable<any> {
        return this.http.get<any>(this.getUrl(ApiConstants.API_Get_CmdbDetails), {
            params: new HttpParams()
                .set('cmdbId', cmdbId)
        });
    }


    getHIPEligibleApplication(cmdbId: string): Observable<HIPEligibleApplication[]> {
      return this.http.get<HIPEligibleApplication[]>(this.getUrl(ApiConstants.API_Get_HIPEligibleApplication), {
          params: new HttpParams()
              .set('cmdbId', cmdbId)
              .set('api-version',1.0)
              .set('appName','')
      });
  }
  
  

  
  // app registration

  
  getListOfADApplications(cmdbId: string): Observable< any> {
    let httpParams = new HttpParams()
      .set('cmdbId', cmdbId);
    let options = { params: httpParams };
    return this.http.get<any>(this.getUrl(ApiConstants.API_GetApiAppRegistrationGetListOfADApplications), options);
  }

  getNonHIPApplications(cmdbId: string): Observable< NonHipAppRegistration[]> {
    let httpParams = new HttpParams()
      .set('cmdbId', cmdbId);
    let options = { params: httpParams };
    return this.http.get<NonHipAppRegistration[]>(this.getUrl(ApiConstants.API_GetNonHIPApplications), options);
  }

  
  getValidateAndListAppOwners(appId: string): Observable<any> {
    let httpParams = new HttpParams()
      .set('appId', appId);
    let options = { params: httpParams };
    return this.http.get<any>(this.getUrl(ApiConstants.API_GetApiAppRegistrationValidateAndListAppOwners), options);
  }

  getApplicationOwnersWithValidation(appId: string): Observable<ApplicationOwnersWithValidation> {
    let httpParams = new HttpParams()
      .set('appId', appId);
    let options = { params: httpParams };
    return this.http.get<ApplicationOwnersWithValidation>(this.getUrl(ApiConstants.API_GetApplicationOwnersWithValidation), options);
  }


  addOwnersInADGroup(cmdbId: CmdbRequest): Observable<any> {
    return this.http.post<any>(this.getUrl(ApiConstants.API_AppRegistrationADGroup),
    cmdbId);
  }
  
  getListOfADUsers(wildcardText: string): Observable<any> {
    let httpParams = new HttpParams()
      .set('wildcardText', wildcardText);
    let options = { params: httpParams };
    return this.http.get<any>(this.getUrl(ApiConstants.API_GetApiAppRegistrationGetListOfADUsers), options);
  }

  getApiCmdbGetDetails(cmdbId: string): Observable<CmdbDetails> {
    let httpParams = new HttpParams()
       .set('cmdbId', cmdbId);
     let options = { params: httpParams };

     return this.http.get<CmdbDetails>(this.getUrl(ApiConstants.API_GetApiCmdbGetDetails), options);
   }
  

  getApiCmdbGetApplications(wildcardText: string): Observable<CmdbApplications> {
    return this.http.get<CmdbApplications>(this.getUrl(ApiConstants.API_GetApiCmdbGetApplications+"/"+wildcardText));
  }


  // subscriptions

  
  getSubscriptionsByUserEmail(email: string): Observable<SubscriptionEmailRequest> {
    let httpParams = new HttpParams()
      .set('email', email);
    let options = { params: httpParams };
    return this.http.get<SubscriptionEmailRequest>(this.getUrl(ApiConstants.API_GetApiSubscriptionGetSubscriptionsbyUserEmail), options);
  }

  
  getSubscriptionKey(subscriptionName: string): Observable<SubscriptionKeyRequest> {
    let httpParams = new HttpParams()
      .set('subscriptionName', subscriptionName);
    let options = { params: httpParams };
    return this.http.get<SubscriptionKeyRequest>(this.getUrl(ApiConstants.API_GetApiSubscriptionGetSubscriptionKey), options);
  }


  getApisForSubscription(subscriptionId: string): Observable<ApisForSubscriptionRequest> {
    let httpParams = new HttpParams()
      .set('subscriptionId', subscriptionId);
    let options = { params: httpParams };
    return this.http.get<ApisForSubscriptionRequest>(this.getUrl(ApiConstants.API_GetApiSubscriptionGetApisForSubscription), options);
  }

  
  getSubscriptionsBy(apiId: string): Observable<SubscriptionsByApiRequest> {
    let httpParams = new HttpParams()
      .set('apiId', apiId);
    let options = { params: httpParams };
    return this.http.get<SubscriptionsByApiRequest>(this.getUrl(ApiConstants.API_GetApiSubscriptionGetSubscriptionsByApi), options);
  }

  getIsValidCmdbOwner(): Observable<boolean> {   
    return this.http.get<boolean>(this.getUrl(ApiConstants.API_Get_IsValidCmdbOwner));
}

  getApplicationsByUser(businessAreaCode: string, filter = '', sortOrder = 'asc', pageNumber = 1,
  pageSize = 50, apiVersion:string = '1.0'): Observable<any> {   
  return this.http.get<any>(this.getUrl(ApiConstants.API_Get_ApplicationsByUser),{
  params: new HttpParams()
  .set('businessAreaCode', businessAreaCode)
  .set('isDeleted', false)
  .set('filter', filter)
  .set('sortOrder', sortOrder)
  .set('pageNumber', pageNumber.toString())
  .set('pageSize', pageSize.toString())
  .set('api-version',apiVersion)
  });
}


 getSubscriptionsByApplicationOwner(state: string, filter = '', sortOrder = 'asc', pageNumber = 1,
 pageSize = 50, apiVersion:string = '1.0'): Observable<any> {   
  return this.http.get<any>(this.getUrl(ApiConstants.API_GetSubscriptionsByApplicationOwner),{
    params: new HttpParams()
    .set('filter', filter)
    .set('sortOrder', sortOrder)
    .set('pageNumber', pageNumber.toString())
    .set('pageSize', pageSize.toString())
    .set('api-version',apiVersion)
    .set('state',state)
    });
}

getSubscriptionsByCmdbId(CmdbId: string, filter = '', sortOrder = 'asc', pageNumber = 1,
pageSize = 50, apiVersion:string = '1.0', state:string=''): Observable<any> {   
 return this.http.get<any>(this.getUrl(ApiConstants.API_GetSubscriptionsByCmdbId),{
   params: new HttpParams()
   .set('filter', filter)
   .set('sortOrder', sortOrder)
   .set('pageNumber', pageNumber.toString())
   .set('pageSize', pageSize.toString())
   .set('api-version',apiVersion)
   .set('CmdbId',CmdbId)
   .set('State',state)
   });
}

  createSubscription(api: SubscriptionRequest): Observable<any> {
    return this.http.post<any>(this.getUrl(ApiConstants.API_createSubscriptionApi),
      api);
  }

  notifySubscription(subscription: NotifySubscription): Observable<any> {
    return this.http.post<any>(this.getUrl(ApiConstants.API_NotifySubscriptionApi),
    subscription);
  }

  rejectSubscription(api: RejectSubscriptionRequest): Observable<any> {
    return this.http.put<any>(this.getUrl(ApiConstants.API_putRejectSubscriptionApi),
      api);
  }
  cancelSubscription(api: SubscriptionCancelRequest): Observable<any> {      
    return this.http.put<any>(this.getUrl(ApiConstants.API_putCancelSubscriptionApi),{},{
     params: new HttpParams()
    .set('productId', api.productId)
      .set('subscriptionName', api.subscriptionName)
    }    
   );
  }
  reactivateSubscription(api: SubscriptionReactivateRequest): Observable<any> {      
    return this.http.put<any>(this.getUrl(ApiConstants.API_putReactivateSubscriptionApi),{},{
     params: new HttpParams()
    .set('productId', api.productId)
      .set('subscriptionName', api.subscriptionName)
    }    
   );
  }
  deleteSubscription(api: DeleteSubscriptionRequest): Observable<any> {      
    return this.http.delete<any>(this.getUrl(ApiConstants.API_DeleteSubscriptionApi),{
     params: new HttpParams()
    .set('productId', api.productId)
      .set('subscriptionName', api.subscriptionName)
    }    
   );
  }
  approveProductSubscription(api: SubscriptionApproveRequest): Observable<any> {
    return this.http.post<any>(this.getUrl(ApiConstants.API_approveProductSubscriptionApi),
      api);
  }

  getHttpFile(queryHttpFileByApim: IQueryHttpFileByApim): Observable<IHttpFileResponse> {
    const params = new HttpParams()
      .set('SubscriptionName', queryHttpFileByApim.SubscriptionName)
      .set('ApiVersion', queryHttpFileByApim.ApiVersion)
      .set('ApimApiName', queryHttpFileByApim.ApimApiName)
      .set('ProxyClientId', queryHttpFileByApim.ProxyClientId);
  
      if (queryHttpFileByApim.Environment) {
        params.set('Environment', queryHttpFileByApim.Environment);
      }
  
  
    return this.http.get<IHttpFileResponse>(this.getUrl(ApiConstants.API_GetDownloadFileForSubscription), { params });
  }

  apiApimTracing(tracingModel: TracingModel): Observable<any>{
    return this.http.put<any>(this.getUrl(ApiConstants.API_putApiApimTracing),tracingModel);
  }

  getSubscriptionsToBeExported(apimProductId:string,environment?: string): Observable<any>{
    const params = new HttpParams().set('apimProductId', apimProductId);
    if (environment) {
      params.set('Environment', environment);
    }
    return this.http.get<IHttpFileResponse>(this.getUrl(ApiConstants.API_GetSubscriptionsToBeExported), { params });
    }

    getMySubscriptionsToBeExported():Observable<any>{
      return this.http.get<any>(this.getUrl(ApiConstants.API_GetMySubscriptionsToBeExported));
    }
    getDeveloperManageSubscriptionsToBeExported(cmdbId:string):Observable<any>{
      const params = new HttpParams().set('cmdbId',cmdbId);
      return this.http.get<any>(this.getUrl(ApiConstants.API_GetDeveloperManageSubscriptionsToBeExported),{params});
    }
  private getUrl(relativePath: string) {
    return `${Environment.managementHIPUri}${relativePath}`;
  }

}
