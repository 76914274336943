import { NgModule } from '@angular/core';
import { MsalGuard } from '@azure/msal-angular';
import { RouterModule, Routes } from '@angular/router';
import {AddProductComponent} from './add-product/add-product.component'
import {ManageProductComponent} from './manage-product/manage-product.component'
import {ProductsComponent} from './products.component'
import { SharedModule } from 'src/app/shared/shared.module';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Ng2OrderModule } from 'ng2-order-pipe';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from 'src/app/app-routing.module';
import { EditProductComponent } from './edit-product/edit-product.component';
import { AllProductsComponent } from './all-products/all-products.component';
import { ViewProductComponent } from './view-product/view-product.component';
import { DeveloperViewProductComponent } from './developer-view-product/developer-view-product.component';
import { ProductSubcriptionFlowComponent } from './product-subcription-flow/product-subcription-flow.component';
import { AuthGuard } from 'src/app/shared/guard/auth.guard';
import { ApiCatalogRoleEnum } from 'src/app/core/enum/UserRoleEnum';
import { EditDraftProductComponent } from './edit-draft-product/edit-draft-product.component';

const routes: Routes = [
  {path: 'publisher',
  children:[
    { path: 'manage-products/add-product', component: AddProductComponent, data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/manage-products","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN]} ,canActivate: [MsalGuard,AuthGuard] },
    { path: 'manage-products', component: ManageProductComponent,data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/manage-products","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN]} ,canActivate: [MsalGuard,AuthGuard]  },
    { path: 'manage-products/edit-product/:productId', component: EditProductComponent,data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/manage-products","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN]} ,canActivate: [MsalGuard,AuthGuard] },
    { path: 'manage-products/edit-draft-product/:productId', component: EditDraftProductComponent,data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/manage-products","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN]} ,canActivate: [MsalGuard,AuthGuard] },
    { path: 'manage-products/view-product/:productId', component: ViewProductComponent,data: {"menu" : "Publisher", "defaultModuleRoute" : "/publisher/manage-products","role":[ApiCatalogRoleEnum.PUBLISHER,ApiCatalogRoleEnum.ADMIN,ApiCatalogRoleEnum.APPROVER]} ,canActivate: [MsalGuard,AuthGuard] }
   ]             
    },
    {
      path: 'developer',
      children:[
        {
          path: 'all-products',
          component:AllProductsComponent,
          data: {"menu" : "Developer", "defaultModuleRoute" : "/developer/all-products"},
          canActivate: [MsalGuard]
        },
        { path: 'view-product/:productId', component: DeveloperViewProductComponent,data: {"menu" : "Developer", "defaultModuleRoute" : "/developer/all-products"} ,canActivate: [MsalGuard] },
        { path: 'product-subscription/:productId', component: ProductSubcriptionFlowComponent, data: {"menu" : "Developer", "defaultModuleRoute" : "/developer/all-products"},canActivate: [MsalGuard] }
      ]
    }     
  ];



@NgModule({
    declarations: [AddProductComponent,ManageProductComponent,ProductsComponent,EditProductComponent, AllProductsComponent, DeveloperViewProductComponent, ProductSubcriptionFlowComponent, EditDraftProductComponent],
    imports: [RouterModule.forRoot(routes),SharedModule,
      BrowserModule,
      AppRoutingModule,
      BrowserAnimationsModule,
      FormsModule,
      HttpClientModule,
      NgxPaginationModule, //npm install ngx-pagination --save
      Ng2SearchPipeModule, // npm i ng2-search-filter --save
      Ng2OrderModule, //npm install  ng2-order-pipe --save
      ReactiveFormsModule,],
    exports: [RouterModule]  
  })
  export class ProductModule {
  
  }

