import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'customCapitalizePipe'
})
export class CustomCapitalizePipe implements PipeTransform {

  transform(value: string, ...args: any[]): any {
    value=value.trimStart();
    let formattedValue=value.charAt(0).toUpperCase()+value.slice(1)
    return formattedValue;
  }

}
